import requestApis from "apis/request";
import {
  IArea,
  IAreaQueryParams,
  IAreaV2,
  IDistrictQueryParams,
  ILocation,
  IProvinceQueryParams,
  IWardQueryParams,
} from "models";

const { staffBffClient, locationClient, locationClientV2 } = requestApis;

const getProvincesOrEmpty = async (): Promise<IArea[]> => {
  const { data } = await staffBffClient.get("api/v1/location/provinces");
  return data?.areas || [];
};

const getDistrictsOrEmpty = async (params?: IDistrictQueryParams): Promise<IArea[]> => {
  const { data } = await staffBffClient.get("api/v1/location/districts", { params });
  return data?.areas || [];
};

const getWardsOrEmpty = async (params?: IWardQueryParams): Promise<IArea[]> => {
  const { data } = await staffBffClient.get("api/v1/location/wards", { params });
  return data?.areas || [];
};

const getAreasOrEmpty = async (params: IAreaQueryParams): Promise<IArea[]> => {
  const { data } = await staffBffClient.get("api/v1/location/areas", { params });
  return data?.areas || [];
};

const getProvincesOrEmptyV2 = async (params?: IProvinceQueryParams): Promise<IAreaV2[]> => {
  const { data } = await locationClient.get("api/v1/location/provinces", { params });
  return data?.result?.provinces || [];
};

const getDistrictsOrEmptyV2 = async (provinceCode?: IAreaV2["code"]): Promise<IAreaV2[]> => {
  const { data } = await locationClient.get("api/v1/location/districts", { params: { provinceCode } });
  return data?.result?.districts || [];
};

const getWardsOrEmptyV2 = async (districtCode?: IAreaV2["code"]): Promise<IAreaV2[]> => {
  const { data } = await locationClient.get("api/v1/location/wards", { params: { districtCode } });
  return data?.result?.wards || [];
};

const getLocationsOrEmpty = async (keyword: string): Promise<ILocation[]> => {
  try {
    const resp = await locationClientV2.get("api/v2/location/areas", { params: { keyword } });
    return resp.data?.data?.areas || [];
  } catch {
    return [];
  }
};

export default {
  getProvincesOrEmpty,
  getDistrictsOrEmpty,
  getWardsOrEmpty,
  getAreasOrEmpty,
  getProvincesOrEmptyV2,
  getDistrictsOrEmptyV2,
  getWardsOrEmptyV2,
  getLocationsOrEmpty,
};
