import { IFlagsData, IRoute } from "models";

/**
 * Given the list of feature flag key, determines if a leaf route is accessible.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param flagsData the list of feature flag key
 */
const isLeafRouteVisible = (route: IRoute, flagsData: IFlagsData): boolean => {
  const { flagKey } = route;
  if (!flagKey || !flagsData[flagKey]) return true;
  return flagsData[flagKey].enabled;
};

/**
 * Given the list of feature flag key, returns which routes are accessible
 * @param items the routes to check
 * @param flagsData the list of feature flag key
 */
const filterFlagsEnabled = (items: IRoute[], flagsData: IFlagsData): IRoute[] => {
  return items.filter((item) => {
    const { flagKey, children: childPaths } = item;

    // If the feature flag of this route was disabled => return false
    if (flagKey && flagsData[flagKey] && !flagsData[flagKey].enabled) {
      return false;
    }

    // If this route has children => return true if one of its children is visible
    if (childPaths) {
      const childRoutes = childPaths
        .map((path) => items.find((item) => item.path === path))
        .filter((x) => !!x) as IRoute[];
      const visibleChild = childRoutes.find((route) => isLeafRouteVisible(route, flagsData));
      return !!visibleChild;
    }

    // If this route doesn't have either children or feature flag => return true
    return true;
  });
};

export default {
  filterFlagsEnabled,
};
