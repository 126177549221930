import { ErrorInfoDomain, MessageByErrorInfo } from "models";

const receiveRequestErrorMessage =
  "Có vấn đề khi hệ thống tiếp nhận yêu cầu của bạn. Vui lòng tải lại trang và thử lại, nếu vấn đề tiếp diễn, vui lòng liên hệ support@teko.vn để được hỗ trợ";
const systemErrorMessage = "Hệ thống đang gặp sự cố. Để nắm thêm thông tin, vui lòng liện hệ đội ngũ hỗ trợ";
const fileGenerationErrorMessage =
  "Có vấn đề trong quá trình tạo file. Vui lòng liên hệ support@teko.vn để được hỗ trợ";

const apiErrorMessages: MessageByErrorInfo = {
  [ErrorInfoDomain.Inventory]: {
    INSUFFICIENT_STOCK: "Không thể giữ tồn cho các SKU <1>{{ skus }}</1> vì không đủ số lượng",
  },
  [ErrorInfoDomain.COV]: {
    ORDER_NOT_FOUND:
      "Không tìm thấy đơn hàng. Có thể mã đơn chưa đúng hoặc đơn chưa được đồng bộ xong dưới hệ thống. Nếu mã đơn đã chính xác, vui lòng chờ giây lát và tải lại trang",
    DATABASE_QUERY_FAILURE: systemErrorMessage,
  },
  [ErrorInfoDomain.StaffOMAdminBFF]: {
    DISALLOWED_PLATFORM_ID:
      "Bạn không thể xem và thao tác trên đơn của platform khác. Vui lòng liên hệ đội ngũ hỗ trợ để kiểm tra platform bạn được cấp",
    MISSING_PLATFORM_CONFIG: "Your platform is not configured to use this feature, please contact support@teko.vn",
    READ_HTTP_REQUEST_FAILURE: receiveRequestErrorMessage,
    DECODE_REQUEST_FAILURE: receiveRequestErrorMessage,
    COV_SERVICE_FAILURE: systemErrorMessage,
    SELLER_SERVICE_FAILURE: systemErrorMessage,
    INIT_TEMPLATE_FAILURE: fileGenerationErrorMessage,
    INSERT_DATA_INTO_TEMPLATE_FAILURE: fileGenerationErrorMessage,
    FILE_GENERATION_FAILURE: fileGenerationErrorMessage,
    DISALLOWED_TERMINAL_ID: "Bạn chưa được cấp terminal hợp lệ để thao tác trên đơn hàng này",
  },
  [ErrorInfoDomain.FFR]: {
    ALREADY_CREATED_SHIPMENT_FOR_ITEMS:
      "Một vài sản phẩm đã được tạo gói hàng, không thể tạo thêm. Vui lòng tải lại trang và thử lại",
    EXCEEDED_ITEM_QUANTITY: "Bạn đang chọn quá số lượng hàng được phép tạo gói hàng, vui lòng tải lại trang và thử lại",
  },
  [ErrorInfoDomain.WarehouseCentral]: {
    DEACTIVATE_SITE_FAILED_PRECONDITION:
      "Chi nhánh không đủ điều kiện để dừng hoạt động do vẫn còn hàng tồn kho và một số giao dịch chưa được hoàn tất xử lý. Vui lòng kiểm tra và thử lại sau.",
  },
};

const apiMessages = {
  Common: {
    Success: "Yêu cầu được xử lý thành công",
    UnknownError: "Hệ thống hiện đang có lỗi, vui lòng thử lại sau",
    InternalServerError: "Hệ thống hiện đang có lỗi, vui lòng thử lại sau",
    PermissionDenied: "Bạn không có quyền truy cập trang này",
    BadRequest: "Yêu cầu không hợp lệ",
    NotFound: "Dữ liệu không tồn tại",
    AlreadyExisted: "Dữ liệu đã tồn tại",
  },
  ErrorCode: {
    400001: "Không tìm thấy yêu cầu trả hàng",
    400002: "Không tìm thấy kho thu hồi",
    400003: "Không tìm thấy mã hoàn tiền",
    400004: "Khoảng thời gian không hợp lệ",
    400005: "Không tìm thấy mã yêu cầu trả hàng",
    400006: "Yêu cầu trả hàng hoặc mã đơn hàng là bắt buộc",
    400007: "Người nhận thông báo không có dữ liệu",
    400008: "Không tìm thấy mã hoàn tiền",
    400009: "Không lấy được lý do trả hàng",
    400010: "Không tìm thấy mã sàn",
    400011: "Không được phép điều chỉnh tăng số tiền hoàn trả",
    400012: "Không được phép điều chỉnh giảm số tiền hoàn trả",
    400013: "Vượt quá thời gian cho phép trả hàng",
    400014: "Vượt quá số lượng dịch vụ được phép hủy trả",
    400015: "Vượt quá số lượng dịch vụ được phép hủy trả",
    400016: "Thiếu thông tin số lượng dịch vụ",
    400017: "Không tìm thấy mã dịch vụ",
    400018: "Không tìm thấy mã dịch vụ từ thông tin hóa đơn",
    400019: "Vượt quá số lượng dịch vụ được phép hủy trả",
    400020: "Không tìm thấy trạng thái yêu cầu trả hàng",
    400021: "Không tìm thấy trạng thái đơn hàng để tạo yêu cầu trả hàng",
    400022: "Vượt quá số lượng sản phẩm được phép hủy trả",
    400023: "Không tìm thấy trạng thái yêu cầu trả hàng",
    500001: "Không tìm thấy trạng thái yêu cầu trả hàng",

    // ErrorCode of API PrintMultipleSaleOrders
    400901001: "Lỗi hệ thống: Không lấy được danh sách đơn",
    400901002: "Danh sách đơn có chứa đơn thuộc platform không hợp lệ",
    500901001: "Lỗi hệ thống",
    500901002: "Lỗi hệ thống",
    500901003: "Lỗi hệ thống: Không lấy được thông tin đơn hàng",
    500901004: "Lỗi hệ thống: Không lấy được thông tin sàn",
    500901005: "Lỗi hệ thống: Không lấy được thông tin nhà bán",
    500901006: "Lỗi hệ thống: Không lấy được mẫu in",
    500901007: "Lỗi hệ thống",
    500901008: "Lỗi hệ thống: Không lấy được dữ liệu",
    500901009: "Lỗi hệ thống: Tạo tập tin PDF lỗi",
    500901010: "Lỗi hệ thống: Gửi tập tin PDF bị lỗi",
  },
};

const validationMessages = {
  Required: "Không được để trống {{fieldName}}",
  Invalid: "Không hợp lệ",
  MaxLength: "Phải nhỏ hơn hoặc bằng {{maxLength}} ký tự",
  MinLength: "Phải ít nhất {{minLength}} ký tự",
  MaxValue: "Phải nhỏ hơn hoặc bằng {{maxValue}}",
  MinValue: "Phải lớn hơn hoặc bằng {{minValue}}",
  Email: "Địa chỉ email không hợp lệ",
};

const helperMessages = {
  SearchPlaceholder: "Nhập {{fieldName}} để tìm kiếm",
};

export default {
  ApiErrorMessages: apiErrorMessages,
  ApiMessages: apiMessages,
  ValidationMessages: validationMessages,
  HelperMessages: helperMessages,
};
