import { SharedOrder } from "components/Shared_OrderDataTable_Pre1018";
import { orderItemStatusInfo } from "constants/index";
import {
  InvoiceItem,
  InvoiceItemType,
  InvoiceType,
  IOrderItem,
  IShipment,
  MarketOrder,
  OrderItemsBySeller,
  OrderItemsByStatus,
  OrderItemsByStatusAndSeller,
  OrderItemStatus,
  OrderType,
  ShipmentStatus,
} from "models";
import orderUtils from "utils/order";

const transformOrderDataByShipment: (rawOrders: MarketOrder[], target?: OrderType) => SharedOrder[] = (
  rawOrders,
  target = OrderType.market
) => {
  const transformedData: SharedOrder[] = [];
  rawOrders.forEach((rawOrder) => {
    const order = orderUtils.switchItemsToRouterItems(rawOrder);
    const transformedItems = transformItemsNonShipmentByStatus(order.items);
    const noGroupItems = Object.keys(transformedItems).length;
    const noShipments = noGroupItems + order.shipments.length;
    //transform items group to shared order type
    const fakeShipments = Object.values(transformedItems).map((element, index) => {
      const fakeOrder = { ...order, items: element };
      return constructDefaultShipment(fakeOrder, target, index + 1, noShipments);
    });
    //transform order to shared order type
    const shipments = flattenOrderIntoShipments(order, target, noGroupItems + 1, noShipments);
    Array.prototype.push.apply(transformedData, [...fakeShipments, ...shipments]);
  });
  return transformedData;
};

const flattenOrderIntoShipments: (
  order: MarketOrder,
  target: OrderType,
  shipmentBaseIndex: number,
  noShipments?: number
) => SharedOrder[] = (order, target, shipmentBaseIndex, noShipments) =>
  order.shipments.map((shipment, index) => ({
    noShipments: noShipments || order.shipments.length,
    detailRef: target === OrderType.market ? order.code : `${shipment.sellerId}/${order.id}`,
    createdAt: order.createdAt,
    creator: order.creator,
    customer: order.customer,
    billingInfo: order.billingInfo,
    terminalName: order.terminalName,
    platformId: order.platformId,
    shippingInfo: order.shippingInfo,
    grandTotal: order.grandTotal,
    replaceOrderId: order.replacedOrderId,
    order: {
      id: order.id,
      code: order.code,
      displayStatusName: order.displayStatusName,
    },
    items: order.items,
    routerItems: order.routerItems,
    shipment: {
      index: shipmentBaseIndex + index,
      id: shipment.id,
      shipmentSetCode: shipment.shipmentSetCode,
      status: shipment.status,
      displayStatusColor: shipment.displayStatusColor,
      displayStatusName: shipment.displayStatusName,
      allowedUserActions: shipment.allowedUserActions,
      parentId: shipment.parentId,
      sellerId: shipment.sellerId,
      fulfillmentChannelName: shipment.fulfillmentChannelName,
      fulfillmentProviderName: shipment.fulfillmentProviderName,
      items: shipment.items,
      routerItems: shipment.routerItems,
      isItemGroup: false,
    },
  }));

const constructDefaultShipment: (
  order: MarketOrder,
  target?: OrderType,
  shipmentIndex?: number,
  noShipments?: number
) => SharedOrder = (order, target = OrderType.market, shipmentIndex = 1, noShipments = 1) => {
  const itemGroupStatus = order.items[0].status;
  const statusInfo = orderItemStatusInfo.find((obj) => obj.status === itemGroupStatus);

  return {
    noShipments: noShipments,
    detailRef: target === OrderType.market ? order.id : `${order.items?.[0]?.sellerId}/${order.id}`,
    createdAt: order.createdAt,
    creator: order.creator,
    customer: order.customer,
    billingInfo: order.billingInfo,
    terminalName: order.terminalName,
    platformId: order.platformId,
    shippingInfo: order.shippingInfo,
    grandTotal: order.grandTotal,
    replaceOrderId: order.replacedOrderId,
    order: {
      id: order.id,
      code: order.code,
      displayStatusName: order.displayStatusName,
    },
    items: order.items,
    routerItems: order.routerItems,
    shipment: {
      index: shipmentIndex,
      id: `${order.id}_${shipmentIndex}`,
      shipmentSetCode: "",
      status: itemGroupStatus as ShipmentStatus,
      displayStatusColor: statusInfo?.displayColor || "",
      displayStatusName: statusInfo?.displayName || "",
      parentId: order.id,
      sellerId: -1,
      fulfillmentChannelName: "",
      items: order.items,
      routerItems: order.routerItems,
      isItemGroup: true,
    },
  };
};

const groupItemsNonShipmentByStatus = (items: IShipment["items"]) => {
  let groupedItems: OrderItemsByStatus = items
    .filter((item) => !item.shipmentId)
    .reduce((result: any, currentItem) => {
      if (currentItem.status) {
        result[currentItem.status] = [...(result[currentItem.status] || []), currentItem];
      } else {
        result[OrderItemStatus.unspecified] = [...(result[OrderItemStatus.unspecified] || []), currentItem];
      }
      return result;
    }, {});

  return groupedItems;
};

const groupItemsNonShipmentByStatusAndSeller: (
  groupedItemsByStatus: OrderItemsByStatus
) => OrderItemsByStatusAndSeller = (groupedItemsByStatus) => {
  let groupedItems: OrderItemsByStatusAndSeller = {};
  Object.keys(groupedItemsByStatus).forEach((status) => {
    groupedItems[status] = groupedItemsByStatus[status].reduce(
      (result: OrderItemsBySeller, currentItem: IOrderItem) => {
        result[currentItem.sellerId] = [...(result[currentItem.sellerId] || []), currentItem];
        return result;
      },
      {}
    );
  });
  return groupedItems;
};

const sortItemsNonShipmentByStatus = (groupedItems: OrderItemsByStatus) => {
  const MAX_PRIORITY = 1000;
  let sortedItems: OrderItemsByStatus = Object.keys(groupedItems)
    .sort((statusA, statusB) => {
      const priorityA = orderItemStatusInfo.find((obj) => obj.status === statusA)?.priority || MAX_PRIORITY;
      const priorityB = orderItemStatusInfo.find((obj) => obj.status === statusB)?.priority || MAX_PRIORITY;
      return priorityA - priorityB;
    })
    .reduce((result: any, currentItem) => {
      result[currentItem] = groupedItems[currentItem];
      return result;
    }, {});

  return sortedItems;
};

const transformItemsNonShipmentByStatus = (items: IShipment["items"]) => {
  return sortItemsNonShipmentByStatus(groupItemsNonShipmentByStatus(items));
};

const transformItemsNonShipmentByStatusAndSeller: (items: IShipment["items"]) => OrderItemsByStatusAndSeller = (
  items
) => {
  return groupItemsNonShipmentByStatusAndSeller(groupItemsNonShipmentByStatus(items));
};

const checkOrderPriority = (items: IShipment["items"]) => {
  for (let item of items) {
    if (item.priority && item.priority > 0) return true;
  }
  return false;
};

const getServiceInvoiceItems = (invoices: MarketOrder["invoices"]) => {
  let result: InvoiceItem[] = [];
  let serviceInvoices = invoices.filter((invoice) => invoice.type === InvoiceType.service);

  if (serviceInvoices.length !== 0) {
    serviceInvoices.forEach((invoice) => {
      result = result.concat(
        invoice.invoiceItems.map((item) => {
          return {
            ...item,
            sellerId: invoice.sellerId,
            sellerName: invoice.sellerName,
          };
        })
      );
    });
  }

  if (result.length === 0) {
    invoices.forEach((invoice) => {
      result = result.concat(
        invoice.invoiceItems
          .filter((item) => item.type === InvoiceItemType.service)
          .map((item) => {
            return {
              ...item,
              sellerId: invoice.sellerId,
              sellerName: invoice.sellerName,
            };
          })
      );
    });
  }

  return result;
};

export default {
  transformOrderDataByShipment,
  transformItemsNonShipmentByStatus,
  transformItemsNonShipmentByStatusAndSeller,
  checkOrderPriority,
  getServiceInvoiceItems,
};
