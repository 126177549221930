import { Typography, notification } from "antd";
import { ErrorInfo } from "models";
import React from "react";
import { t } from "utils/i18n";
import { styledErrorMessageMap } from "./StyledErrorMessage";

const ErrorInfoMessage: React.FC<{ errorInfo: ErrorInfo }> = ({ errorInfo }) => {
  const { domain, reason, metadata } = errorInfo;
  const StyledErrorMessage = styledErrorMessageMap?.[domain]?.[reason];
  // if error is not specified styling component, then use the error message in translation resources
  if (!StyledErrorMessage) {
    return (
      <Typography.Text>
        {t(`ApiErrorMessages.${errorInfo?.domain}.${errorInfo?.reason}`, errorInfo?.metadata)}
      </Typography.Text>
    );
  }
  return <StyledErrorMessage metadata={metadata} translationKey={`ApiErrorMessages.${domain}.${reason}`} />;
};

const notifyErrorInfo = (errorInfo: ErrorInfo) => {
  notification.error({
    message: t("Error"),
    description: <ErrorInfoMessage errorInfo={errorInfo} />,
  });
};

export { notifyErrorInfo };
