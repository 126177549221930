import { Result, Spin } from "antd";
import { StoreContext } from "contexts";
import { ordersHooks } from "hooks";
import { IPlatform } from "models";
import React, { useContext } from "react";
import { Trans } from "react-i18next";

export interface WithSinglePlatformIdProps {
  authorizedPlatform: IPlatform;
}

export const withSinglePlatformId = <P extends {}>(
  Component: React.FC<P>,
  targetTitle: string
): React.FC<P & WithSinglePlatformIdProps> => {
  return (props) => {
    const { isFetching, userPlatformId, authorizedPlatforms } = ordersHooks.useAuthorizedPlatforms();
    const { currentUser } = useContext(StoreContext);
    const userIdentity = currentUser.email || currentUser.phone_number || currentUser.name;

    const isInvalidPlatform =
      Number.isNaN(userPlatformId) || userPlatformId === 0 || (authorizedPlatforms && authorizedPlatforms.length === 0);

    if (isFetching) return <Spin spinning={isFetching} />;

    if (isInvalidPlatform) {
      return (
        <Result
          status="403"
          subTitle={
            <Trans i18nKey="InvalidUserPlatformMsg">
              Tài khoản <span className="text-primary text-medium"> {{ userIdentity }} </span> chưa được gán Platform
              hợp lệ để xem {{ targetTitle: targetTitle.toLowerCase() }}.
              <br /> Vui lòng liên hệ Admin <a href="mailto:support@teko.vn">support@teko.vn</a>
              để được hỗ trợ.
            </Trans>
          }
        />
      );
    }

    return <Component {...props} authorizedPlatform={authorizedPlatforms?.[0]} />;
  };
};
