import { IFundType, Terminal } from "models";
import { createContext, Dispatch, SetStateAction } from "react";

type DiscountContextType = {
  fundTypes: IFundType[];
  setFundTypes?: Dispatch<SetStateAction<IFundType[]>>;
  terminals: Terminal[];
};

export const DiscountContext = createContext<DiscountContextType>({
  fundTypes: [],
  setFundTypes: () => {},
  terminals: [],
});
