import { FireOutlined } from "@ant-design/icons";
import { INTERNAL_PATH } from "constants/index";
import { lazy } from "react";
import { t } from "utils/i18n";
// Pages
const FlagsList = lazy(() => import("pages/Internal/FlagsList/FlagsList"));

export default [
  // Parent menu
  {
    path: INTERNAL_PATH.ROOT,
    name: t("Internal.Root"),
    icon: FireOutlined,
    children: [INTERNAL_PATH.FLAGS_LIST],
  },
  // Sub menus
  {
    exact: true,
    path: INTERNAL_PATH.FLAGS_LIST,
    name: t("Internal.FlagsList"),
    component: FlagsList,
  },
];
