import { DropboxOutlined } from "@ant-design/icons";
import { flagConstants } from "constants/index";
import { actionConstants, appConstants, resourceConstants } from "constants/permission";
import { withAssignedSingleSeller, withFeatureFlag, withSinglePlatformId } from "hocs";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { EXTENDED_FLAG_KEYS } = flagConstants;
const { STAFF_BFF } = appConstants;
const { INVENTORY } = resourceConstants;
const { READ, UPDATE, READ_SITE_AND_WAREHOUSE, CREATE_SITE_AND_WAREHOUSE, UPDATE_SITE_AND_WAREHOUSE } = actionConstants;

// Pages
const ManualInventoryTracking = lazy(() => import("pages/ManualInventoryTracking"));
const ManualInventoryUploadHistory = lazy(() => import("pages/ManualInventoryUploadHistory"));
const ManualInventoryWarehouseList = lazy(() => import("pages/ManualInventoryWarehouseList"));
const ManualInventoryWarehouseCreate = lazy(() => import("pages/ManualInventoryWarehouseCreate"));
const ManualInventoryWarehouseUpdate = lazy(() => import("pages/ManualInventoryWarehouseUpdate"));
const ManualInventoryWarehouseUploadHistory = lazy(() => import("pages/ManualInventoryWarehouseUploadHistory"));
const ManualInventorySiteList = lazy(() => import("pages/ManualInventorySiteList"));
const ManualInventorySiteCreate = lazy(() => import("pages/ManualInventorySiteCreate"));
const ManualInventorySiteUpdate = lazy(() => import("pages/ManualInventorySiteUpdate"));
const ManualInventorySiteUploadHistory = lazy(() => import("pages/ManualInventorySiteUploadHistory"));
const SiteList = lazy(() => import("pages/SiteList"));
const SiteFormContainer = lazy(() => import("pages/SiteFormContainer"));

export default [
  // Parent menu
  {
    exact: true,
    path: "/manual-inventory",
    name: t("ManualInventory.Root"),
    icon: DropboxOutlined,
    children: ["/manual-inventory/tracking", "/manual-inventory/warehouses", "/manual-inventory/sites"],
  },
  // Sub menus
  {
    exact: true,
    path: "/manual-inventory/tracking",
    name: t("ManualInventory.InventoryTracking"),
    component: ManualInventoryTracking,
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ)],
  },
  {
    exact: true,
    path: "/manual-inventory/tracking/upload-history",
    name: t("UploadHistory"),
    component: ManualInventoryUploadHistory,
    permissions: [permission(STAFF_BFF, INVENTORY, UPDATE)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/manual-inventory/sites",
    name: t("ManualInventory.Sites"),
    component: withFeatureFlag(
      ManualInventorySiteList,
      withAssignedSingleSeller(withSinglePlatformId(SiteList, t("ManualInventory.Sites"))),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: "/manual-inventory/sites/create",
    name: t("ManualInventory.AddSite"),
    component: withFeatureFlag(
      ManualInventorySiteCreate,
      withAssignedSingleSeller(withSinglePlatformId(SiteFormContainer, t("ManualInventory.Site"))),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, CREATE_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: "/manual-inventory/sites/upload-history",
    name: t("UploadHistory"),
    component: ManualInventorySiteUploadHistory,
    permissions: [permission(STAFF_BFF, INVENTORY, UPDATE_SITE_AND_WAREHOUSE)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/manual-inventory/sites/:id",
    name: t("ManualInventory.UpdateSite"),
    component: withFeatureFlag(
      ManualInventorySiteUpdate,
      withAssignedSingleSeller(withSinglePlatformId(SiteFormContainer, t("ManualInventory.Site"))),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: "/manual-inventory/warehouses",
    name: t("ManualInventory.Warehouses"),
    component: withFeatureFlag(
      ManualInventoryWarehouseList,
      withAssignedSingleSeller(ManualInventoryWarehouseList),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: "/manual-inventory/warehouses/create",
    name: t("ManualInventory.AddWarehouse"),
    component: withFeatureFlag(
      ManualInventoryWarehouseCreate,
      withAssignedSingleSeller(ManualInventoryWarehouseCreate),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, CREATE_SITE_AND_WAREHOUSE)],
  },
  {
    exact: true,
    path: "/manual-inventory/warehouses/upload-history",
    name: t("UploadHistory"),
    component: withFeatureFlag(
      ManualInventoryWarehouseUploadHistory,
      withAssignedSingleSeller(ManualInventoryWarehouseUploadHistory),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    permissions: [permission(STAFF_BFF, INVENTORY, UPDATE_SITE_AND_WAREHOUSE)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/manual-inventory/warehouses/:id",
    name: t("ManualInventory.UpdateWarehouse"),
    component: withFeatureFlag(
      ManualInventoryWarehouseUpdate,
      withAssignedSingleSeller(ManualInventoryWarehouseUpdate),
      EXTENDED_FLAG_KEYS.MANAGE_OFFLINE_STORE_FOR_SELLER.key
    ),
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ_SITE_AND_WAREHOUSE)],
  },
];
