import { ORDER_ITEM_STATUS_FILTER_OPTIONS } from "constants/index";
import { OrderItemStatus } from "models";

// I do not write unit test for this function because we no longer need this once OMNI-1018 is on
const handleShipmentStatusFilter = (rawFilterValues: string[]) => {
  const actualShipmentStatus: string[] = [];
  const actualItemStatus: string[] = [];

  if (Array.isArray(rawFilterValues)) {
    // just to be really safe before using forEach, because TypeScript typechecking cannot help us detect bad input at runtime
    const orderItemStatusValues = ORDER_ITEM_STATUS_FILTER_OPTIONS.map((opt) => opt.status);
    rawFilterValues.forEach((value) => {
      if (orderItemStatusValues.includes(value as OrderItemStatus)) {
        actualItemStatus.push(value);
      } else {
        actualShipmentStatus.push(value);
      }
    });
  }
  return { actualShipmentStatus, actualItemStatus };
};

export default {
  handleShipmentStatusFilter,
};
