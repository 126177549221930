import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import classNames from "classnames";
import { localizationConstants } from "constants/index";
import { StoreContext } from "contexts";
import { IRegionItem, IRoute } from "models";
import React, { useContext } from "react";
import { commonUtils, iframeUtils, localizationUtils, userUtils } from "utils";
import { t } from "utils/i18n";
import SellerSwitch from "./SellerSwitch";

const { Header } = Layout;
const { REGIONS } = localizationConstants;
const { getCurrentLanguage, changeLanguage } = localizationUtils;
const { getDataFromIframe } = iframeUtils;
const { isCurrentPathUseSellerSwitch } = commonUtils;
const { logout } = userUtils;

/**
 * Decide when to hide the app header
 * Eg: hide when the container's current page is for bank or the container passes fixed seller to iframe
 */
const { isBankPage, currentSeller } = getDataFromIframe();
const headerHidden = isBankPage || currentSeller;

interface AppHeaderProps {
  filteredRoutes: IRoute[];
}

const AppHeader: React.FC<AppHeaderProps> = ({ filteredRoutes }) => {
  const { currentUser, inIframe } = useContext(StoreContext);

  const localizationMenu = (
    <Menu>
      {Object.values(REGIONS).map((el: IRegionItem) => (
        <Menu.Item key={el.key} onClick={() => changeLanguage(el.key)}>
          <Avatar src={el.flag} shape="square" />
          <span style={{ marginLeft: 10 }}>{el.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item data-testid="btn-logout" onClick={logout}>
        <LogoutOutlined />
        <span>{t("Logout")}</span>
      </Menu.Item>
    </Menu>
  );

  const currentRegion = REGIONS[getCurrentLanguage()];
  const isUseSellerSwitch = isCurrentPathUseSellerSwitch(window.location.pathname, filteredRoutes);
  return (
    <Header className={classNames("app-header", { "app-header-in-iframe": inIframe, "d-none": headerHidden })}>
      {!inIframe && (
        <>
          {isUseSellerSwitch && (
            <span className="app-user">
              <SellerSwitch />
            </span>
          )}
          <Dropdown overlay={localizationMenu} trigger={["click"]}>
            <span className="app-user">
              <Avatar src={currentRegion && currentRegion.flag} shape="square" />
              <span className="name">{currentRegion && currentRegion.name}</span>
            </span>
          </Dropdown>
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <span className="app-user">
              <Avatar src={currentUser.picture} />
              <span className="name">{currentUser.name}</span>
              <DownOutlined />
            </span>
          </Dropdown>
        </>
      )}
    </Header>
  );
};

export default AppHeader;
