import { ISiteV2 } from "models";
import { createContext } from "react";

type ManualInventorySiteContextType = {
  siteList: ISiteV2[];
};

export const ManualInventorySiteContext = createContext<ManualInventorySiteContextType>({
  siteList: [],
});
