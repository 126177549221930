import { Layout } from "antd";
import { StoreContext } from "contexts";
import { IRoute } from "models";
import React, { Suspense, useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import AppBreadcrumb from "./AppBreadcrumb";
import Route from "./Route";

const { Content } = Layout;

interface AppContentProps {
  filteredRoutes: IRoute[];
}

const AppContent: React.FC<AppContentProps> = (props) => {
  const { filteredRoutes } = props;
  const { inIframe } = useContext(StoreContext);

  return (
    <Content className="app-content">
      <Suspense fallback={null}>
        <Switch>
          {filteredRoutes.map(({ component: Component, ...rest }, index) => {
            return (
              <Route
                {...rest}
                key={`${rest.path}_${index}`}
                render={(routeProps) => {
                  const crumbs = filteredRoutes
                    // Get all routes that contain the current one
                    .filter(({ path }) => routeProps.match.path.includes(path))
                    // Swap out any dynamic routes with their param values
                    // E.g. "/products/:id" will become "/products/1"
                    .map(({ path, name }) => {
                      return {
                        path: Object.keys(routeProps.match.params).length
                          ? Object.keys(routeProps.match.params).reduce(
                              (path, param) => path.replace(`:${param}`, routeProps.match.params[param]),
                              path
                            )
                          : path,
                        breadcrumbName: name,
                      };
                    });
                  return (
                    <>
                      {!inIframe && <AppBreadcrumb crumbs={crumbs} />}
                      <Component {...routeProps} src={rest.iFrameSrc} title={rest.name} />
                    </>
                  );
                }}
              />
            );
          })}
          <Redirect from="/" to="/404" />
        </Switch>
      </Suspense>
    </Content>
  );
};

export default AppContent;
