import React from "react";
import SellerReducer from "./reducer";
import { SellerDispatch, SellerProviderProps, SellerState } from "./types";

const SellerStateContext = React.createContext<SellerState | undefined>(undefined);
const SellerDispatchContext = React.createContext<SellerDispatch | undefined>(undefined);

const initialState: SellerState = { currentSeller: null, authorizedSellerIds: [] };

const SellerProvider: React.FC<SellerProviderProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(SellerReducer, initialState);
  return (
    <SellerStateContext.Provider value={state}>
      <SellerDispatchContext.Provider value={dispatch}>{children}</SellerDispatchContext.Provider>
    </SellerStateContext.Provider>
  );
};

const useSellerState = (): SellerState => {
  const context = React.useContext(SellerStateContext);
  if (context === undefined) {
    throw new Error("useSellerState must be used within a SellerProvider");
  }
  return context;
};

const useSellerDispatch = (): SellerDispatch => {
  const context = React.useContext(SellerDispatchContext);
  if (context === undefined) {
    throw new Error("useSellerDispatch must be used within a SellerProvider");
  }
  return context;
};

export * from "./actions";
export { SellerProvider, useSellerState, useSellerDispatch };
