import { Form } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { dcaApis, iamApis, orderApis, sellerAndPlatformApis, supplyChainApis, ticketApis } from "apis";
import { appConfig } from "app-config";
import { SharedOrder } from "components/Shared_OrderDataTable_Pre1018";
import {
  EXPORT_DATE_RANGE_LIMIT,
  GET_CUSTOMER_LIST_PARAMS,
  MIN_LENGTH_TO_SEARCH_BY_PHONE,
  commonConstants,
  flagConstants,
} from "constants/index";
import { StoreContext, initialMarketOrderDetail, initialOrderDetails, useSellerState } from "contexts";
import lodashGet from "lodash/get";
import {
  BillingTaxType,
  CommentFormValues,
  CommentsAndAssignee,
  CustomerCreatePayload,
  CustomerGetListItem,
  CustomerGetListParams,
  DcaAccountGetListPayload,
  DcaAccountRoleId,
  GetTotalFilteredOrderPayload,
  IBffSeller,
  IBffTerminal,
  IConsultant,
  IDcaAccount,
  IOrderCustomer,
  IPlatform,
  ISeller,
  IStockItemPre1018,
  ListStockRequestPayload,
  MarketOrder,
  Order,
  OrderBillingInfoUpdatePayload,
  OrderConsultantUpdatePayload,
  OrderCustomerUpdatePayload,
  OrderDetails,
  OrderFilter,
  OrderFilter_Pre1018,
  OrderGetListPayload,
  OrderType,
  PostOrderCommentPayload,
  SellerOrder,
  SellerOrderFilter,
  SellerOrderGetListPayload,
  StaffOfTeam,
  StaffUserDetail,
  StockRequest,
  StockRequestCreatedSource,
  TeamOption,
  UserGetListPayload,
} from "models";
import { useCallback, useContext, useEffect, useState } from "react";
import { commonUtils, marketOrderUtils, numberUtils, orderUtils, sellerOrderUtils, sellerUtils } from "utils";
import { t } from "utils/i18n";

const { DEFAULT_PAGINATION } = commonConstants;
const { FLAG_KEYS } = flagConstants;
const { mapShipmentOptionsToShipmentStatus, getOrderStatusFilter } = orderUtils;
const { validateDateRangeList } = commonUtils;
const { getNumberDisplayFormat } = numberUtils;
const { canUseOmni252PlatformIds } = appConfig;

/*
 * Can't use the same hook, because SellerOrder relies on the (required) sellerId from App Context,
 * while MarketplaceOrder uses an (optional) sellerId from filter
 */
const useMarketOrderListDataPre1018 = (defaultFilter: OrderFilter_Pre1018, platforms?: IPlatform[]) => {
  /* ---------------------------------- state --------------------------------- */

  const [orders, setOrders] = useState<SharedOrder[]>([]);
  const [filters, setFilters] = useState(defaultFilter);
  const [fetching, setFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationProps>({
    ...DEFAULT_PAGINATION,
  });
  const [sellers, setSellers] = useState<IBffSeller[]>([]);
  const [terminals, setTerminals] = useState<IBffTerminal[]>([]);

  /* -------------------------------- functions ------------------------------- */

  const handleTableChange = (pager: PaginationProps) => {
    setFilters((prev) => ({
      ...prev,
      page: pager.current,
      pageSize: pager.pageSize,
    }));
  };

  const getOrders = async (params: OrderFilter_Pre1018) => {
    try {
      setFetching(true);

      const apiParams = {
        code: params.code?.trim(),
        phone: params.phone?.trim(),
        status: params.status,
        subStatus: params.subStatus,
        shipmentStatus: params.shipmentStatus,
        startDate:
          (params.orderDate && params.orderDate[0] && params.orderDate[0].format("YYYY-MM-DDTHH:mm:ssZ")) || undefined,
        endDate:
          (params.orderDate && params.orderDate[1] && params.orderDate[1].format("YYYY-MM-DDTHH:mm:ssZ")) || undefined,
        sellerId: params.sellerId,
        terminalCode: params.terminalCode,
        platformId: params.platformId,
        offset: params.page && params.pageSize && params.pageSize * (params.page - 1),
        limit: params.pageSize,
        sort: params.sort,
      };

      const { orders, pagination } = await orderApis.getMarketOrdersListPre1018(apiParams);
      if (orders) {
        const transformedData = marketOrderUtils.transformOrderDataByShipment(orders);
        setOrders(transformedData);
        setPagination((prev) => ({
          ...prev,
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }));
      }
    } finally {
      setFetching(false);
    }
  };

  const getTerminals = useCallback(
    async (platformId?: IPlatform["id"]) => {
      const platform = platforms?.find((platform) => platform.id === platformId);
      if (platform?.code) {
        const terminals = await sellerAndPlatformApis.getTerminalsByPlatform(platform.code);
        setTerminals(terminals);
      } else {
        setTerminals([]);
      }
    },
    [platforms]
  );

  const getSellerList = async (platformId?: IPlatform["id"]) => {
    if (platformId) {
      const sellers = await sellerAndPlatformApis.getSellersByPlatform(platformId);
      setSellers(sellers);
    } else {
      setSellers([]);
    }
  };

  /* ---------------------------------- hooks --------------------------------- */

  useEffect(() => {
    getOrders(filters);
  }, [filters, getTerminals]);

  useEffect(() => {
    if (platforms?.length === 1) {
      getSellerList(platforms[0].id);
      getTerminals(platforms[0].id);
    }
  }, [getTerminals, platforms]);

  return {
    orders,
    filters,
    setFilters,
    fetching,
    setFetching,
    pagination,
    handleTableChange,
    sellers,
    getSellerList,
    terminals,
    getTerminals,
  };
};

const useMarketOrderListData = (defaultFilter: OrderFilter, initOrders: boolean) => {
  /* ---------------------------------- state --------------------------------- */
  const { featureFlagsData } = useContext(StoreContext);
  const isOMNI1058Enabled = featureFlagsData[FLAG_KEYS.UNIFY_ORDER_STATUS_LABEL]?.enabled;

  const ORDER_STATUS_FILTER = getOrderStatusFilter(isOMNI1058Enabled);

  const [orders, setOrders] = useState<Order[]>([]);
  const [totalFilteredOrders, setTotalFilteredOrders] = useState<number | undefined>();
  const [filters, setFilters] = useState(defaultFilter);
  const [fetching, setFetching] = useState(false);
  const [fetchingCount, setFetchingCount] = useState(false);
  const [nextOffset, setNextOffset] = useState<number>(0);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [shouldGetOrders, setShouldGetOrders] = useState(initOrders);

  /* -------------------------------- functions ------------------------------- */
  const transformFilterToParams = (filter: OrderFilter) => {
    return {
      ...(filter.orderStatus && ORDER_STATUS_FILTER[filter.orderStatus].params),
      shipmentStatus: mapShipmentOptionsToShipmentStatus(filter.shipmentStatus),
      phone: filter.phone?.trim(),
      createdAtGte: filter.orderCreatedDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      createdAtLte: filter.orderCreatedDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      deliveredAtGte: filter.deliveredDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      deliveredAtLte: filter.deliveredDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      paidAtGte: filter.paymentDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      paidAtLte: filter.paymentDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      sellerId: filter.sellerId,
      terminalId: filter.terminalId,
      platformId: filter.platformId,
      consultantId: filter.consultantId,
      limit: filter.limit,
      offset: filter.offset,
      sort: filter.sort,
      isReadyToFulfill: filter.isReadyToFulfill,
      exportSiteIds: filter.exportSiteIds,
      skus: filter.skus,
    };
  };

  const transformOrdersListPayload2TotalFilteredOrderPayload = (
    ordersListPayload: OrderGetListPayload
  ): GetTotalFilteredOrderPayload => {
    const { phone, offset, limit, sort, ...rest } = ordersListPayload;
    return {
      ...rest,
      customer: ordersListPayload.phone,
    };
  };

  const getOrders = async (params: OrderFilter) => {
    try {
      setFetching(true);
      const { orders, nextOffset } = await orderApis.getMarketOrdersList(transformFilterToParams(params));
      if (orders) {
        setCanLoadMore(orders.length === (filters.limit || 0));
        setOrders(orders);
        setNextOffset(nextOffset);
      }
    } finally {
      setFetching(false);
    }
  };

  const getTotalFilteredOrders = async (params: OrderFilter) => {
    try {
      setFetchingCount(true);
      const isDatesValid =
        params.orderCreatedDate &&
        validateDateRangeList(
          EXPORT_DATE_RANGE_LIMIT,
          params.orderCreatedDate,
          params.deliveredDate,
          params.paymentDate
        );
      if (isDatesValid) {
        const { orderCount } = await orderApis.getTotalFilteredOrders(
          transformOrdersListPayload2TotalFilteredOrderPayload(transformFilterToParams(params))
        );
        setTotalFilteredOrders(orderCount);
      } else {
        setTotalFilteredOrders(undefined);
      }
    } catch {
      setTotalFilteredOrders(undefined);
    } finally {
      setFetchingCount(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      setFetching(true);
      const params = {
        ...transformFilterToParams(filters),
        offset: nextOffset,
      };
      const { orders, nextOffset: resNextOffset } = await orderApis.getMarketOrdersList(params);
      if (orders) {
        setCanLoadMore(orders.length === (filters.limit || 0));
        setOrders((prevState) => [...prevState, ...orders]);
        setNextOffset(resNextOffset);
      }
    } finally {
      setFetching(false);
    }
  };

  /* ---------------------------------- hooks --------------------------------- */

  useEffect(() => {
    if (shouldGetOrders && filters.platformId) {
      getOrders(filters);
      getTotalFilteredOrders(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    orders,
    totalFilteredOrders,
    filters,
    setFilters,
    fetching,
    fetchingCount,
    handleLoadMore,
    canLoadMore,
    shouldGetOrders,
    setShouldGetOrders,
  };
};

// deprecated after OMNI-1018
const useSellerOrderListDataPre1018 = (defaultFilter: OrderFilter_Pre1018) => {
  const [orders, setOrders] = useState<SharedOrder[]>([]);
  const [filters, setFilters] = useState<OrderFilter_Pre1018>(defaultFilter);
  const [fetching, setFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationProps>({
    ...DEFAULT_PAGINATION,
  });
  const [sellingPlatforms, setSellingPlatforms] = useState<IPlatform[]>([]);
  const [isFetchingSellingPlatforms, setIsFetchingSellingPlatforms] = useState(false);
  const [terminals, setTerminals] = useState<IBffTerminal[]>([]);

  const sellerState = useSellerState();
  const sellerId = sellerState?.currentSeller?.id;

  /* -------------------------------- functions ------------------------------- */

  const handleTableChange = (pager: PaginationProps) => {
    setFilters((prev) => ({
      ...prev,
      page: pager.current,
      pageSize: pager.pageSize,
    }));
  };

  const getOrders = async (params: OrderFilter_Pre1018, sellerId?: ISeller["id"]) => {
    try {
      setFetching(true);

      const { actualShipmentStatus, actualItemStatus } = sellerOrderUtils.handleShipmentStatusFilter(
        params.shipmentStatus || []
      );
      const apiParams = {
        code: params.code?.trim(),
        phone: params.phone?.trim(),
        shipmentStatus: actualShipmentStatus,
        subStatus: actualItemStatus,
        status: !!actualItemStatus.length ? ["PROCESSING"] : [], // due to COV validation: https://git.teko.vn/platform/tekone/-/blob/master/app/orders/cov_service/internal/service/handler_utils.go#L105
        startDate:
          (params.orderDate &&
            params.orderDate[0] &&
            params.orderDate[0].startOf("day").format("YYYY-MM-DDTHH:mm:ssZ")) ||
          undefined,
        endDate:
          (params.orderDate &&
            params.orderDate[1] &&
            params.orderDate[1].endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")) ||
          undefined,
        sellerId,
        platformId: params.platformId,
        terminalCode: params.terminalCode,
        offset: params.page && params.pageSize && params.pageSize * (params.page - 1),
        limit: params.pageSize,
        sort: params.sort,
        target: OrderType.seller,
      };

      const { orders, pagination } = await orderApis.getMarketOrdersListPre1018(apiParams);

      if (orders) {
        const transformedData = marketOrderUtils.transformOrderDataByShipment(orders, OrderType.seller);
        setOrders(transformedData);
        setPagination((prev) => ({
          ...prev,
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }));
      }
    } finally {
      setFetching(false);
    }
  };

  const getSellingPlatforms = useCallback(async () => {
    if (sellerId) {
      setIsFetchingSellingPlatforms(true);
      const result = await sellerAndPlatformApis.getSellingPlatforms(sellerId);
      setIsFetchingSellingPlatforms(false);
      setSellingPlatforms(result);
    }
  }, [sellerId]);

  const getTerminalsBySeller = useCallback(async () => {
    if (sellerId) {
      const response = await sellerAndPlatformApis.getTerminalsBySeller(sellerId);
      if (!!response.length) {
        setTerminals(response);
      }
    }
  }, [sellerId]);

  /* ---------------------------------- hooks --------------------------------- */

  useEffect(() => {
    if (sellerId === undefined) return;
    getOrders(filters, sellerId);
  }, [filters, sellerId]);

  useEffect(() => {
    getSellingPlatforms();
  }, [getSellingPlatforms]);

  useEffect(() => {
    getTerminalsBySeller();
  }, [getTerminalsBySeller]);

  return {
    orders,
    filters,
    setFilters,
    fetching,
    pagination,
    handleTableChange,
    sellingPlatforms,
    getSellingPlatforms,
    isFetchingSellingPlatforms,
    terminals,
  };
};

const useSellerOrderListData = (defaultFilter: SellerOrderFilter, currentSellerId: ISeller["id"]) => {
  const { featureFlagsData } = useContext(StoreContext);
  const enabledGetUserTerminals = featureFlagsData[FLAG_KEYS.GET_USER_TERMINALS]?.enabled;

  const [orders, setOrders] = useState<SellerOrder[]>([]);
  const [totalFilteredOrders, setTotalFilteredOrders] = useState<number | undefined>();
  const [filters, setFilters] = useState<SellerOrderFilter>(defaultFilter);
  const [fetching, setFetching] = useState(false);
  const [fetchingCount, setFetchingCount] = useState(false);
  const [sellingPlatforms, setSellingPlatforms] = useState<IPlatform[]>([]);
  const [isFetchingSellingPlatforms, setIsFetchingSellingPlatforms] = useState(false);
  const [isFetchingTerminals, setIsFetchingTerminals] = useState(false);
  const [terminals, setTerminals] = useState<IBffTerminal[]>([]);
  const [nextOffset, setNextOffset] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);

  /* -------------------------------- functions ------------------------------- */
  const transformFilterToParams = (filter: SellerOrderFilter) => {
    return {
      orderId: filter.orderId?.trim() || undefined,
      phone: filter.phone?.trim(),
      consultantId: filter.consultantId,
      shipmentStatus: mapShipmentOptionsToShipmentStatus(filter.shipmentStatus),
      platformId: filter.platformId,
      terminalId: filter.terminalId,
      skus: filter.skus,
      createdAtGte: filter.orderCreatedDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      createdAtLte: filter.orderCreatedDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      paidAtGte: filter.paymentDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      paidAtLte: filter.paymentDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      deliveredAtGte: filter.deliveredDate?.[0]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      deliveredAtLte: filter.deliveredDate?.[1]?.format("YYYY-MM-DDTHH:mm:ssZ"),
      limit: filter.limit,
      offset: filter.offset,
      sort: filter.sort,
    };
  };

  const transformSellerOrdersListPayload2SellerTotalFilteredOrderPayload = (
    sellerOrdersListPayload: SellerOrderGetListPayload
  ): SellerOrderGetListPayload => {
    const { offset, limit, sort, ...rest } = sellerOrdersListPayload;
    return {
      ...rest,
    };
  };

  const getOrders = async (params: SellerOrderFilter) => {
    try {
      setFetching(true);
      const { sellerOrders: orders, nextOffset } = await orderApis.getSellerOrdersList(transformFilterToParams(params));
      if (orders) {
        setCanLoadMore(orders.length === (filters.limit || 0));
        setOrders(orders);
        setNextOffset(nextOffset);
      }
    } finally {
      setFetching(false);
    }
  };

  const getTotalFilteredOrders = async (params: SellerOrderFilter) => {
    try {
      setFetchingCount(true);
      const isDatesValid =
        params.orderCreatedDate &&
        validateDateRangeList(
          EXPORT_DATE_RANGE_LIMIT,
          params.orderCreatedDate,
          params.deliveredDate,
          params.paymentDate
        );
      if (isDatesValid) {
        const { shipmentCount } = await orderApis.getSellerTotalFilteredOrders(
          transformSellerOrdersListPayload2SellerTotalFilteredOrderPayload(transformFilterToParams(params))
        );
        setTotalFilteredOrders(shipmentCount);
      } else {
        setTotalFilteredOrders(undefined);
      }
    } catch {
      setTotalFilteredOrders(undefined);
    } finally {
      setFetchingCount(false);
    }
  };

  const getSellingPlatforms = useCallback(async () => {
    if (currentSellerId) {
      setIsFetchingSellingPlatforms(true);
      const result = await sellerAndPlatformApis.getSellingPlatforms(currentSellerId);
      setIsFetchingSellingPlatforms(false);
      setSellingPlatforms(result);
    }
  }, [currentSellerId]);

  const getTerminalsBySeller = useCallback(async () => {
    if (currentSellerId) {
      setIsFetchingTerminals(true);
      const response = enabledGetUserTerminals
        ? await sellerAndPlatformApis.getUserTerminals({ sellerId: currentSellerId })
        : await sellerAndPlatformApis.getTerminalsBySeller(currentSellerId);
      setIsFetchingTerminals(false);
      if (!!response.length) {
        setTerminals(response);
      }
    }
  }, [currentSellerId, enabledGetUserTerminals]);

  const handleLoadMore = async () => {
    try {
      setFetching(true);
      const params = {
        ...transformFilterToParams(filters),
        offset: nextOffset,
      };
      const { sellerOrders: orders, nextOffset: resNextOffset } = await orderApis.getSellerOrdersList(params);
      if (orders) {
        setCanLoadMore(orders.length === (filters.limit || 0));
        setOrders((prevState) => [...prevState, ...orders]);
        setNextOffset(resNextOffset);
      }
    } finally {
      setFetching(false);
    }
  };

  /* ---------------------------------- hooks --------------------------------- */
  useEffect(() => {
    getOrders(filters);
    getTotalFilteredOrders(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    getSellingPlatforms();
  }, [getSellingPlatforms]);

  useEffect(() => {
    getTerminalsBySeller();
  }, [getTerminalsBySeller]);

  return {
    orders,
    totalFilteredOrders,
    filters,
    setFilters,
    fetching,
    fetchingCount,
    sellingPlatforms,
    isFetchingSellingPlatforms,
    isFetchingTerminals,
    terminals,
    canLoadMore,
    handleLoadMore,
  };
};

const useMarketOrderDetail = (orderId: string) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<MarketOrder>(initialMarketOrderDetail);

  const fetchData = useCallback(async () => {
    if (orderId) {
      setLoading(true);
      try {
        const order: MarketOrder = await orderApis.getMarketOrderDetail(orderId);
        const isOmni252EnabledForOrderPlatform =
          !canUseOmni252PlatformIds || canUseOmni252PlatformIds?.includes(order.platformId);
        setOrder({ ...orderUtils.switchItemsToRouterItems(order), isOmni252EnabledForOrderPlatform });
      } finally {
        setLoading(false);
      }
    }
  }, [orderId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return {
    loading,
    order,
    fetchData,
  };
};

const useOrderDetailsForPlatform = (orderId: string) => {
  const { featureFlagsData } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<OrderDetails>(initialOrderDetails);

  const isOmni1058Enabled = featureFlagsData[FLAG_KEYS.UNIFY_ORDER_STATUS_LABEL]?.enabled;

  const fetchData = useCallback(async () => {
    if (orderId) {
      setLoading(true);
      try {
        const { order } = await orderApis.getOrderDetailsForPlatform(orderId, getNumberDisplayFormat());

        // check if order platform is allowed for omni_252
        const isOmni252EnabledForOrderPlatform =
          !canUseOmni252PlatformIds || canUseOmni252PlatformIds?.includes(order.platformId);
        const isOmni1251EnabledForOrderPlatform =
          isOmni252EnabledForOrderPlatform && featureFlagsData[FLAG_KEYS.CONFIRM_ORDER_BY_SITE]?.enabled;

        const transformedOrder = {
          ...orderUtils.transformRawOrderDetails(order, isOmni1058Enabled),
          isOmni252EnabledForOrderPlatform,
          isOmni1251EnabledForOrderPlatform,
        };
        setOrder(transformedOrder);

        // just to adapt the sync flow of refreshWithGuarantee
        return transformedOrder;
      } finally {
        setLoading(false);
      }
    }
  }, [orderId, isOmni1058Enabled]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    order,
    fetchData,
  };
};

const useOrderDetailsForSeller = (orderId: string) => {
  const { featureFlagsData } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [sellerOrder, setSellerOrder] = useState<OrderDetails>(initialOrderDetails);

  const isOmni1058Enabled = featureFlagsData[FLAG_KEYS.UNIFY_ORDER_STATUS_LABEL]?.enabled;

  const fetchData = useCallback(async () => {
    if (orderId) {
      setLoading(true);
      try {
        const { sellerOrder } = await orderApis.getOrderDetailsForSeller(orderId, getNumberDisplayFormat());

        // check if order platform is allowed for omni_252
        const isOmni252EnabledForOrderPlatform =
          !canUseOmni252PlatformIds || canUseOmni252PlatformIds?.includes(sellerOrder.platformId);
        const isOmni1251EnabledForOrderPlatform =
          isOmni252EnabledForOrderPlatform && featureFlagsData[FLAG_KEYS.CONFIRM_ORDER_BY_SITE]?.enabled;

        const transformedOrder = {
          ...orderUtils.transformRawOrderDetails(sellerOrder, isOmni1058Enabled),
          isOmni252EnabledForOrderPlatform,
          isOmni1251EnabledForOrderPlatform,
        };
        setSellerOrder(transformedOrder);

        // just to adapt the sync flow of refreshWithGuarantee
        return transformedOrder;
      } finally {
        setLoading(false);
      }
    }
  }, [orderId, isOmni1058Enabled]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    sellerOrder,
    fetchData,
  };
};

const useSellerOrderDetail = (orderId: MarketOrder["id"]) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<MarketOrder>(initialMarketOrderDetail);

  const fetchData = useCallback(async () => {
    if (orderId) {
      setLoading(true);
      try {
        const order = await orderApis.getSellerOrderDetail(orderId);
        setOrder(orderUtils.switchItemsToRouterItems(order));
      } finally {
        setLoading(false);
      }
    }
  }, [orderId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading,
    order,
    fetchData,
  };
};

const useIncomingStockPre1018 = (item: IStockItemPre1018, orderCode: string, visible: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stockRequests, setStockRequests] = useState<StockRequest[]>([]);

  useEffect(() => {
    const fetchStockRequests = async () => {
      setLoading(true);
      try {
        const itemSiteId = item?.siteIdInt || item?.siteIdFromFfr;
        const params: ListStockRequestPayload = {
          orderCodeRef: orderCode,
          createdSources: [StockRequestCreatedSource.BACKORDER],
          requestedSiteIds: itemSiteId ? [itemSiteId] : [],
          sellerSkus: [item.sellerSku],
          sellerId: item.sellerId,
        };
        const _stockRequests = await supplyChainApis.listStockRequests(params);
        setStockRequests(_stockRequests);
      } finally {
        setLoading(false);
      }
    };

    if (visible) {
      fetchStockRequests();
    }
  }, [item, orderCode, visible]);

  return {
    loading,
    stockRequests,
  };
};

const useAuthorizedPlatforms = () => {
  const [authorizedPlatforms, setAuthorizedPlatforms] = useState<IPlatform[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const { currentUser } = useContext(StoreContext);
  const userPlatformId = Number.parseInt(lodashGet(currentUser, ["meta_data", "platformId"]));

  const fetchPlatforms = useCallback(async () => {
    if (Number.isInteger(userPlatformId)) {
      const canAccessAllPlatforms = sellerUtils.hasAllPlatforms(currentUser);
      const platforms = canAccessAllPlatforms
        ? await sellerAndPlatformApis.getAllPlatforms()
        : await sellerAndPlatformApis.getPlatform(userPlatformId).then((platform) => (platform ? [platform] : []));
      setAuthorizedPlatforms(platforms);
    }
    setIsFetching(false);
  }, [userPlatformId, currentUser]);

  useEffect(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  return {
    authorizedPlatforms: authorizedPlatforms?.sort((a, b) => a.name.localeCompare(b.name)),
    isFetching,
    userPlatformId,
  };
};

interface RenderData {
  isFetchingComments: boolean;
  comments: CommentsAndAssignee["comments"];
  assignee: CommentsAndAssignee["assignee"];
  teamOptions: CommentsAndAssignee["teamOptions"];
  assigneeOptions: StaffOfTeam[];
}

const useOrderComment = (orderId: string) => {
  const [renderData, setRenderData] = useState<RenderData>({
    assignee: {},
    comments: [],
    teamOptions: [],
    isFetchingComments: false,
    assigneeOptions: [],
  });
  const [selectedTeamId, setSelectedTeamId] = useState<TeamOption["teamId"]>();
  const [ticketId, setTicketId] = useState<number>();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [form] = Form.useForm<CommentFormValues>();
  const [hasAssigneeError, setHasAssigneeError] = useState(false);

  const fetchRenderData = useCallback(async () => {
    try {
      setRenderData((prev) => ({ ...prev, isFetchingComments: true }));
      const getCAARes = await orderApis.getCommentsAndAssignee(orderId);
      const { assignee, comments, teamOptions } = getCAARes;
      if (getCAARes) {
        setRenderData((prev) => ({
          ...prev,
          assignee,
          comments,
          teamOptions,
        }));

        if (getCAARes.ticketId) {
          setTicketId(getCAARes.ticketId);
        }
      }

      if (!!teamOptions.length) {
        const selectedTeam = teamOptions.find((teamOption) => teamOption.selected);
        if (selectedTeam && selectedTeam.teamId) {
          form.setFieldsValue({ team: selectedTeam.teamId });
          setSelectedTeamId(selectedTeam.teamId);

          const staffs = await ticketApis.getStaffsOfTeamOrEmpty(selectedTeam.teamId);
          if (staffs && !!staffs.length) {
            setRenderData((prev) => ({ ...prev, assigneeOptions: staffs }));
            if (assignee && assignee?.staffId) {
              if (isStaffInTeam(staffs, assignee.staffId)) {
                form.setFieldsValue({ assignee: assignee.staffId });
              } else {
                form.setFieldsValue({ assignee: undefined });
              }
            }
          }
        }
      }
    } finally {
      setRenderData((prev) => ({ ...prev, isFetchingComments: false }));
    }
  }, [form, orderId]);

  const isStaffInTeam = (staffOptions: StaffOfTeam[], staffId: number): boolean => {
    return staffOptions.some((option) => option.id === staffId);
  };

  const fetchStaffOptions = useCallback(
    async (teamId) => {
      try {
        setRenderData((prev) => ({ ...prev, isFetchingComments: true }));
        const staffs = await ticketApis.getStaffsOfTeamOrEmpty(teamId);
        if (staffs && !!staffs.length) {
          setRenderData((prev) => ({ ...prev, assigneeOptions: staffs }));
          const currentStaffId = renderData?.assignee?.staffId;
          if (currentStaffId && !isStaffInTeam(staffs, currentStaffId)) {
            form.setFieldsValue({ assignee: undefined });
            setHasAssigneeError(true);
          } else {
            setHasAssigneeError(false);
            form.setFieldsValue({ assignee: currentStaffId });
          }
        }
      } finally {
        setRenderData((prev) => ({ ...prev, isFetchingComments: false }));
      }
    },
    [form, renderData.assignee?.staffId]
  );

  const postComment = async (values: CommentFormValues) => {
    try {
      setIsPostingComment(true);
      const payload: PostOrderCommentPayload = {
        assigneeStaffId: values?.assignee,
        content: values?.content,
        teamId: values?.team,
        ticketId: ticketId,
      };
      await orderApis.postOrderComment(orderId, payload);
      form.resetFields();
      await fetchRenderData();
    } finally {
      setIsPostingComment(false);
    }
  };

  useEffect(() => {
    fetchRenderData();
  }, [fetchRenderData]);

  return {
    form,
    renderData,
    isBlocking,
    isPostingComment,
    isSubmittable,
    selectedTeamId,
    hasAssigneeError,
    postComment,
    fetchStaffOptions,
    setIsBlocking,
    setIsSubmittable,
    isStaffInTeam,
    setHasAssigneeError,
  };
};

const useOrderConsultant = () => {
  const [consultants, setConsultants] = useState<StaffUserDetail[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [searchHelpText, setSearchHelpText] = useState("");
  const { currentUser } = useContext(StoreContext);
  const { tenant_id } = currentUser;

  const searchByEmailOrPhone = async (searchText: string) => {
    searchText = searchText.trim();

    if (orderUtils.isInEmailFormat(searchText)) {
      setSearchHelpText("");
      const userDetailList = await getUserDetailList({ email: searchText });
      setConsultants(userDetailList);
    } else if (orderUtils.isInPhoneFormat(searchText)) {
      setSearchHelpText("");
      const userDetailList = await getUserDetailList({ phone_number: searchText });
      setConsultants(userDetailList);
    } else {
      setSearchHelpText(t("InputStaffExactEmailOrPhoneToSearch"));
    }
  };

  const getUserDetailList = async (params: UserGetListPayload): Promise<StaffUserDetail[]> => {
    try {
      setIsFetching(true);
      const userList = await iamApis.getUserListOrEmpty({
        revoked: false,
        active: true,
        fields: "id,name,email,phone_number",
        tenant_id,
        ...params,
      });
      if (!!userList.length) {
        const reqList = userList.reduce((previousValue: Promise<StaffUserDetail>[], currentValue: StaffUserDetail) => {
          if (currentValue?.id) {
            const res = iamApis.getUserDetail(currentValue.id);
            previousValue.push(res);
          }
          return previousValue;
        }, []);
        let userDetailList = await Promise.all(reqList);
        return userDetailList;
      }
      return [];
    } catch (err) {
      return [];
    } finally {
      setIsFetching(false);
    }
  };

  const updateConsultant = async (orderId: MarketOrder["id"], value: StaffUserDetail) => {
    try {
      setIsFetching(true);
      const payload: OrderConsultantUpdatePayload = {
        consultant: {
          id: value?.id,
          email: value?.email,
          name: value?.name,
          phone: value?.phone_number,
          customId: value.user_metadata?.metadata?.externalUserId,
        },
      };
      await orderApis.updateOrderConsultant(orderId, payload);
      return { success: true, newConsultant: payload.consultant as IConsultant };
    } catch (err) {
      return { success: false, newConsultant: undefined };
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    consultants,
    searchHelpText,
    updateConsultant,
    searchByEmailOrPhone,
  };
};

const useOrderCustomer = (platformId: MarketOrder["platformId"]) => {
  const [customers, setCustomers] = useState<CustomerGetListItem[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const defaultParams: CustomerGetListParams = {
    platformId: platformId,
    sortField: GET_CUSTOMER_LIST_PARAMS.SORT_FIELD.UPDATED_AT,
    sortOrder: GET_CUSTOMER_LIST_PARAMS.SORT_ORDER.DESCENDING,
  };

  const onSearchCustomer = (input: string) => {
    setCustomers([]);
    if (input?.length >= MIN_LENGTH_TO_SEARCH_BY_PHONE) {
      setIsFetching(true);
      orderApis
        .getCustomerListOrEmpty({ ...defaultParams, keyword: input })
        .then((customers) => {
          if (!!customers.length) {
            setCustomers(customers);
          }
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const updateCustomer = async (
    orderId: MarketOrder["id"],
    customer: CustomerGetListItem
  ): Promise<{ success: boolean; newCustomer?: IOrderCustomer }> => {
    const { email, userId, id, telephone, name } = customer;
    try {
      setIsFetching(true);
      const payload: OrderCustomerUpdatePayload = {
        customerInfo: {
          email: email,
          id: userId,
          profileId: id,
          phone: telephone,
          name: name,
        },
      };
      await orderApis.updateOrderCustomer(orderId, payload);
      const newCustomer: IOrderCustomer = {
        email: email || "",
        id: id || "",
        iamId: userId || "",
        name: name || "",
        phone: telephone || "",
      };
      return { success: true, newCustomer };
    } catch (error) {
      return { success: false, newCustomer: undefined };
    } finally {
      setIsFetching(false);
    }
  };

  const upsertOrderCustomer = async (payload: CustomerCreatePayload): Promise<CustomerGetListItem> => {
    try {
      setIsFetching(true);
      const { profile } = await orderApis.upsertOrderCustomer(payload);
      return profile;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    customers,
    isFetching,
    onSearchCustomer,
    updateCustomer,
    upsertOrderCustomer,
  };
};

const useOrderBillingInfo = (orderId: MarketOrder["id"], sellerId: ISeller["id"]) => {
  const [dcaAccounts, setDcaAccounts] = useState<IDcaAccount[]>([]);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const searchAccountsByTaxCode = async (taxCode: DcaAccountGetListPayload["taxCode"]) => {
    try {
      setIsFetchingAccounts(true);
      setDcaAccounts([]);
      const { accounts } = await dcaApis.getAccounts({ taxCode, roleId: DcaAccountRoleId.customer, sellerId });
      setDcaAccounts(accounts || []);
      return { success: true, isResultFound: !!accounts?.length };
    } catch (error) {
      return { success: false, isResultFound: false };
    } finally {
      setIsFetchingAccounts(false);
    }
  };

  const updateBillingInfo = async (payload: OrderBillingInfoUpdatePayload) => {
    try {
      setIsUpdating(true);
      await orderApis.updateBillingInfo(orderId, {
        ...payload,
        billingTaxType: payload.billingTaxType || BillingTaxType.VAT,
      });
      return true;
    } catch (error) {
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    dcaAccounts,
    setDcaAccounts,
    isFetchingAccounts,
    isUpdating,
    searchAccountsByTaxCode,
    updateBillingInfo,
  };
};

export default {
  useMarketOrderListDataPre1018,
  useMarketOrderListData,
  useSellerOrderListDataPre1018,
  useSellerOrderListData,
  useMarketOrderDetail,
  useOrderDetailsForPlatform,
  useOrderDetailsForSeller,
  useSellerOrderDetail,
  useIncomingStockPre1018,
  useAuthorizedPlatforms,
  useOrderComment,
  useOrderConsultant,
  useOrderCustomer,
  useOrderBillingInfo,
};
