export const DPOS_PATHS = {
  CreateOrder: "/dpos/create-order",
  CloneOrder: "/dpos/clone-order",
};

export const MANUAL_INVENTORY_PATHS = {
  InventoryTracking: "/manual-inventory/tracking",
  Sites: "/manual-inventory/sites",
};

export const ORDERS_PATHS = {
  QuotationList: "/orders/customer-quotations",
  MarketplaceOrder: "/orders/marketplace",
};

export const PURCHASING_PATHS = {
  PurchaseOrders: "/purchasing/purchase-order",
  LeadTimeConfig: "/purchasing/site-lead-time",
  PurchaseOrdersReturn: "/purchasing/por",
};

export const PRODUCT_PATHS = {
  ProductMatching: "/products/similar-product",
  InventoryTracking: "/products/inventory-tracking",
};

export const WAREHOUSING_PATHS = {
  Sites: "/warehousing/master-data/sites",
};

export const EXTERNAL_URLS = {
  Supplychain_LeadTimeConfig: "/purchasing/site-lead-time",
  Supplychain_ExportHistory: "/purchasing/export-history",
};

export const ORDER_NOTIFICATION_PATHS = {
  ROOT: "/order-notifications",
  CREATE: "/order-notifications/create",
  CLONE: "/order-notifications/clone",
};

export const SETTING_PATHS = {
  ROOT: "/setting",
  CREDIT_APPROVAL_AUTHORITY: "/setting/credit-approval-authority",
  DISCOUNT_APPROVAL: "/setting/discount-approval",
  RETURN_REASONS: "/setting/return-reasons",
  LOYALTY_PROGRAM: "/setting/loyalty-program",
  DEBT_APPROVAL_CONFIG: "/setting/debt-approval-config",
};

export const ACCOUNTING_PATHS = {
  CONFIRM_PURCHASE_INVOICE: "/accounting/purchase-invoices/confirm",
};

export const INTERNAL_PATH = {
  ROOT: "/internal",
  FLAGS_LIST: "/internal/flags-list",
};

export default {
  DPOS_PATHS,
  MANUAL_INVENTORY_PATHS,
  ORDERS_PATHS,
  PURCHASING_PATHS,
  PRODUCT_PATHS,
  WAREHOUSING_PATHS,
  ORDER_NOTIFICATION_PATHS,
  SETTING_PATHS,
  ACCOUNTING_PATHS,
  INTERNAL_PATH,
};
