import { DownOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Menu, Row, Space, Typography } from "antd";
import { updateCurrentSeller, useSellerDispatch, useSellerState } from "contexts";
import { ISeller } from "models";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useState } from "react";
import { t } from "utils/i18n";
import "./SellerSwitch.scss";

const SellerSwitch: React.FC = () => {
  const { currentSeller, sellers } = useSellerState();
  const dispatch = useSellerDispatch();
  const [filterBy, setFilterBy] = useState<string>();

  const handleMenuClick = useCallback(
    (e: MenuInfo) => {
      const currentSellerId = e.key;
      const currentSeller = sellers?.find((s) => s.id.toString() === currentSellerId);
      updateCurrentSeller(dispatch, currentSeller || null);
      setFilterBy("");
    },
    [dispatch, sellers]
  );

  const sellerMenu = (
    <Menu onClick={handleMenuClick} className="menu-seller-list" data-testid="seller-switch_menu">
      <div className="p-half">
        <Input
          className="w-100"
          color="primary"
          placeholder={t("Search")}
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
          data-testid="seller-switch_input"
        />
      </div>

      {sellers
        ?.filter((seller) => {
          if (!filterBy) return seller;
          return seller.name.toLocaleLowerCase().includes(filterBy.toLocaleLowerCase());
        })
        .map((seller: ISeller) => (
          <Menu.Item key={seller.id} data-testid={`seller-switch_${seller.id}`}>
            <Space>
              <Avatar className="select-icon" shape="square" src={seller?.logoUrl} />
              {seller.name}
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={sellerMenu} trigger={["click"]}>
      <Button className="seller-btn" type="text">
        <Row align="middle" justify="space-between">
          <Space className="seller-item">
            <Avatar shape="square" src={currentSeller?.logoUrl} />
            <Typography.Text strong>{currentSeller?.name || t("Seller")}</Typography.Text>
            <DownOutlined />
          </Space>
        </Row>
      </Button>
    </Dropdown>
  );
};
export default SellerSwitch;
