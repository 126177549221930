import { BellOutlined } from "@ant-design/icons";
import { t } from "utils/i18n";
import { lazy } from "react";
import {
  actionConstants,
  appConstants,
  resourceConstants,
  flagConstants,
  ORDER_NOTIFICATION_PATHS,
} from "constants/index";
import { userUtils } from "utils";
import { withSinglePlatformId } from "hocs";

const { permission } = userUtils;

const { FLAG_KEYS } = flagConstants;
const { ONS_BFF } = appConstants;
const { ORDER_NOTIFICATIONS } = resourceConstants;
const { READ, UPDATE } = actionConstants;

// Pages
const OrderNotificationList = lazy(() => import("pages/OrderNotificationList"));
const OrderNotificationFormContainer = lazy(() => import("pages/OrderNotificationFormContainer"));

export default [
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATHS.ROOT,
    name: t("OrderNotification.Root"),
    icon: BellOutlined,
    component: withSinglePlatformId(OrderNotificationList, t("OrderNotification.Root")),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, READ)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATHS.CREATE,
    name: t("OrderNotification.Update"),
    component: withSinglePlatformId(OrderNotificationFormContainer, t("OrderNotification.Update")),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, UPDATE)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: `${ORDER_NOTIFICATION_PATHS.ROOT}/:id`,
    name: t("OrderNotification.Update"),
    component: withSinglePlatformId(OrderNotificationFormContainer, t("OrderNotification.Update")),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, READ)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: `${ORDER_NOTIFICATION_PATHS.CLONE}/:id`,
    name: t("OrderNotification.Create"),
    component: withSinglePlatformId(OrderNotificationFormContainer, t("OrderNotification.Create")),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, UPDATE)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
];
