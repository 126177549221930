import { StoreContext } from "contexts";
import React, { useContext } from "react";

export const withFeatureFlag = <P extends {}, T extends {}>(
  OldComponent: React.FC<P>,
  NewComponent: React.FC<T>,
  flagKey: string
): React.FC<P & T> => {
  return (props) => {
    const { featureFlagsData } = useContext(StoreContext);
    const isFlagEnabled = featureFlagsData[flagKey]?.enabled;

    return isFlagEnabled ? <NewComponent {...props} /> : <OldComponent {...props} />;
  };
};
