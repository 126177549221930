import classNames from "classnames";
import React from "react";

interface GetTrackingPropsParams {
  name?: string;
  target?: string;
  payload?: string;
}

type WithTrackingHelpers = {
  getTrackingProps: (param: GetTrackingPropsParams) => Record<string, string | boolean>;
};

interface WithTrackingProps extends GetTrackingPropsParams {
  children: React.ReactElement | ((helpers: WithTrackingHelpers) => React.ReactElement);
  regionName?: string;
}

const WithTracking: React.FC<WithTrackingProps> = ({ children, name, regionName, target, payload, ...props }) => {
  if (typeof children === "function") {
    const getTrackingProps = ({ name, target, payload }: GetTrackingPropsParams) => ({
      "data-track-content": true,
      "data-content-region-name": regionName || "root",
      ...(name && { "data-content-name": name }),
      ...(target && { "data-content-target": target }),
      ...(payload && { "data-content-payload": payload }),
    });
    return children({ getTrackingProps });
  }

  const child = React.Children.only(children) as React.ReactElement;
  const parentProps = props as Record<string, any>;
  const childProps = child?.props || {};

  // Pass parent props into child props for nest usage
  const passedProps: Record<string, any> = {};
  const passedEventList = [
    "onContextMenu",
    "onClick",
    "onMouseDown",
    "onMouseEnter",
    "onMouseLeave",
    "onFocus",
    "onBlur",
  ];

  passedEventList.forEach((eventName) => {
    if (parentProps[eventName]) {
      passedProps[eventName] = (...args: any[]) => {
        childProps[eventName]?.(...args);
        parentProps[eventName](...args);
      };
    }
  });

  passedProps.className = classNames(childProps?.className, parentProps?.className) || undefined;

  return React.cloneElement(child, {
    "data-track-content": true,
    "data-content-region-name": regionName || "root",
    ...passedProps,
    ...(name && { "data-content-name": name }),
    ...(target && { "data-content-target": target }),
    ...(payload && { "data-content-payload": payload }),
  });
};

export default WithTracking;
