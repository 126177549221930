import { appConfig } from "app-config";
import { INumberDisplayFormat } from "models";
import localizationUtils from "utils/localization";
import { parseJsonObject } from "utils/independent";

const { iam, tracker } = appConfig;

/**
 * Check whether current app is in iframe or not
 */
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Get data passed from container (staff interface) to iframe (current app)
 */
const getDataFromIframe = (): {
  localization?: string;
  isBankPage?: boolean;
  currentSeller?: string;
  currentPlatform?: number;
  parentOrigin?: string;
  clientId?: string;
  oauthDomain?: string;
  isAdminDomain?: boolean;
  trackingAppId?: string;
  numberDisplayFormat?: INumberDisplayFormat;
} => {
  return parseJsonObject(window.name);
};

const getIframeNameObj = () => {
  const {
    clientId: iframeClientId,
    oauthDomain: iframeOauthDomain,
    trackingAppId: iframeTrackingAppId,
  } = getDataFromIframe();
  const clientId = iframeClientId || iam.clientId;
  const oauthDomain = iframeOauthDomain || iam.oauthDomain;
  const trackingAppId = iframeTrackingAppId || tracker.appId || "";

  const iframeNameObj = JSON.stringify({
    clientId,
    oauthDomain,
    trackingAppId,
    localization: localizationUtils.getCurrentLanguage(),
  });

  return iframeNameObj;
};

export default {
  inIframe,
  getDataFromIframe,
  getIframeNameObj,
};
