import { Result, Spin } from "antd";
import { sellerAndPlatformApis } from "apis";
import { commonConstants } from "constants/index";
import { StoreContext, useSellerState } from "contexts";
import { commonHooks } from "hooks";
import { IPlatform, ISeller } from "models";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";

const { DEFAULT_FILTERS } = commonConstants;

export interface WithPlatformOwnerPermissionProps {
  platforms: IPlatform[];
  isFetching?: boolean;
}

export const withPlatformOwnerPermission = <P extends {}>(
  Component: React.FC<P>
): React.FC<P & WithPlatformOwnerPermissionProps> => {
  return (props) => {
    const { currentUser } = useContext(StoreContext);
    commonHooks.useSellerFetch(currentUser);
    const { currentSeller, authorizedSellerIds } = useSellerState();
    const [platforms, setPlatforms] = useState<IPlatform[]>();
    const [isFetching, setIsFetching] = useState(false);

    const userIdentity = currentUser.email || currentUser.phone_number || currentUser.name;
    const currentSellerId = currentSeller?.id;
    const isFetchingSellers = !currentSeller && authorizedSellerIds.length > 0;

    const fetchPlatforms = useCallback(async (sellerId: ISeller["id"]) => {
      setIsFetching(true);
      const params = {
        ...DEFAULT_FILTERS.PLATFORM,
        ownerSellerId: sellerId || undefined,
      };
      try {
        const result = await sellerAndPlatformApis.getPlatformOptionsOrEmpty(params);
        if (!!result) {
          setPlatforms(result);
        }
      } finally {
        setIsFetching(false);
      }
    }, []);

    useEffect(() => {
      if (!currentSellerId) return;
      fetchPlatforms(currentSellerId);
    }, [currentSellerId, fetchPlatforms]);

    if (isFetchingSellers || isFetching) return <Spin spinning={isFetchingSellers || isFetching} />;

    if (!platforms || platforms.length === 0) {
      return (
        <Result
          status="403"
          subTitle={
            <Trans i18nKey="InvalidSellerOwnerMsg">
              Tài khoản <span className="text-primary text-medium"> {{ userIdentity }} </span> chưa được gán Seller hợp
              lệ để xem loyalty program.
              <br /> Vui lòng liên hệ Admin <a href="mailto:support@teko.vn">support@teko.vn</a> để được hỗ trợ.
            </Trans>
          }
        />
      );
    }

    return <Component {...props} platforms={platforms?.sort((a, b) => a.name.localeCompare(b.name))} />;
  };
};
