import localStorageConstants from "constants/local-storage";
import localizationConstants from "constants/localization";
import iframeUtils from "utils/iframe";

const { LOCALIZATION } = localStorageConstants;
const { REGIONS } = localizationConstants;

const getCurrentLanguage = () => {
  const { localization } = iframeUtils.getDataFromIframe();
  const language = localization || localStorage.getItem(LOCALIZATION);
  return language || REGIONS.vi.key;
};

const changeLanguage = (language: string) => {
  if (language === getCurrentLanguage()) return;
  localStorage.setItem(LOCALIZATION, language);
  window.location.reload();
};

export default {
  getCurrentLanguage,
  changeLanguage,
};
