import { HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import React from "react";
import { Link } from "react-router-dom";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { logout } = userUtils;

export const BackToHomeButton: React.FC<ButtonProps> = () => (
  <Link to="/">
    <Button type="primary" icon={<HomeOutlined />}>
      {t("BackToHome")}
    </Button>
  </Link>
);

export const LogoutButton: React.FC<ButtonProps> = () => (
  <Button icon={<LogoutOutlined />} onClick={logout}>
    {t("Logout")}
  </Button>
);

export const DefaultButton: React.FC<ButtonProps> = ({ children, ...rest }) => <Button {...rest}>{children}</Button>;

export const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Button type="primary" {...rest}>
    {children}
  </Button>
);
