import { ISeller } from "./common";

export enum DcaAccountRoleId {
  customer = "customer",
}

export interface IDcaAccount {
  address: string | null;
  id: number;
  isCompany: boolean;
  name: string;
  refId: string | null;
  refSource: string | null;
  roleId: DcaAccountRoleId;
  sellerId: number;
  taxCode: string;
  telephone: string;
  dcaId?: number;
  accountLookupId?: number;
  email?: string;
}

export interface DcaAccountGetListPayload {
  taxCode?: IDcaAccount["taxCode"];
  name?: IDcaAccount["name"];
  telephone?: IDcaAccount["telephone"];
  accountLookupId?: IDcaAccount["accountLookupId"];
  roleId?: DcaAccountRoleId;
  sellerId?: ISeller["id"];
  limit?: number;
  offset?: number;
}
