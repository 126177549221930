import { HomeOutlined, ReadOutlined } from "@ant-design/icons";
import { lazy } from "react";
import { t } from "utils/i18n";
import { computeTrackingScreenName } from "utils/tracking";
import accountingSubmenus from "./accounting";
import cashierSubmenus from "./cashier";
import internal from "./internal";
import manualInventory from "./manualInventory";
import orderHealth from "./orderHealth";
import ordersSubmenus from "./orders";
import purchasingSubmenus from "./purchasing";
import reportsSubmenus from "./reports";
import settingSubmenus from "./setting";
import orderNotificationSubMenus from "./orderNotification";

const Home = lazy(() => import("pages/Home"));
const StatusTranslations = lazy(() => import("pages/StatusTranslations"));

const routes = [
  {
    exact: true,
    path: "/",
    name: t("Home"),
    component: Home,
    icon: HomeOutlined,
  },
  ...purchasingSubmenus,
  ...accountingSubmenus,
  ...cashierSubmenus,
  ...reportsSubmenus,
  ...settingSubmenus,
  ...ordersSubmenus,
  ...manualInventory,
  ...orderHealth,
  ...orderNotificationSubMenus,
  ...internal,
  {
    exact: true,
    path: "/status-translations",
    name: "Status Translations",
    component: StatusTranslations,
    icon: ReadOutlined,
  },
];

export default routes.map((route) => ({
  screenName: computeTrackingScreenName(route.path),
  ...route,
}));
