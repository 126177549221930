import { currentUser } from "apiMocks/user";
import { IFlagsData } from "models";
import { createContext } from "react";
import { IUserDetailsInfo } from "teko-oauth2";

export interface StoreContextType {
  inIframe: boolean;
  currentUser: IUserDetailsInfo;
  featureFlagsData: IFlagsData;
}

export const StoreContext = createContext<StoreContextType>({
  inIframe: false,
  currentUser,
  featureFlagsData: {},
});
