import requestApis from "apis/request";
import { fileConstants } from "constants/index";
import {
  IFulfillmentProvider,
  IGetSellerSitesParams,
  IGetUserSitesParams,
  IGetUserSitesResponse,
  ISeller,
  ISellerSite,
  ISite,
  ISiteCreatePayload,
  ISiteQueryParam,
  ISiteShippingTypeLocationPayload,
  ISiteUpdatePayload,
  ISiteV2,
  ISiteV2CreatePayload,
  ISiteV2CreateResponse,
  ISiteV2GetListPayload,
  ISiteV2UpdatePayload,
  IStockQueryParamSite,
  IWarehouseCreatePayload,
  IWarehouseQueryParam,
} from "models";

const { staffBffClient } = requestApis;

const getWarehousesBySite = async (sellerId: ISeller["id"]): Promise<Array<IStockQueryParamSite>> => {
  const res = await staffBffClient.get(`api/v1/sites/${sellerId}`);
  return res.data?.data;
};

const getWarehouses = async (params: IWarehouseQueryParam) => {
  return staffBffClient.get(`api/v1/warehouses`, { params });
};

const getWarehouse = async (params: { warehouseId: number }) => {
  const res = await staffBffClient.get(`api/v1/warehouses/${params.warehouseId}`);
  return res.data;
};

const createWarehouse = async (payload: IWarehouseCreatePayload) => {
  return staffBffClient.post("/api/v1/warehouses", { ...payload });
};

const updateWarehouse = async (payload: IWarehouseCreatePayload & { id: number }) => {
  return staffBffClient.put(`/api/v1/warehouses/${payload.id}`, { ...payload });
};

const getSites = async (params?: ISiteQueryParam) => {
  return staffBffClient.get(`api/v1/sites`, { params });
};

const getSitesV2 = async (params?: ISiteV2GetListPayload): Promise<ISiteV2[]> => {
  const res = await staffBffClient.get("api/v2/sites", { params });
  return res.data?.data;
};

const getUserSites = async (params?: IGetUserSitesParams): Promise<IGetUserSitesResponse> => {
  const res = await staffBffClient.get("api/v1/sites/user", { params });
  return res.data?.data;
};

const getSitesOrEmpty = async (params: ISiteQueryParam): Promise<ISite[]> => {
  try {
    const response = await staffBffClient.get(`api/v1/sites`, { params });
    const { records } = response.data;
    return records;
  } catch {
    return [];
  }
};

const getSite = async (params: { siteId: number }) => {
  return staffBffClient.get(`api/v1/sites/${params.siteId}/one`);
};

const createSite = async (payload: ISiteCreatePayload) => {
  return staffBffClient.post("/api/v1/sites", { ...payload });
};

const createSiteV2 = async (payload: ISiteV2CreatePayload): Promise<ISiteV2CreateResponse> => {
  const { data } = await staffBffClient.post("/api/v1/sites", payload);
  return data;
};

const updateSite = async (payload: ISiteUpdatePayload & { id?: number }) => {
  return staffBffClient.put(`/api/v1/sites/${payload.id}`, { ...payload });
};

const updateSiteV2 = async (payload: ISiteV2UpdatePayload) => {
  return staffBffClient.put(`/api/v1/sites/${payload.site.id}`, payload);
};

const updateSiteShippingTypeLocation = async (payload: ISiteShippingTypeLocationPayload) => {
  return staffBffClient.post("/api/v1/update-site-shipping-type-location", payload);
};

const downloadWarehouseTemplate = async () => {
  return staffBffClient.get("/api/v1/export-template", {
    responseType: "blob",
    headers: {
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    params: { type: fileConstants.FILE_TYPE.WAREHOUSE },
  });
};

const downloadSiteTemplate = async () => {
  return staffBffClient.get("/api/v1/export-template", {
    responseType: "blob",
    headers: {
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    params: { type: fileConstants.FILE_TYPE.SITE },
  });
};

const uploadWarehouseAndSiteFile = (payload: FormData) => {
  return staffBffClient.post(`api/v1/import-central`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getFulfillmentProvidersOrEmpty = async (): Promise<IFulfillmentProvider[]> => {
  const { data } = await staffBffClient.get("api/v1/fulfillment_providers");
  return data?.data?.providers || [];
};

const getSellerSites = async (sellerId: number, params?: IGetSellerSitesParams): Promise<ISellerSite[]> => {
  const res = await staffBffClient.get(`/api/v1/sites/${sellerId}`, { params });
  return res?.data?.data;
};

const deactivateSite = async (siteId: ISiteV2["id"]) => {
  return staffBffClient.post(`/api/v1/sites/${siteId}/deactivate`);
};

const requestGenerateSiteInfo = (siteId: number) => {
  return staffBffClient.post("/api/v1/ws/report/site-info", { siteId });
};

export default {
  getWarehousesBySite,
  getWarehouses,
  getWarehouse,
  createWarehouse,
  updateWarehouse,
  getSites,
  getSitesV2,
  getSitesOrEmpty,
  getSite,
  createSite,
  createSiteV2,
  updateSite,
  updateSiteV2,
  downloadWarehouseTemplate,
  downloadSiteTemplate,
  uploadWarehouseAndSiteFile,
  getFulfillmentProvidersOrEmpty,
  updateSiteShippingTypeLocation,
  getSellerSites,
  deactivateSite,
  getUserSites,
  requestGenerateSiteInfo,
};
