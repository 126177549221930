import { Spin } from "antd";
import { userApis } from "apis";
import { StoreContext } from "contexts";
import { flagHooks, userHooks } from "hooks";
import { IRoute } from "models";
import React, { useEffect } from "react";
import { iframeUtils } from "utils";
import Route from "./Route";

const PrivateRoute = ({ component: Component, ...rest }: IRoute) => {
  useEffect(() => {
    const userId = userApis.getUserInfo()?.sub;
    // Tracking - Set userId when logged in
    track("setUserId", userId);

    // Tracking - Enable content impressions
    track("enableTrackVisibleContentImpressions");
    return () => {
      // Tracking - Disable content impressions
      track("disableTrackVisibleContentImpressions");
    };
  }, []);

  // Check if user is logged in or not
  if (!userApis.isLoggedIn()) {
    userApis.login();
    return null;
  }

  // Fetch global data
  const { currentUser } = userHooks.useUserInfo();
  const { featureFlagsData, isInitiating } = flagHooks.useFeatureFlags(currentUser);

  // Show spin when fetching required global data
  if (!currentUser || isInitiating) {
    return <Spin className="app-spin" />;
  }

  return (
    <StoreContext.Provider
      value={{
        inIframe: iframeUtils.inIframe(),
        currentUser,
        featureFlagsData,
      }}
    >
      <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
    </StoreContext.Provider>
  );
};

export default PrivateRoute;
