import requestApis from "apis/request";
import { commonConstants } from "constants/index";
import {
  IGetSuppliersParams,
  IGetSuppliersResponse,
  IPurchaseOrderGetListResponseData,
  IPurchaseOrdersGetParams,
  ISeller,
  ListStockRequestPayload,
  StockRequest,
} from "models";

const { staffBffClient } = requestApis;
const { X_SELLER_ID } = commonConstants;

const listStockRequests = async (params: ListStockRequestPayload): Promise<StockRequest[]> => {
  const { data } = await staffBffClient.post("/api/v1/stock_request/following", params);
  return data?.data.stockRequests;
};

const getPurchaseOrders = async (
  params: IPurchaseOrdersGetParams,
  sellerId: ISeller["id"]
): Promise<IPurchaseOrderGetListResponseData> => {
  const { data } = await staffBffClient.get("/api/v1/get-purchase-order", {
    params,
    headers: { [X_SELLER_ID]: sellerId },
  });
  return data.data;
};

const getSuppliers = async (params: IGetSuppliersParams, sellerId: ISeller["id"]): Promise<IGetSuppliersResponse> => {
  const { data } = await staffBffClient.get("/api/v2/suppliers", {
    params,
    headers: { [X_SELLER_ID]: sellerId },
  });
  return data.data;
};

export default {
  listStockRequests,
  getPurchaseOrders,
  getSuppliers,
};
