export const GRAY = {
  main: "#4F4F4F",
  light: "#B7B7B7",
  flash: "#F3F3F7",
};

export const GREEN = {
  main: "#30CD60",
  light: "#94E3AE",
};

export const RED = {
  light: "#EB5757",
  dark: "#DA4343",
};

export const BLUE = {
  light: "#2D9CDB",
};

export const YELLOW = {
  main: "#F2994A",
  light: "#FFC440",
};

export default { GREEN, RED, BLUE, YELLOW };
