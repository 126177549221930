import { ISeller } from "models";
import { SellerDispatch } from "./types";

export const updateSellers = (dispatch: SellerDispatch, payload: ISeller[]): void => {
  dispatch({ type: "UPDATE_SELLERS", payload });
  dispatch({ type: "UPDATE_CURRENT_SELLER", payload: payload[0] || null });
};
export const updateCurrentSeller = (dispatch: SellerDispatch, payload: ISeller | null): void => {
  dispatch({ type: "UPDATE_CURRENT_SELLER", payload });
};
export const updateAuthorizedSellerIds = (dispatch: SellerDispatch, payload: number[]): void => {
  dispatch({ type: "UPDATE_AUTHORIZED_SELLER_IDS", payload });
};
