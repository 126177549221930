import { Layout } from "antd";
import { StoreContext } from "contexts";
import { flagHooks, trackingHooks, userHooks } from "hooks";
import { IRoute } from "models";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";
import { iframeUtils } from "utils";
import AppContent from "./AppContent";
import AppHeader from "./AppHeader";
import "./AppLayout.scss";
import AppSider from "./AppSider";

const { getDataFromIframe } = iframeUtils;
const { useTrackEventsFromParent } = trackingHooks;

const AppLayout: React.FC = () => {
  const { filteredNavigation: filteredNavigationByPermissions } = userHooks.useAuthorizationData(routes as IRoute[]);

  const { filteredNavigation, filteredRoutes } = flagHooks.useFilterNavigationByFeatureFlags(
    filteredNavigationByPermissions
  );

  const { inIframe } = useContext(StoreContext);

  const location = useLocation();
  useEffect(() => {
    if (inIframe) {
      const parentWindow = window.parent;
      const { parentOrigin = "" } = getDataFromIframe();
      const { search, pathname } = window.location;
      parentWindow.postMessage(`pathname=${pathname}${search}`, parentOrigin);
      parentWindow.postMessage("component-rendered", parentOrigin); // https://confluence.teko.vn/display/HCM9/OMPRODUCT-545%3A+Tracking+component+GiveFeedback
    }
  }, [location, inIframe]);

  useTrackEventsFromParent();

  return (
    <Layout className="app-layout">
      {!inIframe && <AppSider filteredNavigation={filteredNavigation} />}
      <Layout>
        <AppHeader filteredRoutes={filteredRoutes} />
        <AppContent filteredRoutes={filteredRoutes} />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
