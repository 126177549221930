import { MedicineBoxOutlined } from "@ant-design/icons";
import { actionConstants, appConstants, resourceConstants } from "constants/index";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_BFF } = appConstants;
const { ORDER_HEALTH } = resourceConstants;
const { READ } = actionConstants;

// Pages
const OrderHealthList = lazy(() => import("pages/OrderHealthList"));

export default [
  // Parent menu
  {
    exact: true,
    path: "/order-health",
    name: t("OrderHealth"),
    icon: MedicineBoxOutlined,
    component: OrderHealthList,
    permissions: [permission(STAFF_BFF, ORDER_HEALTH, READ)],
  },
];
