import { IBasePaginationParams } from "models/common";

export interface GenericEntity {
  name: string;
  id: number;
}

export interface OrderNotificationRuleResponse {
  rules: OrderNotificationRuleListRecord[];
}

export interface OrderNotificationRuleDetailResponse {
  rule: OrderNotificationRule;
}

export interface OrderNotificationRuleListRecord extends GenericEntity {
  notificationType: OrderNotificationType;
  status: OrderNotificationStatus;
  receivers: string[];
  updatedBy: string;
  updatedAt: number;
}

export interface OrderNotificationRule extends Required<GenericEntity> {
  type: OrderNotificationType;
  status: OrderNotificationStatus;
  title: string;
  content: string;
  receivers: string[];
  topicId: number;
  brandId: number;
  sellerIds: number[];
  terminalIds: number[];
}

export interface UpsertOrderNotificationRulePayload extends Partial<OrderNotificationRule> {}

export interface OrderNotificationFormInterface
  extends Omit<Required<UpsertOrderNotificationRulePayload>, "title" | "topicId" | "receivers"> {
  title?: string;
  templateKey?: string;
  customReceivers?: string;
  receivers?: string;
}

export enum OrderNotificationType {
  EMAIL = "EMAIL",
  OTT = "OTT",
  SMS = "SMS",
}

export enum OrderNotificationStatus {
  CREATING = "CREATING",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export type OrderNotificationStatusWithoutCreating = Exclude<OrderNotificationStatus, "CREATING">;

export type UserNotificationBrand = GenericEntity;

export interface LoadTemplateScreenResponse {
  brands: UserNotificationBrand[];
}

export interface GetListParamsPayload extends IBasePaginationParams {
  keyword?: string;
  serviceIds?: string;
}

export interface UserNotificationParam {
  id: number;
  serviceId: number;
  service: string;
  code: string;
  description: string;
  createdBy?: string;
  createdAt?: number;
  updatedBy?: string;
  updatedAt?: number;
}

export interface ListParamResponse {
  total: number;
  params: UserNotificationParam[];
  services: GenericEntity[];
}

export interface OrderNotificationTopic {
  id: number;
  name: OrderNotificationTopicKey;
}

export interface GetOrderNotificationTopicResponse {
  topics: OrderNotificationTopic[];
}

export enum OrderNotificationTopicKey {
  ORDER_IS_CREATED_NOT_FULLY_PAID = "ORDER_IS_CREATED_NOT_FULLY_PAID",
  ORDER_IS_FULLY_PAID = "ORDER_IS_FULLY_PAID",
  ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID = "ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID",
  ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID = "ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID",
  ORDER_IS_WAITING_FOR_DEALER_APPROVAL = "ORDER_IS_WAITING_FOR_DEALER_APPROVAL",
  SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF = "SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF",
  SHIPMENT_IS_DELIVERING = "SHIPMENT_IS_DELIVERING",
  SHIPMENT_IS_DELIVERED = "SHIPMENT_IS_DELIVERED",
  SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP = "SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP",
  SHIPMENT_NEED_ACTION = "SHIPMENT_NEED_ACTION",
  ORDER_IS_CANCELLED = "ORDER_IS_CANCELLED",
  ORDER_IS_DELIVERED = "ORDER_IS_DELIVERED",
}

export enum ONRFormAction {
  UPDATE = "update",
  CREATE = "create",
  CLONE = "clone",
}

export interface LoadTemplateScreenParams {
  msgTypes?: string;
}
