import { sellerAndPlatformApis } from "apis";
import { appConfig } from "app-config";
import lodashGet from "lodash/get";
import { IPlatform, ISeller } from "models";
import { IUserDetailsInfo } from "teko-oauth2";
import { iframeUtils } from "utils";

const { tenantIds } = appConfig;

export function getAccessibleSellers(fullSellerList: ISeller[], userSellerIds: number[]): ISeller[] {
  if (userSellerIds.includes(0)) return fullSellerList; // 0 === all sellers
  return fullSellerList.filter((s) => userSellerIds.includes(s.id));
}
export function getAuthorizedSellerIds(
  userInfo: IUserDetailsInfo | undefined,
  ignoreParentApp?: boolean
): Array<ISeller["id"]> {
  if (!userInfo) return [];
  const { meta_data } = userInfo;
  if (!meta_data) return [];
  const { seller_id } = meta_data;
  if (!seller_id || !seller_id.split) return [];

  if (ignoreParentApp) {
    // Get authorized sellers from IAM user config
    return seller_id.split(",").map((idString: string) => parseInt(idString));
  }

  // If has fixed seller passed from container to iframe, return this seller
  const { currentSeller } = iframeUtils.getDataFromIframe();
  if (currentSeller && (seller_id === "0" || seller_id.includes(currentSeller))) {
    return [parseInt(currentSeller)];
  } else if (currentSeller && !seller_id.includes(currentSeller)) {
    return [];
  }

  // Get authorized sellers from IAM user config
  return seller_id.split(",").map((idString: string) => parseInt(idString));
}

export function getDefaultSellerOption(userInfo?: IUserDetailsInfo) {
  const sellerId = userInfo?.meta_data?.seller_id;
  if (!sellerId) return undefined;
  return sellerId.split(",").map((idString: string) => parseInt(idString))[0];
}

export function hasAllSellers(userInfo?: IUserDetailsInfo) {
  const sellerId = userInfo?.meta_data?.seller_id;
  if (!sellerId) return false;
  else if (sellerId === "0") return true;
  else return false;
}

export function hasAllPlatforms(userInfo?: IUserDetailsInfo) {
  const platformId = Number.parseInt(userInfo?.meta_data?.platformId);
  const tenantId = Number.parseInt(userInfo?.tenant_id || "");
  if (platformId === 0 && tenantId === tenantIds?.teko) return true;
  return false;
}

export async function checkIsPlatformOwner(
  currentUser: IUserDetailsInfo,
  currentSeller: ISeller | null
): Promise<{ isPlatformOwner: boolean; platformDetail: IPlatform | null }> {
  const userPlatformId = Number.parseInt(lodashGet(currentUser, ["meta_data", "platformId"]));
  const platformDetail = await sellerAndPlatformApis.getPlatform(userPlatformId);
  if (platformDetail && platformDetail.ownerSellerId === currentSeller?.id) {
    return { isPlatformOwner: true, platformDetail };
  }
  return { isPlatformOwner: false, platformDetail: null };
}

export default {
  getAccessibleSellers,
  getAuthorizedSellerIds,
  getDefaultSellerOption,
  hasAllSellers,
  hasAllPlatforms,
  checkIsPlatformOwner,
};
