import { IOrderItem, ISeller } from "models";

export interface StockRequest {
  // Site Transfer
  stockTransferRef: string; // ST code
  stockTransferStatus?: string;

  // Purchase Order
  purchaseOrderRef: string; // PO code
  purchaseOrderStatus?: string;

  expectedIncomingDate: string; //date time
  confirmedQty: number;
  orderCodeRef: string;
}

export enum StockRequestCreatedSource {
  SYSTEM = "system",
  BACKORDER = "backorder",
  MANUAL = "manual",
}

export interface ListStockRequestPayload {
  orderCodeRef: string;
  createdSources: StockRequestCreatedSource[];
  requestedSiteIds: number[];
  sellerSkus: string[];
  sellerId: ISeller["id"];
  limit?: number;
}

export interface IStockItemPre1018
  extends Pick<
    IOrderItem,
    "sku" | "sellerSku" | "name" | "siteId" | "siteCode" | "siteIdInt" | "sellerId" | "siteName" | "siteIdFromFfr"
  > {}
