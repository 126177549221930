import { IAction } from "components/StickyFooter";
import { GRAY, GREEN, RED } from "constants/color";
import {
  OrderNotificationStatus,
  OrderNotificationStatusWithoutCreating,
  OrderNotificationTopicKey,
  OrderNotificationType,
} from "models";
import { t } from "utils/i18n";

export const SMS_MAX_CHARACTERS_PER_TEXT = 156;

export const MAX_CHARACTERS_5000 = 5000;

export const MAX_CHARACTERS_500 = 500;

export const MAX_CHARACTERS_255 = 255;

export const ORDER_NOTIFICATION_STATUS: Record<
  OrderNotificationStatusWithoutCreating,
  {
    color: string;
    name: string;
  }
> = {
  [OrderNotificationStatus.ACTIVE]: {
    name: t("Active"),
    color: GREEN.main,
  },
  [OrderNotificationStatus.DRAFT]: {
    name: t("Draft"),
    color: GRAY.main,
  },
  [OrderNotificationStatus.INACTIVE]: {
    name: t("Inactive"),
    color: RED.dark,
  },
  [OrderNotificationStatus.DELETED]: {
    name: t("Deleted"),
    color: GRAY.main,
  },
};

export const ORDER_NOTIFICATION_TYPE_OPTIONS = [
  {
    label: OrderNotificationType.SMS,
    value: OrderNotificationType.SMS,
  },
  {
    label: OrderNotificationType.OTT,
    value: OrderNotificationType.OTT,
  },
  {
    label: OrderNotificationType.EMAIL,
    value: OrderNotificationType.EMAIL,
  },
];

export const ALL_SELLERS_OPTION_VALUE = 0;

export const ALL_TERMINALS_OPTION_VALUE = 0;

export const RECEIVER_CUSTOMER_VALUE = "CUSTOMER";

export const RECEIVER_CONSULTANT_VALUE = "CONSULTANT";

export const RECEIVER_CUSTOM_VALUE = "CUSTOM_RECEIVER";

export const RECEIVERS_OPTIONS = [
  {
    label: t("Customer"),
    value: RECEIVER_CUSTOMER_VALUE,
  },
  {
    label: `${t("Consultant")}/${t("Dealer")}`,
    value: RECEIVER_CONSULTANT_VALUE,
  },
];

export const ORDER_SELLER_OPTIONS = [
  {
    label: t("AllSellers"),
    value: ALL_SELLERS_OPTION_VALUE,
  },
];

export const ORDER_TERMINALS_OPTIONS = [
  {
    label: t("AllTerminals"),
    value: ALL_TERMINALS_OPTION_VALUE,
  },
];

export const NOTIFICATIONS_ACTIONS_BY_STATUS: Record<string, IAction[]> = {
  [OrderNotificationStatus.ACTIVE]: ["clone", "activate"],
  [OrderNotificationStatus.INACTIVE]: ["clone"],
  [OrderNotificationStatus.DRAFT]: ["delete", "clone", "cancel", "save", "activate"],
  [OrderNotificationStatus.CREATING]: ["cancel", "save", "activate"],
  [OrderNotificationStatus.DELETED]: [],
};

export const TOPIC_TITLE: { [key in OrderNotificationTopicKey]: string } = {
  [OrderNotificationTopicKey.ORDER_IS_CREATED_NOT_FULLY_PAID]: "Đơn được đặt thành công, chưa thanh toán",
  [OrderNotificationTopicKey.ORDER_IS_FULLY_PAID]: "Đơn được đặt thành công, đã thanh toán",
  [OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID]: "Đơn được khách đặt thành công, đã thanh toán",
  [OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID]:
    "Đơn được đặt hộ thành công, đã thanh toán",
  [OrderNotificationTopicKey.ORDER_IS_WAITING_FOR_DEALER_APPROVAL]: "Đơn chờ duyệt",
  [OrderNotificationTopicKey.ORDER_IS_CANCELLED]: "Đơn bị hủy",
  [OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF]: "Yêu cầu huỷ/trả gói hàng thành công",
  [OrderNotificationTopicKey.SHIPMENT_IS_DELIVERING]: "Gói hàng đang giao",
  [OrderNotificationTopicKey.SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP]: "Gói hàng đã giao đến đại lý",
  [OrderNotificationTopicKey.SHIPMENT_IS_DELIVERED]: "Gói hàng đã giao",
  [OrderNotificationTopicKey.SHIPMENT_NEED_ACTION]: "Gói hàng cần xử lý",
  [OrderNotificationTopicKey.ORDER_IS_DELIVERED]: "Đơn hàng đã giao",
};

interface OrderNotificationTopicTemplateMap {
  [key: /*`orderNotificationTopicKey-receiver`*/ string]: {
    name: string;
    topicKey: string;
    template: {
      [notificationType: /*  OTT | SMS | EMAIL*/ string]: {
        receivers: string;
        sellerIds: number[];
        terminalIds: number[];
        title?: string;
        content: string;
      };
    };
  };
}

// *TopicKey have to match with key from API get notification topics to be able to get topicId
// *Template key syntax: `orderNotificationTopicKey-receiver`
// *Receivers in this constant is in type string (instead of string[]) in order to match form's structure
export const ORDER_NOTIFICATION_TOPIC_TEMPLATE: OrderNotificationTopicTemplateMap = {
  [`${OrderNotificationTopicKey.ORDER_IS_CREATED_NOT_FULLY_PAID}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_CREATED_NOT_FULLY_PAID]} - Gửi khách đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_CREATED_NOT_FULLY_PAID,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Bấm để xem chi tiết đơn hàng.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} da duoc dat thanh cong. Vui long vao Chi tiet don hang de xem thong tin don.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Vui lòng vào Chi tiết đơn hàng để xem thông tin đơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_FULLY_PAID}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_FULLY_PAID]} - Gửi khách đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_FULLY_PAID,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Bấm để xem chi tiết đơn hàng.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} da duoc dat thanh cong. Vui long vao Chi tiet don hang de xem thong tin don.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Vui lòng vào Chi tiết đơn hàng để xem thông tin đơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_FULLY_PAID}-${RECEIVER_CONSULTANT_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_FULLY_PAID]} - Gửi Đại lý/ Người tư vấn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_FULLY_PAID,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} vừa được đặt thành công. Vui lòng chuẩn bị hàng cho khách.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} vua duoc dat thanh cong. Vui long chuan bi hang cho khach.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} vừa được đặt thành công. Vui lòng chuẩn bị hàng cho khách.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID]} - Gửi khách đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CUSTOMER_FULLY_PAID,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Bấm để xem chi tiết đơn hàng.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} da duoc dat thanh cong. Vui long vao Chi tiet don hang de xem thong tin don.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được đặt thành công. Vui lòng vào Chi tiết đơn hàng để xem thông tin đơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${
      TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID]
    } - Gửi khách đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_CREATED_BY_CONSULTANT_OR_DEALER_FULLY_PAID,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content:
          "Đơn hàng {{.order_id}} vừa được đại lý {{.consultant_name}} đặt hộ thành công.\n" +
          "Bấm để xem chi tiết đơn hàng.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content:
          "Don hang {{.order_id}} vua duoc dai ly {{.consultant_name}} dat ho thanh cong.\n" +
          "Vui long vao Chi tiet don hang de xem thong tin don.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đặt hàng thành công",
        content:
          "Đơn hàng {{.order_id}} vừa được đại lý {{.consultant_name}} đặt hộ thành công.\n" +
          "Vui lòng vào Chi tiết đơn hàng để xem thông tin đơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_WAITING_FOR_DEALER_APPROVAL}-${RECEIVER_CONSULTANT_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_WAITING_FOR_DEALER_APPROVAL]} - Gửi Đại lý/ Người tư vấn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_WAITING_FOR_DEALER_APPROVAL,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng chờ duyệt",
        content:
          "Bạn có đơn hàng {{.order_id}} từ khách hàng {{.customer_name}} cần duyệt. Đơn hàng sẽ tự động duyệt sau 30 phút.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content:
          "Ban co don hang {{.order_id}} tu khach hang {{.customer_name}} can duyet. Don hang se tu dong duyet sau 30 phut.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng chờ duyệt",
        content:
          "Bạn có đơn hàng {{.order_id}} từ khách hàng {{.customer_name}} cần duyệt. Đơn hàng sẽ tự động duyệt sau 30 phút.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF]} - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Yêu cầu hủy thành công",
        content: "Đơn hàng {{.shipment_id}} đã được hủy thành công.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.shipment_id}} da duoc huy thanh cong.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Yêu cầu hủy thành công",
        content: "Đơn hàng {{.shipment_id}} đã được hủy thành công.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF}-${RECEIVER_CONSULTANT_VALUE}`]: {
    name: `${
      TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF]
    } - Gửi Đại lý/ Người tư vấn`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_IS_RETURNED_BY_CREATOR_OR_STAFF,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Yêu cầu hủy thành công",
        content: "Đơn hàng {{.shipment_id}} đã được hủy thành công.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.shipment_id}} da duoc huy thanh cong.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Yêu cầu hủy thành công",
        content: "Đơn hàng {{.shipment_id}} đã được hủy thành công.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_IS_DELIVERING}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_IS_DELIVERING]} - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_IS_DELIVERING,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Gói hàng đang vận chuyển",
        content: "Gói hàng {{.shipment_id}} đang trên đường giao đến bạn. Bấm để kiểm tra đơn hàng của bạn.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content:
          "Goi hang {{.shipment_id}} dang tren duong giao den ban. Vui long vao Chi tiet don hang de xem thong tin don.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Gói hàng đang vận chuyển",
        content:
          "Gói hàng {{.shipment_id}} đang trên đường giao đến bạn. Vui lòng vào Chi tiết đơn hàng để xem thông tin đơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${
      TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP]
    } - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_IS_READY_FOR_CUSTOMER_TO_PICK_UP,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng của bạn đã được giao đến đại lý",
        content:
          "Đơn hàng {{.shipment_id}} của bạn đã được giao đến đại lý {{.consultant_name}}. Mời bạn đến đại lý để nhận đơn hàng.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content:
          "Don hang {{.shipment_id}} cua ban da duoc giao den dai ly {{.consultant_name}}. Moi ban den dai ly de nhan don hang.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng của bạn đã được giao đến đại lý",
        content:
          "Đơn hàng {{.shipment_id}} của bạn đã được giao đến đại lý {{.consultant_name}}. Mời bạn đến đại lý để nhận đơn hàng.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_IS_DELIVERED}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_IS_DELIVERED]} - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_IS_DELIVERED,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Giao hàng thành công",
        content: "Gói hàng {{.shipment_id}} đã được giao tới bạn thành công.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Goi hang {{.shipment_id}} da duoc giao toi ban thanh cong.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Giao hàng thành công",
        content: "Gói hàng {{.shipment_id}} đã được giao tới bạn thành công.",
      },
    },
  },
  [`${OrderNotificationTopicKey.SHIPMENT_NEED_ACTION}-${RECEIVER_CONSULTANT_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.SHIPMENT_NEED_ACTION]}`,
    topicKey: OrderNotificationTopicKey.SHIPMENT_NEED_ACTION,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã được tạo",
        content: "Đơn hàng {{.shipment_id}} đã được tạo. Bấm để xem chi tiết.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.shipment_id}} da duoc tao. Bam de xem chi tiet.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã được tạo",
        content:
          "Dear anh/chị,\n" +
          "Đơn hàng {{.shipment_id}} đã được tạo thành công. Anh/Chị có thể vào chi tiết đơn hàng trên trang quản lý để xem thông tin.\n" +
          "Email này cùng các tệp tin đính kèm là các thông tin bảo mật của {{.platform_name}} và chỉ có mục đích được gửi cho những người nhận được nêu tại email. Đây là mail tự động, Anh/Chị vui lòng không trả lời lại mail này.\n" +
          "Xin cám ơn.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_CANCELLED}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_CANCELLED]} - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_CANCELLED,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã bị huỷ",
        content:
          "Đơn hàng {{.order_id}} đã được hủy. Đối với đơn hàng trả trước, khách hàng sẽ được hoàn tiền trong 15 - 20 ngày làm việc.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content:
          "Don hang {{.order_id}} da duoc huy. Doi voi don hang tra truoc, khach hang se duoc hoan tien trong 15 - 20 ngay lam viec.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã bị huỷ",
        content:
          "Đơn hàng {{.order_id}} đã được hủy. Đối với đơn hàng trả trước, khách hàng sẽ được hoàn tiền trong 15 - 20 ngày làm việc.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_CANCELLED}-${RECEIVER_CONSULTANT_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_CANCELLED]} - Gửi Đại lý/ Người tư vấn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_CANCELLED,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã bị huỷ",
        content: "Đơn hàng {{.order_id}} đã bị từ chối duyệt.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} da bi tu choi duyet.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CONSULTANT_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Đơn hàng đã bị huỷ",
        content: "Đơn hàng {{.order_id}} đã bị từ chối duyệt.",
      },
    },
  },
  [`${OrderNotificationTopicKey.ORDER_IS_DELIVERED}-${RECEIVER_CUSTOMER_VALUE}`]: {
    name: `${TOPIC_TITLE[OrderNotificationTopicKey.ORDER_IS_DELIVERED]} - Gửi khách hàng đặt đơn`,
    topicKey: OrderNotificationTopicKey.ORDER_IS_DELIVERED,
    template: {
      [OrderNotificationType.OTT]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Giao hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được giao tới bạn thành công.",
      },
      [OrderNotificationType.SMS]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        content: "Don hang {{.order_id}} da duoc giao toi ban thanh cong.",
      },
      [OrderNotificationType.EMAIL]: {
        receivers: RECEIVER_CUSTOMER_VALUE,
        sellerIds: [ALL_SELLERS_OPTION_VALUE],
        terminalIds: [ALL_TERMINALS_OPTION_VALUE],
        title: "Giao hàng thành công",
        content: "Đơn hàng {{.order_id}} đã được giao tới bạn thành công.",
      },
    },
  },
};
