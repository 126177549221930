import { Result } from "antd";
import { BackToHomeButton, LogoutButton } from "components/Button";
import React from "react";
import { t } from "utils/i18n";

const Page404: React.FC = () => {
  return (
    <Result
      className="app-result-page"
      status="404"
      title="404"
      subTitle={t("PageNotFound")}
      extra={
        <>
          <BackToHomeButton />
          <LogoutButton />
        </>
      }
    />
  );
};

export default Page404;
