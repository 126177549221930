import requestApis from "apis/request";
import { StaffOfTeam } from "models";

const { ticketBffClient } = requestApis;
const getStaffsOfTeamOrEmpty = async (teamId: number): Promise<StaffOfTeam[]> => {
  const params = {
    teamId,
  };
  const resp = await ticketBffClient.get("/api/staffs/getStaffOfTeam", { params });
  return resp.data?.data || [];
};

export default {
  getStaffsOfTeamOrEmpty,
};
