import requestApis from "apis/request";
import { DcaAccountGetListPayload, IDcaAccount } from "models";

const { dcaClient } = requestApis;

const getAccounts = async (
  params: DcaAccountGetListPayload
): Promise<{ accounts: IDcaAccount[]; limit: number; offset: number; totalRecords: number }> => {
  const res = await dcaClient.get("/accounts", { params });
  return res.data.result;
};

export default {
  getAccounts,
};
