import { IUserDetailsInfo } from "teko-oauth2";
export const currentUser = {
  sub: "a160863da1644a119382cfc3e8685a7c",
  name: "Văn Hữu Linh",
  picture: "https://lh3.googleusercontent.com/a-/AAuE7mDQFfGHKAJ9y6ZNwtoCyvvXfe6CY9b97C8ndmsg",
  email: "linh.vh@teko.vn",
  phone_number: "0852161778",
  address: "",
  birthday: "",
  roles: ["catalog:admin", "staff-bff:3pl_bol", "staff-bff:cancel_all_orders", "staff-bff:cancel_owned_orders"],
  permissions: [
    "catalog:all:all",
    "ppm:all:all",
    "apl:credit_customer:create",
    "apl:credit_approval_authority:create",
    "apl:discount_approval_configs:read",
    "apl:discount_approval_configs:create",
    "apl:discount_approval_configs:update",
    "apl:discount_approval_configs:delete",
    "staff-bff:clearance_request:update",
    "staff-bff:inventory:create",
    "staff-bff:inventory:update",
    "staff-bff:inventory:read",
    "staff-bff:inventory:read:site_and_warehouse",
    "staff-bff:inventory:create:site_and_warehouse",
    "staff-bff:inventory:update:site_and_warehouse",
    "staff-bff:order:read",
    "staff-bff:orders:update",
    "staff-bff:orders:update:clone",
    "staff-bff:return_requests:read",
    "staff-bff:return_requests:create",
    "staff-bff:return_requests:update",
    "staff-bff:loyalty_computation_configs:update",
    "staff-bff:delivery_request:create",
    "staff-bff:stock_request:read",
    "staff-bff:stock_request:create",
    "ticket-bff:issues:read",
    "ticket-bff:issues:read",
    "staff-bff:orders:read:einvoice",
    "staff-bff:orders:update:cancel_service_fee",
    "staff-bff:orders:update:customer",
    "staff-bff:orders:update:item:confirm_hold",
    "staff-bff:orders:update:item:change_warehouse",
    "staff-bff:orders:update:item:swap_hold",
    "staff-bff:orders:update:update_hold_priority",
    "staff-bff:orders:update:shipment:mark_packed",
    "staff-bff:orders:update:shipment:mark_delivered",
    "staff-bff:orders:update:shipment:mark_delivering",
    "staff-bff:export_history:read",
    "staff-bff:orders:read:order_header_report",
    "staff-bff:orders:read:order_detail_report",
    "staff-bff:orders:read:seller_report",
    "staff-bff:orders:read:seller_report",
    "staff-bff:audit-log:read:clearance_request",
    "ons-bff:order-notifications:read",
    "ons-bff:order-notifications:update",
    "ons-bff:order-notifications:update:activate",
    "staff-bff:return_requests:read:export",
    "staff-bff:pending_sales_orders:read",
    "staff-bff:pending_sales_orders:delete",
    "staff-bff:pending_sales_orders:delete:sku_request",
    "staff-bff:pending_sales_orders:create:import_to_cart",
    "catalog:products:create",
  ],
  meta_data: {
    platformId: 0,
    seller_id: "1,2",
    sso_id: 70024,
    addr_code: "01",
    department: "COD",
    role: "staff",
    title: "Developer",
    asia_id: null,
    asia_username: null,
    manager_id: 50415,
    manager_asia_id: null,
    date_created: "2017-04-18 07:52:21",
    date_modified: "2017-08-02 08:51:22",
    mch_transport_code: "1000357,1010117",
  },
  tenant_id: "1",
  updated_at: "2017-08-02 08:51:22",
};

export const mockEmptyUser: IUserDetailsInfo = {
  sub: "",
  name: "",
  picture: "",
  email: "",
  phone_number: "",
  address: "",
  birthday: "",
  roles: [],
  permissions: [],
  meta_data: {},
  tenant_id: "",
  updated_at: "",
};

const isLoggedIn = () => true;
const login = () => {};
const logout = () => {};
const getAccessToken = () => "1";
const getUserInfo = () => currentUser;
const getFullUserInfo = () => Promise.resolve(currentUser);
const denyAccess = () => {};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
