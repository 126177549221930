import { ReturnData } from "models";
import { createContext } from "react";

export type ReturnRequestsContextType = {
  loading: boolean;
  allowedToRead: boolean;
  returnData: ReturnData;
  getOrderReturnRequests: () => Promise<boolean>;
  initData: (callbackFn?: () => void) => Promise<void>;
};

export const initialReturnData: ReturnData = {
  returnRequests: [],
  processingRequests: [],
  finishedRequests: [],
  returnedProducts: [],
  returnedServices: [],
};

export const initialReturnRequestContext: ReturnRequestsContextType = {
  loading: false,
  allowedToRead: false,
  returnData: initialReturnData,
  getOrderReturnRequests: () => Promise.resolve(false),
  initData: () => Promise.resolve(),
};

export const ReturnRequestContext = createContext<ReturnRequestsContextType>(initialReturnRequestContext);
