import { Result, Spin } from "antd";
import { sellerAndPlatformApis } from "apis";
import { StoreContext } from "contexts";
import { ordersHooks } from "hooks";
import { IBffSeller, IPlatform } from "models";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { sellerUtils } from "utils";

const { getAuthorizedSellerIds, hasAllSellers } = sellerUtils;

export interface WithAuthorizedPlatformsOrSellers {
  authorizedPlatforms?: IPlatform[];
  authorizedSellers?: IBffSeller[];
}

export const withAuthorizedPlatformsOrSellers = <P extends {}>(
  Component: React.FC<P>,
  targetTitle: string
): React.FC<P & WithAuthorizedPlatformsOrSellers> => {
  return (props) => {
    const {
      authorizedPlatforms,
      userPlatformId,
      isFetching: isFetchingPlatforms,
    } = ordersHooks.useAuthorizedPlatforms();
    const { currentUser } = useContext(StoreContext);
    const [authorizedSellers, setAuthorizedSellers] = useState<IBffSeller[]>([]);
    const [isFetchingSellers, setIsFetchingSellers] = useState<boolean>(false);
    const ignoreParentApp = true;
    const authorizedSellerIds = useRef(getAuthorizedSellerIds(currentUser, ignoreParentApp));
    const userIdentity = currentUser.email || currentUser.phone_number || currentUser.name;

    useEffect(() => {
      if (Number.isNaN(userPlatformId)) {
        setIsFetchingSellers(true);
        sellerAndPlatformApis
          .getSellers()
          .then((fullSellers) => {
            if (hasAllSellers(currentUser)) {
              setAuthorizedSellers(fullSellers);
            } else {
              const result = fullSellers.filter((seller) => authorizedSellerIds.current.includes(seller.id));
              setAuthorizedSellers(result);
            }
          })
          .finally(() => {
            setIsFetchingSellers(false);
          });
      }
    }, [userPlatformId, currentUser, authorizedSellerIds]);

    if (isFetchingPlatforms || isFetchingSellers) return <Spin spinning={isFetchingPlatforms || isFetchingSellers} />;

    if (Number.isInteger(userPlatformId) && authorizedPlatforms?.length === 0) {
      return (
        <Result
          data-testid="invalid-user-platform-error"
          status="403"
          subTitle={
            <Trans i18nKey="InvalidUserPlatformMsg">
              Tài khoản <span className="text-primary text-medium"> {{ userIdentity }} </span> chưa được gán Platform
              hợp lệ để xem {{ targetTitle: targetTitle.toLowerCase() }}.
              <br /> Vui lòng liên hệ Admin <a href="mailto:support@teko.vn">support@teko.vn</a>
              để được hỗ trợ.
            </Trans>
          }
        />
      );
    }

    if (Number.isNaN(userPlatformId) && authorizedSellers.length === 0) {
      return (
        <Result
          data-testid="invalid-user-seller-error"
          status="403"
          subTitle={
            <Trans i18nKey="InvalidUserSellerMsg">
              Tài khoản <span className="text-primary text-medium"> {{ userIdentity }} </span> chưa được gán Seller hợp
              lệ để xem {{ targetTitle: targetTitle.toLowerCase() }}.
              <br /> Vui lòng liên hệ Admin <a href="mailto:support@teko.vn">support@teko.vn</a>
              để được hỗ trợ.
            </Trans>
          }
        />
      );
    }

    return <Component {...props} authorizedPlatforms={authorizedPlatforms} authorizedSellers={authorizedSellers} />;
  };
};
