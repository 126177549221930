import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  ConfirmationStatus,
  FulfillmentStatus,
  IMapInventoryBySku,
  OrderDetails,
  OrderDetailTabKey,
  OrderType,
  PaymentStatus,
  SelectedRouterItem,
  Service,
  WarehouseBiasedItem,
  WriteDataApi,
} from "models";
import React, { createContext } from "react";

export const initialOrderDetails: OrderDetails = {
  orderId: "",
  createdAt: "",
  customer: null,
  shippingInfo: null,
  grandTotal: 0,
  createdFromSiteDisplayName: "",
  confirmationStatus: "" as ConfirmationStatus,
  paymentStatus: "" as PaymentStatus,
  isHandover: false,
  fulfillmentStatus: "" as FulfillmentStatus,
  hasReturnedFullItems: false,
  terminalName: "",
  platformId: 0,
  replacedOrderId: "",
  replacedByOrderId: "",
  creator: null,
  consultant: null,
  payments: [],
  note: "",
  services: [],
  billingInfo: null,
  orderCaptureLineItems: [],
  shipments: [],
  cancellationReason: "",
  externalOrderRef: "",
  baseTotalAfterTaxAmount: 0,
  totalPromotionAfterTaxAmount: 0,
  totalPriceIncreaseAfterTaxAmount: 0,
  totalOnDemandDiscountAfterTaxAmount: 0,
  remainPaymentFloat64: 0,
  allowedUserActions: [],
  status: { displayName: "", displayColor: "", value: "" },
  lastWarehouseExportAt: null,
  isOmni252EnabledForOrderPlatform: false,
  isOmni1251EnabledForOrderPlatform: false,
};

export type OrderDetailsContextType = {
  order: OrderDetails;
  loading: boolean;
  isReadonly: boolean;
  typeOrder: OrderType;
  fetchData: () => void;
  activeTab: OrderDetailTabKey;
  setActiveTab: React.Dispatch<React.SetStateAction<OrderDetailTabKey>>;
  shouldRefresh: boolean;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  refreshWithGuarantee: (apiName: WriteDataApi, expectedValue: any) => Promise<void>;
  refreshOrderDetailsWithGuarantee: (
    isPostConditionSatisfied: (latestData: any) => boolean,
    maxWaitInSeconds: number
  ) => Promise<void>;
  isRefreshing: boolean;
};

export const initialOrderDetailsContext: OrderDetailsContextType = {
  order: initialOrderDetails,
  loading: false,
  isReadonly: false,
  typeOrder: OrderType.market,
  activeTab: OrderDetailTabKey.Order,
  fetchData: () => {},
  setActiveTab: () => {},
  shouldRefresh: false,
  setShouldRefresh: () => {},
  refreshWithGuarantee: () => Promise.resolve(),
  refreshOrderDetailsWithGuarantee: () => Promise.resolve(),
  isRefreshing: false,
};

export const OrderDetailsContext = createContext<OrderDetailsContextType>(initialOrderDetailsContext);

type WarehouseSelectContextType = {
  selectedWarehouseMap: Map<WarehouseBiasedItem["identifier"], WarehouseBiasedItem["warehouse"]>;
  setSelectedWarehouseMap: React.Dispatch<
    React.SetStateAction<Map<WarehouseBiasedItem["identifier"], WarehouseBiasedItem["warehouse"]>>
  >;
  selectedSiteMap: Map<WarehouseBiasedItem["identifier"], IMapInventoryBySku>;
  setSelectedSiteMap: React.Dispatch<React.SetStateAction<Map<WarehouseBiasedItem["identifier"], IMapInventoryBySku>>>;
};

export const WarehouseSelectContext = createContext<WarehouseSelectContextType>({
  selectedWarehouseMap: new Map(),
  setSelectedWarehouseMap: () => {},
  selectedSiteMap: new Map(),
  setSelectedSiteMap: () => {},
});

type SelectedItemsContextType<T> = {
  selectedItems: T[];
  onCheckboxChange: (event: CheckboxChangeEvent, item: T) => void;
  checkedMap?: { [key: string]: boolean };
};

export const SelectedRouterItemsContext = createContext<SelectedItemsContextType<SelectedRouterItem>>({
  selectedItems: [],
  onCheckboxChange: () => {},
});

export const SelectedServiceItemsContext = createContext<SelectedItemsContextType<Service>>({
  selectedItems: [],
  onCheckboxChange: () => {},
  checkedMap: {},
});
