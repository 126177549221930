import { FileProcessStatus, FileTemplateType, ImportFileProcessingType } from "models";
import { t } from "utils/i18n";

const FILE_TYPE = {
  INVENTORY: 1,
  SITE: 2,
  WAREHOUSE: 3,
};

const FILE_UPLOAD_STATUS = {
  NOT_PROCESS: {
    status: 0,
    color: "default",
    displayText: t("FileNotProcess"),
  },
  SUCCESS: {
    status: 1,
    color: "green",
    displayText: t("FileProcessSuccess"),
  },
  FAIL: {
    status: 2,
    color: "volcano",
    displayText: t("FileProcessFail"),
  },
  PROCESSING: {
    status: 3,
    color: "blue",
    displayText: t("FileInProcessing"),
  },
  PENDING: {
    status: 4,
    color: "default",
    displayText: t("FileProcessPending"),
  },
  DONE_WITH_ERROR: {
    status: 5,
    color: "volcano",
    displayText: t("FileProcessSuccessWithError"),
  },
};

export const FILE_PROCESS_STATUS_MAP: {
  [key in FileProcessStatus]: { color: string; text: string };
} = {
  [FileProcessStatus.init]: {
    color: "default",
    text: t("FileProcessStatus.Init"),
  },
  [FileProcessStatus.processing]: {
    color: "blue",
    text: t("FileProcessStatus.Processing"),
  },
  [FileProcessStatus.failed]: {
    color: "red",
    text: t("FileProcessStatus.Failed"),
  },
  [FileProcessStatus.finished]: {
    color: "green",
    text: t("FileProcessStatus.Success"),
  },
};

export const EXCEL_TYPES = [".xls", ".xlsx"];

export const FILE_MAX_SIZE_MB = 5;

export default { FILE_TYPE, FILE_UPLOAD_STATUS, FILE_PROCESS_STATUS_MAP };

export const ImportTemplateMap: {
  [key in ImportFileProcessingType]: FileTemplateType;
} = {
  [ImportFileProcessingType.IMPORT_ADJUSTMENT]: FileTemplateType.IMPORT_ADJUSTMENT,
  [ImportFileProcessingType.IMPORT_STOCK]: FileTemplateType.IMPORT_STOCK,
  [ImportFileProcessingType.IMPORT_BIN]: FileTemplateType.IMPORT_BIN,
  [ImportFileProcessingType.IMPORT_SITE_INFO]: FileTemplateType.IMPORT_SITE_INFO,
};

export const MAX_ROW_IN_FILE = 200;
