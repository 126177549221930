import { appConfig } from "app-config";
import { flagConstants, localStorageConstants } from "constants/index";
import { StoreContext } from "contexts";
import FlagsupClient, { EvaluatedFlags } from "flagsup-ts";
import { IRoute } from "models";
import { useContext, useEffect, useState } from "react";
import { IUserDetailsInfo } from "teko-oauth2";
import { flagUtils } from "utils";

const { serviceHost } = appConfig;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { FLAG_ECOM_489 } = localStorageConstants;

const useFilterNavigationByFeatureFlags = (items: IRoute[]) => {
  const { featureFlagsData } = useContext(StoreContext);

  // Get navigation which their feature flags was enable to build menu
  const filteredNavigation = flagUtils.filterFlagsEnabled(items, featureFlagsData);

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter((item) => !item.children && item.component);

  return { filteredNavigation, filteredRoutes };
};

const useFeatureFlags = (currentUser?: IUserDetailsInfo) => {
  const [featureFlagsData, setFeatureFlagsData] = useState<EvaluatedFlags>({});
  const [isInitiating, setIsInitiating] = useState(true);

  const flagsupClient = new FlagsupClient({
    targetHostname: serviceHost.flagsup,
    defaultEntityId: currentUser?.sub,
    useCacheRefresh: false,
  });

  const getFlags = async () => {
    try {
      setIsInitiating(true);
      const flags = await flagsupClient.batchEvaluateFlags(
        {
          ...FLAG_KEYS,
          ...EXTENDED_FLAG_KEYS,
        },
        currentUser
      );
      setFeatureFlagsData(flags);
      localStorage.setItem(FLAG_ECOM_489, flags[EXTENDED_FLAG_KEYS.EPIC_ECOM_489.key]?.enabled ? "true" : "false");
    } finally {
      setIsInitiating(false);
    }
  };

  useEffect(() => {
    if (currentUser?.sub) {
      getFlags();
    }
  }, [currentUser?.sub]);

  return { featureFlagsData, isInitiating, getFlags };
};

export default {
  useFeatureFlags,
  useFilterNavigationByFeatureFlags,
};
