import { ErrorInfoDomain, MessageByErrorInfo } from "models";

const receiveRequestErrorMessage =
  "There are something wrong when system receive your request. Please reload and try again, if the problem persists, please contact support@teko.vn";
const systemErrorMessage = "The system is experiencing problems. To get more information, please contact support team";
const fileGenerationErrorMessage = "There is an issue during file generation. Please contact support@teko.vn";

const apiErrorMessages: MessageByErrorInfo = {
  [ErrorInfoDomain.Inventory]: {
    INSUFFICIENT_STOCK: "Cannot hold for SKUs <1>{{ skus }}</1> because there is not enough stock",
  },
  [ErrorInfoDomain.COV]: {
    ORDER_NOT_FOUND:
      "The order is not found. Order ID could be not in correct format or it has not been synchronized completely in the system. If you're sure the ID is correct, please wait for a moment and reload the page",
    DATABASE_QUERY_FAILURE: systemErrorMessage,
  },
  [ErrorInfoDomain.StaffOMAdminBFF]: {
    DISALLOWED_PLATFORM_ID:
      "You cannot perform action on orders from another platform. Please contact support team to check the platform you are assigned to",
    MISSING_PLATFORM_CONFIG: "Your platform is not configured to use this feature, please contact support@teko.vn",
    READ_HTTP_REQUEST_FAILURE: receiveRequestErrorMessage,
    DECODE_REQUEST_FAILURE: receiveRequestErrorMessage,
    COV_SERVICE_FAILURE: systemErrorMessage,
    SELLER_SERVICE_FAILURE: systemErrorMessage,
    INIT_TEMPLATE_FAILURE: fileGenerationErrorMessage,
    INSERT_DATA_INTO_TEMPLATE_FAILURE: fileGenerationErrorMessage,
    FILE_GENERATION_FAILURE: fileGenerationErrorMessage,
    DISALLOWED_TERMINAL_ID: "You do not have valid terminals assigned to manage this order",
  },
  [ErrorInfoDomain.FFR]: {
    ALREADY_CREATED_SHIPMENT_FOR_ITEMS:
      "Some items have been processed to fulfill, can't create more shipments for them. Please reload the page and try again",
    EXCEEDED_ITEM_QUANTITY: "The quantity of items exceeded the amount allowed, please reload the page and try again",
  },
  [ErrorInfoDomain.WarehouseCentral]: {
    DEACTIVATE_SITE_FAILED_PRECONDITION:
      "Site cannot be deactivated as there are still inventory items and some transactions pending processing. Please recheck and try again later.",
  },
};

const apiMessages = {
  Common: {
    Success: "The request has been processed successfully",
    UnknownError: "Something went wrong, please try again later",
    InternalServerError: "Something went wrong, please try again later",
    PermissionDenied: "You don't have permission to access this page",
    BadRequest: "Invalid request",
    NotFound: "Data doesn't exist",
    AlreadyExisted: "Resource has already existed",
  },
  ErrorCode: {
    400001: "Cannot find return request",
    400002: "Cannot find siteId",
    400003: "Cannot find refund request",
    400004: "The date period is invalid",
    400005: "Cannot find return request",
    400006: "Either requestId or orderId is required",
    400007: "Notification receivers are empty",
    400008: "Cannot find refund request",
    400009: "Cannot get return reason",
    400010: "PlatformId is missing",
    400011: "Not allowed to increase estimated refund",
    400012: "Not allowed to decrease estimated refund",
    400013: "Exceeded time allowed to return",
    400014: "Exceeded quantity allowed to return",
    400015: "Exceeded quantity allowed to return",
    400016: "Service quantity is missing",
    400017: "Cannot find serviceId",
    400018: "Cannot find serviceId from billing info",
    400019: "Exceeded quantity allowed to return",
    400020: "Cannot find return request status",
    400021: "Cannot find order status to create return request",
    400022: "Exceeded quantity allowed to return",
    400023: "Cannot find return request status",
    500001: "Cannot find return request status",

    // ErrorCode of API PrintMultipleSaleOrders
    400901001: "System error: Invalid list of orders",
    400901002: "Orders contain invalid platform",
    500901001: "System error",
    500901002: "System error",
    500901003: "System error: Failed to get order details",
    500901004: "System error: Failed to get platform details",
    500901005: "System error: Failed to get seller details",
    500901006: "System error: Failed to get print template",
    500901007: "System error",
    500901008: "System error: Failed to insert data into template",
    500901009: "System error: Failed to generate PDF file",
    500901010: "System error: Failed to return PDF file",
  },
};

const validationMessages = {
  Required: "Field {{fieldName}} is required",
  Invalid: "Invalid",
  MaxLength: "Must be {{maxLength}} characters or less",
  MinLength: "Must be at least {{minLength}} characters",
  MaxValue: "Must be {{maxValue}} or less",
  MinValue: "Must be at least {{minValue}}",
  Email: "Email is invalid",
};

const helperMessages = {
  SearchPlaceholder: "Enter {{fieldName}} to search",
};

export default {
  ApiErrorMessages: apiErrorMessages,
  ApiMessages: apiMessages,
  ValidationMessages: validationMessages,
  HelperMessages: helperMessages,
};
