import { ConfigProvider } from "antd";
import { localizationConstants } from "constants/index";
import { SellerProvider } from "contexts";
import i18n from "i18n";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import localizationUtils from "utils/localization";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationUtils;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ConfigProvider
      locale={{ ...REGIONS[getCurrentLanguage()].antdLocale, ...REGIONS[getCurrentLanguage()].datePickerLocale }}
    >
      <SellerProvider>
        <App />
      </SellerProvider>
    </ConfigProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
