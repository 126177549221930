// DatePicker locale files
import enDatePicker from "antd/lib/date-picker/locale/en_US";
import viDatePicker from "antd/lib/date-picker/locale/vi_VN";
// Antd locale files
import enUS from "antd/lib/locale/en_US";
import viVN from "antd/lib/locale/vi_VN";
// Flags
import enFlag from "assets/images/flags/en.svg";
import viFlag from "assets/images/flags/vi.svg";
// Dayjs locale
import "dayjs/locale/en";
import "dayjs/locale/vi";
// Translation files and User messages
import enTrans from "locales/en/translation.json";
import enUserMessages from "locales/en/userMessages";
import viTrans from "locales/vi/translation.json";
import viUserMessages from "locales/vi/userMessages";
import { IRegion } from "models";

viVN.Table = {
  ...viVN.Table,
  triggerAsc: "Bấm để xếp tăng dần",
  triggerDesc: "Bấm để xếp giảm dần",
  cancelSort: "Bỏ sắp xếp",
};

const RESOURCES = {
  vi: { translation: { ...viTrans, ...viUserMessages } },
  en: { translation: { ...enTrans, ...enUserMessages } },
};

const REGIONS: IRegion = {
  vi: {
    key: "vi",
    name: "Tiếng Việt",
    flag: viFlag,
    antdLocale: viVN,
    datePickerLocale: viDatePicker,
  },
  en: {
    key: "en",
    name: "English",
    flag: enFlag,
    antdLocale: enUS,
    datePickerLocale: enDatePicker,
  },
};

export default {
  RESOURCES,
  REGIONS,
};
