import { RefundMethod, RefundRequestStatus } from "models";
import { t } from "utils/i18n";
import { BLUE, GRAY, GREEN, RED } from "./color";

export const REFUND_METHODS = [
  {
    value: RefundMethod.VNPayGateway,
    label: t("RefundMethods.VnPayGateway"),
  },
  {
    value: RefundMethod.VNPayQrcode,
    label: t("RefundMethods.VnPayQR"),
  },
  {
    value: RefundMethod.MobileBanking,
    label: t("RefundMethods.MobileBanking"),
  },
  {
    value: RefundMethod.BankTransfer,
    label: t("RefundMethods.BankTransfer"),
  },
  {
    value: RefundMethod.Cash,
    label: t("RefundMethods.Cash"),
  },
  {
    value: RefundMethod.Pos,
    label: t("RefundMethods.POS"),
  },
  {
    value: RefundMethod.VNPaySposCard,
    label: t("RefundMethods.VnPaySPOSCard"),
  },
];

export const REFUND_REQUEST_STATUSES: { code: RefundRequestStatus; label: string; color: string }[] = [
  {
    code: RefundRequestStatus.init,
    label: t("RefundStatus.New"),
    color: RED["light"],
  },
  {
    code: RefundRequestStatus.waitPartner,
    label: t("RefundStatus.WaitingForPartner"),
    color: BLUE["light"],
  },
  {
    code: RefundRequestStatus.overdue,
    label: t("RefundStatus.Overdue"),
    color: GRAY["main"],
  },
  {
    code: RefundRequestStatus.done,
    label: t("Done"),
    color: GREEN["main"],
  },
];

const MIN_REFUND_AMOUNT = 1;
const ZERO_REFUND_AMOUNT = 0;
const REFUND_NOTE_LIMIT = 50;

export default {
  REFUND_METHODS,
  REFUND_REQUEST_STATUSES,
  MIN_REFUND_AMOUNT,
  ZERO_REFUND_AMOUNT,
  REFUND_NOTE_LIMIT,
};
