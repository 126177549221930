import { commonConstants } from "constants/index";
import { IRoute } from "models";
import React from "react";
import { Route as BaseRoute, RouteProps } from "react-router-dom";

const { APP_TITLE } = commonConstants;

interface RouteExtendedProps extends RouteProps {
  name?: IRoute["name"];
}
const Route: React.FC<RouteExtendedProps> = ({ name, ...props }) => {
  if (typeof name === "string") document.title = name + " | " + APP_TITLE;
  return <BaseRoute {...props} />;
};

export default Route;
