import requestApis from "apis/request";
import { commonConstants } from "constants/index";
import {
  ICategoriesQueryParams,
  ICategory,
  IPlatform,
  ISeller,
  IShippingTypeListGetBFFResponse,
  IShippingTypeListGetPayload,
  IShippingTypeListGetResponse,
  ISkusQueryParams,
  ISkusResponse,
  ISkusResponseV1,
} from "models";

const { catalogClient, staffBffClient } = requestApis;
const { X_SELLER_ID, X_PLATFORM_ID } = commonConstants;

/** @deprecated by OMNI 1195*/
const getSkusV1 = async (params: ISkusQueryParams): Promise<ISkusResponseV1> => {
  const res = await catalogClient.get("skus", { params });
  return res.data?.result;
};

/** @deprecated by OMNI-1268 */
const getSkus = async (params: ISkusQueryParams): Promise<ISkusResponse> => {
  const res = await catalogClient.get("/api/v2/skus", { params });
  return res.data?.data;
};

const getSkusBFF = async (
  params: ISkusQueryParams,
  xSellerId?: ISeller["id"],
  xPlatformId?: IPlatform["id"]
): Promise<ISkusResponse> => {
  const res = await staffBffClient.get("/api/v1/skus", {
    params,
    headers: { [X_SELLER_ID]: xSellerId, [X_PLATFORM_ID]: xPlatformId },
  });
  return res.data?.data;
};

const getCategoriesOrEmpty = async (params: ICategoriesQueryParams): Promise<ICategory[]> => {
  try {
    const res = await staffBffClient.get(`/api/v1/inventory/${params.sellerId}/categories`, { params });
    return res.data?.categories;
  } catch {
    return [];
  }
};

/** @deprecated by OMNI-1268 */
const getShippingTypesOrEmpty = async (params: IShippingTypeListGetPayload): Promise<IShippingTypeListGetResponse> => {
  try {
    const res = await catalogClient.get("/shipping_types", { params });
    return res.data?.result || { shippingTypes: [], totalRecords: 0 };
  } catch {
    return { shippingTypes: [], totalRecords: 0 };
  }
};

const getShippingTypesBFF = async (
  params: IShippingTypeListGetPayload,
  xSellerId?: ISeller["id"]
): Promise<IShippingTypeListGetBFFResponse> => {
  const res = await staffBffClient.get("/api/v1/shipping-types");
  return res.data?.data;
};

export default {
  getSkusV1,
  getSkus,
  getSkusBFF,
  getCategoriesOrEmpty,
  getShippingTypesOrEmpty,
  getShippingTypesBFF,
};
