import { useEffect } from "react";
import { trackingUtils } from "utils";

const { trackUserClickEvent } = trackingUtils;

enum TrackingEventName {
  VisibleContent = "visibleContent",
  Click = "click",
}

const useTrackEventsFromParent = () => {
  useEffect(() => {
    const trackEventsFromParent = (event: MessageEvent) => {
      const { data, eventName } = event.data;
      if (eventName === TrackingEventName.VisibleContent) {
        track("manualTrackVisibleContentImpressions", data);
      }
      if (eventName === TrackingEventName.Click) {
        trackUserClickEvent(data);
      }
    };

    window.addEventListener("message", trackEventsFromParent);

    return () => {
      window.removeEventListener("message", trackEventsFromParent);
    };
  }, []);
};

export default { useTrackEventsFromParent };
