import { browserHistory } from "utils";
import StaffIdentity from "@staff-admin/identity-sdk";
import { appConfig } from "app-config";

const { isUseStaffAdminIDSDK } = appConfig;
const staffIdentity = StaffIdentity(isUseStaffAdminIDSDK);

const isLoggedIn = () => {
  return staffIdentity.isLoggedIn();
};

const login = () => {
  staffIdentity.login();
};

const logout = () => {
  // Tracking - Reset userId when logged out
  track("resetUserId");

  staffIdentity.logout(window.location.origin);
};

const getAccessToken = () => {
  return staffIdentity.getAccessToken();
};

const getUserInfo = () => {
  return staffIdentity.getUserInfo();
};

const getFullUserInfo = async () => {
  const fullUserInfo = await staffIdentity.getFullUserInfo();
  return fullUserInfo;
};

const denyAccess = () => {
  browserHistory.push("/403");
};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
