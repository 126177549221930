import { SellerAction, SellerState } from "./types";

export default (state: SellerState, action: SellerAction): SellerState => {
  switch (action.type) {
    case "UPDATE_SELLERS": {
      return { ...state, sellers: action.payload };
    }
    case "UPDATE_CURRENT_SELLER": {
      const newSeller = action.payload;
      if (newSeller === null || state?.sellers?.includes(newSeller)) {
        return { ...state, currentSeller: action.payload };
      } else {
        throw new Error(`User is not authorized to access seller ${newSeller.id} - ${newSeller.name}`);
      }
    }
    case "UPDATE_AUTHORIZED_SELLER_IDS": {
      return { ...state, authorizedSellerIds: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
