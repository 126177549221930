import requestApis from "apis/request";
import { commonConstants } from "constants/index";
import { IBffSeller, IBffTerminal, IGetUserTerminalsParams, IPlatform, ISeller, Terminal } from "models";

const { staffBffClient } = requestApis;
const { sellerClient } = requestApis;
const { DEFAULT_FILTERS } = commonConstants;

const getSellersOrEmpty = async (): Promise<Array<ISeller>> => {
  const ret = await sellerClient.get("/sellers/get-all");
  return (ret?.data?.result?.sellers || []) as Array<ISeller>;
};

const getTerminals = async (params: { sellerId: ISeller["id"] }): Promise<Terminal[]> => {
  const ret = await sellerClient.get(`terminals?pageSize=200&page=1&sellerID=${params.sellerId}`);
  return ret.data.result.terminals;
};

const getSellers = async (): Promise<IBffSeller[]> => {
  try {
    const ret = await staffBffClient.get("/api/v1/sellers");
    return ret.data.data;
  } catch {
    return [];
  }
};

const getSellersByPlatform = async (platformId: IPlatform["id"]): Promise<IBffSeller[]> => {
  try {
    const ret = await staffBffClient.get(`api/v1/platforms/${platformId}/sellers`);
    return ret.data.result.sellers;
  } catch {
    return [];
  }
};

const getTerminalsByPlatform = async (platformCode?: IPlatform["code"], sellerId?: number): Promise<IBffTerminal[]> => {
  try {
    const terminals: IBffTerminal[] = [];
    // Get all terminals by checking response list has length smaller than the page size
    let respLength = DEFAULT_FILTERS.TERMINAL.pageSize;
    // Limit the amount of call to 100 times
    for (let i = 1; i <= 100; i++) {
      const resp = await staffBffClient.get(`/api/v1/terminals`, {
        params: {
          page: i,
          pageSize: DEFAULT_FILTERS.TERMINAL.pageSize,
          sellingPlatformCodes: [platformCode],
          sellerId,
        },
      });
      respLength = resp.data?.result?.terminals?.length;
      terminals.push(...resp.data?.result?.terminals);
      if (!respLength || respLength < DEFAULT_FILTERS.TERMINAL.pageSize) {
        break;
      }
    }
    return terminals;
  } catch {
    return [];
  }
};

const getTerminalsBySeller = async (sellerId: ISeller["id"]): Promise<any[]> => {
  try {
    const ret = await staffBffClient.get(`/api/v1/sellers/${sellerId}/terminals`);
    return ret.data.data;
  } catch {
    return [];
  }
};

const getPlatformOptionsOrEmpty = async (
  params: { page: number; pageSize: number; ownerSellerId?: ISeller["id"] } = DEFAULT_FILTERS.PLATFORM
): Promise<IPlatform[]> => {
  try {
    const ret = await staffBffClient.get(`/api/v1/platforms`, {
      params,
    });
    const { platforms } = ret.data.result;
    return platforms || [];
  } catch {
    return [];
  }
};

const getPlatform = async (platformId: IPlatform["id"]): Promise<IPlatform | undefined> => {
  try {
    const ret = await staffBffClient.get(`/api/v1/platforms`, {
      params: { platformId },
    });
    const { platforms } = ret.data.result;
    return platforms && platforms[0];
  } catch {
    return undefined;
  }
};

const getSellingPlatforms = async (sellerId: ISeller["id"]): Promise<IPlatform[]> => {
  try {
    const res = await sellerClient.get(`/sellers/${sellerId}/platforms`);
    return res.data.result?.platforms || [];
  } catch {
    return [];
  }
};

const getAllPlatforms = async (): Promise<IPlatform[]> => {
  const MAX_LOOP_COUNT = 10;
  const MAX_PAGE_SIZE = 200;

  let currentPage = 1;
  let allPlatforms: IPlatform[] = [];

  let loopCount = 0; // hardcode to prevent loop too many times

  while (true) {
    if (loopCount >= MAX_LOOP_COUNT) {
      break;
    }

    const response = await sellerClient.get("/platforms", {
      params: {
        pageSize: MAX_PAGE_SIZE,
        page: currentPage,
      },
    });

    const result = response.data.result;

    allPlatforms = allPlatforms.concat(result.platforms);

    if (result.platforms.length < MAX_PAGE_SIZE) {
      break;
    }

    currentPage++;
    loopCount++;
  }

  return allPlatforms;
};

const getUserTerminals = async (params: IGetUserTerminalsParams): Promise<IBffTerminal[]> => {
  try {
    const res = await staffBffClient.get("/api/v1/terminals/user", { params });
    return res.data.terminals;
  } catch {
    return [];
  }
};

export default {
  getSellers,
  getSellersByPlatform,
  getTerminalsByPlatform,
  getTerminalsBySeller,
  getSellersOrEmpty,
  getTerminals,
  getPlatformOptionsOrEmpty,
  getPlatform,
  getSellingPlatforms,
  getAllPlatforms,
  getUserTerminals,
};
