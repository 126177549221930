import { IPlatform } from "models";
import { createContext } from "react";

type PlatformContextType = {
  platforms: IPlatform[];
};

export const PlatformContext = createContext<PlatformContextType>({
  platforms: [],
});
