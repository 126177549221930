import { CalculatorOutlined } from "@ant-design/icons";
import { appConstants, roleConstants } from "constants/permission";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_BFF } = appConstants;
const { CASHIER } = roleConstants;

// Pages
const RefundRequestList = lazy(() => import("pages/RefundRequestList"));

export default [
  // Parent menu
  {
    path: "/cashier",
    name: t("Cashier.Root"),
    icon: CalculatorOutlined,
    children: ["/cashier/refund-requests"],
  },
  // Sub menus
  {
    exact: true,
    path: "/cashier/refund-requests",
    name: t("Cashier.RefundRequests"),
    component: RefundRequestList,
    permissions: [permission(STAFF_BFF, CASHIER)],
  },
];
