import { LineChartOutlined } from "@ant-design/icons";
import { actionConstants, appConstants, resourceConstants } from "constants/permission";
import { withAssignedSingleSeller, withSinglePlatformId } from "hocs";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_FE, STAFF_BFF } = appConstants;
const { PAYMENT_RECONCILE_REPORT, STOCK_TRANSFER_OUT_REPORT, ORDERS } = resourceConstants;
const { READ, VIEW_SALES_REPORT, READ_REVENUE_REPORT, READ_DISCOUNT_APPROVED_REPORT } = actionConstants;

// Pages
const PaymentReconcileList = lazy(() => import("pages/PaymentReconcileList"));
const StockTransferOutList = lazy(() => import("pages/StockTransferOutList"));
const SalesOrderReportExport = lazy(() => import("pages/SalesOrderReportExport"));
const RevenueReportExport = lazy(() => import("pages/RevenueReportExport"));
const DiscountApprovedOrdersReport = lazy(() => import("pages/DiscountApprovedOrdersReport"));

export default [
  // Parent menu
  {
    path: "/reports",
    name: t("Reports.Root"),
    icon: LineChartOutlined,
    children: [
      "/reports/payment-reconcile",
      "/reports/stock-transfer-out",
      "/reports/sales-order",
      "/reports/revenue",
      "/reports/discount-approved-orders",
    ],
  },
  // Sub menus
  {
    exact: true,
    path: "/reports/payment-reconcile",
    name: t("Reports.PaymentReconcile"),
    component: PaymentReconcileList,
    permissions: [permission(STAFF_FE, PAYMENT_RECONCILE_REPORT, READ)],
  },
  {
    exact: true,
    path: "/reports/stock-transfer-out",
    name: t("Reports.StockTransferOut"),
    component: StockTransferOutList,
    permissions: [permission(STAFF_FE, STOCK_TRANSFER_OUT_REPORT, READ)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/reports/sales-order",
    name: t("Reports.SalesOrderReportBySaleman"),
    component: SalesOrderReportExport,
    permissions: [permission(STAFF_BFF, ORDERS, VIEW_SALES_REPORT)],
  },
  {
    exact: true,
    path: "/reports/revenue",
    name: t("Reports.Revenue"),
    component: withAssignedSingleSeller(withSinglePlatformId(RevenueReportExport, t("Reports.Revenue"))),
    permissions: [permission(STAFF_BFF, ORDERS, READ_REVENUE_REPORT)],
  },
  {
    exact: true,
    path: "/reports/discount-approved-orders",
    name: t("Reports.DiscountApprovedOrdersReport"),
    component: DiscountApprovedOrdersReport,
    permissions: [permission(STAFF_BFF, ORDERS, READ_DISCOUNT_APPROVED_REPORT)],
  },
];
