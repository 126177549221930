import { userApis } from "apis";
import { StoreContext } from "contexts";
import { IRoute } from "models";
import { useContext, useEffect, useState } from "react";
import { IUserDetailsInfo } from "teko-oauth2";
import userUtils from "utils/user";

const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useState<IUserDetailsInfo>();

  const getFullUserInfo = async () => {
    const fullUserInfo = await userApis.getFullUserInfo();
    setCurrentUser(fullUserInfo);
  };

  useEffect(() => {
    getFullUserInfo();
  }, []);

  return { currentUser };
};

const useAuthorizationData = (items: IRoute[]) => {
  const { currentUser } = useContext(StoreContext);

  // Get navigation which match permissions to build menu
  const filteredNavigation = userUtils.filterHasPermissions(items, [...currentUser.permissions, ...currentUser.roles]);

  // Only get routes which is link to a component
  const filteredRoutes = filteredNavigation.filter((item) => !item.children && item.component);

  return {
    filteredNavigation,
    filteredRoutes,
  };
};

export default {
  useUserInfo,
  useAuthorizationData,
};
