import { ErrorInfoDomain, ErrorInfoMetadata } from "models";
import React from "react";
import { Trans } from "react-i18next";

interface StyledErrorInfoMessageProps {
  metadata: ErrorInfoMetadata;
  translationKey: string;
}

const InsufficientStockErrorMessage: React.FC<StyledErrorInfoMessageProps> = ({ metadata, translationKey }) => {
  const { skus } = metadata;
  return (
    <Trans i18nKey={translationKey}>
      Cannot hold for SKUs <strong>{{ skus }}</strong> because there is not enough stock
    </Trans>
  );
};

const styledErrorMessageMap: {
  [domain: string]: { [reason: string]: React.FC<StyledErrorInfoMessageProps> | undefined };
} = {
  [ErrorInfoDomain.Inventory]: {
    INSUFFICIENT_STOCK: InsufficientStockErrorMessage,
  },
};

export { styledErrorMessageMap };
