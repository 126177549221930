import {
  IOrderItem,
  ISitesBySeller,
  IsLoadingWarehousesBySeller,
  MarketOrder,
  OrderDetailTabKey,
  OrderType,
  WarehousesBySeller,
} from "models";
import { createContext } from "react";

export type OrderContextType = {
  order: MarketOrder;
  loading: boolean;
  isReadonly: boolean;
  activeTab: OrderDetailTabKey;
  fetchData: () => void;
  setActiveTab: React.Dispatch<React.SetStateAction<OrderDetailTabKey>>;
  typeOrder?: OrderType;
};

export const initialMarketOrderDetail = {
  id: "",
  platformId: 0,
  code: "",
  createdAt: "",
  displayStatusColor: "",
  displayStatusName: "",
  status: "",
  grandTotal: 0,
  creator: { id: "", email: "", name: "", phone: "" },
  customer: { id: "", iamId: "", email: "", name: "", phone: "" },
  billingInfo: { taxCode: "", name: "", phone: "", email: "", address: "", showBuyerName: false },
  shippingInfo: { name: "", phone: "", fullAddress: "", expectedReceiveFrom: "", expectedReceiveTo: "" },
  items: [],
  routerItems: [],
  shipments: [],
  terminalCode: "",
  terminalName: "",
  note: "",
  totalDiscount: 0,
  payments: [],
  serviceFee: { delivery: [] },
  remainPaymentFloat64: 0,
  invoices: [],
  allowedUserActions: [],
  cancellationReason: "",
};

export const MarketOrderContext = createContext<OrderContextType>({
  order: initialMarketOrderDetail,
  loading: false,
  isReadonly: false,
  activeTab: OrderDetailTabKey.Order,
  fetchData: () => {},
  setActiveTab: () => "",
  typeOrder: OrderType.market,
});

type AllOrderItemsContextType = {
  selectedOrderItems: IOrderItem[];
  setSelectedOrderItems: React.Dispatch<React.SetStateAction<IOrderItem[]>>;
  shouldRefresh: boolean;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AllOrderItemsContext = createContext<AllOrderItemsContextType>({
  selectedOrderItems: [],
  setSelectedOrderItems: () => {},
  shouldRefresh: false,
  setShouldRefresh: () => {},
});

type OrderItemContextType = {
  orderItems: IOrderItem[];
  setOrderItems: React.Dispatch<React.SetStateAction<IOrderItem[]>>;
  selectedOrderItems: IOrderItem[];
  setSelectedOrderItems: React.Dispatch<React.SetStateAction<IOrderItem[]>>;
  isChecked: boolean[];
  setIsChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
  itemSeller?: IOrderItem["sellerName"];
};

export const OrderItemContext = createContext<OrderItemContextType>({
  orderItems: [],
  setOrderItems: () => {},
  selectedOrderItems: [],
  setSelectedOrderItems: () => {},
  isChecked: [],
  setIsChecked: () => {},
  itemSeller: "",
});

type WarehouseBySellerType = {
  warehousesBySeller: WarehousesBySeller;
  setWarehousesBySeller: React.Dispatch<React.SetStateAction<WarehousesBySeller>>;
  isLoadingWarehousesBySeller: IsLoadingWarehousesBySeller;
  setIsLoadingWarehousesBySeller: React.Dispatch<React.SetStateAction<IsLoadingWarehousesBySeller>>;
  sitesBySeller?: ISitesBySeller;
  isLoadingSitesBySeller?: IsLoadingWarehousesBySeller;
};

export const WarehouseBySellerContext = createContext<WarehouseBySellerType>({
  warehousesBySeller: {},
  setWarehousesBySeller: () => {},
  isLoadingWarehousesBySeller: {},
  setIsLoadingWarehousesBySeller: () => {},
});

type OrderCustomerModalType = {
  switchToCustomerEdit: () => void;
  switchToCustomerCreate: () => void;
  switchToCustomerSearch: () => void;
};

export const OrderCustomerModalContext = createContext<OrderCustomerModalType>({
  switchToCustomerEdit: () => {},
  switchToCustomerCreate: () => {},
  switchToCustomerSearch: () => {},
});
