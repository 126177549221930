import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { accountingApis, sellerAndPlatformApis } from "apis";
import { updateAuthorizedSellerIds, updateSellers, useSellerDispatch, useSellerState } from "contexts";
import { IBranch, IRoute } from "models";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IUserDetailsInfo } from "teko-oauth2";
import { browserHistory, commonUtils, sellerUtils } from "utils";

const { getWindowDimensions } = commonUtils;
const { getAccessibleSellers, getAuthorizedSellerIds } = sellerUtils;

const useAppMenu = (items: IRoute[]) => {
  const { location } = browserHistory;
  let selectedKey = location.pathname;
  const selectedKeySplitArr = location.pathname.split("/");
  let i = 1;
  let newSelectedKey = "";

  const getParentKey = (key: string): IRoute | undefined => {
    const newParentKey = items.find((item) => item.children && item.children.includes(key));
    if (newParentKey) return newParentKey;
    else if (i < selectedKeySplitArr.length) {
      newSelectedKey += `/${selectedKeySplitArr[i++]}`;
      selectedKey = newSelectedKey;
      return getParentKey(selectedKey);
    }
  };

  const parentKey = getParentKey(selectedKey);
  const openKey = parentKey ? parentKey.path : "/";

  return { selectedKey, openKey };
};

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = () => setDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return dimensions;
};

/** This hook inits the fetching of sellers data into SellerContext.
 * Use this hook on pages that need sellers data (e.g. for the <SellerSwitch />) */
const useSellerFetch = (currentUser: IUserDetailsInfo) => {
  const sellerDispatch = useSellerDispatch();
  const sellerState = useSellerState();
  useEffect(() => {
    const fetchSellerList = async () => {
      const authorizedSellerIds = await getAuthorizedSellerIds(currentUser);
      if (authorizedSellerIds.length && !sellerState.sellers) {
        updateAuthorizedSellerIds(sellerDispatch, authorizedSellerIds);
        const result = await sellerAndPlatformApis.getSellersOrEmpty();
        updateSellers(sellerDispatch, getAccessibleSellers(result, authorizedSellerIds));
      }
    };
    fetchSellerList();
  }, [currentUser, sellerDispatch, sellerState.sellers]);
};

const useIsMountedRef = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};

const useBranchData = () => {
  const { currentSeller } = useSellerState();
  const sellerId = currentSeller?.id;
  const [branches, setBranches] = useState<IBranch[]>([]);
  useEffect(() => {
    const fetchBranchesBySeller = async () => {
      const newBranches = await accountingApis.getBranches(sellerId);
      setBranches(newBranches);
    };

    fetchBranchesBySeller();
  }, [sellerId]);

  return { branches };
};

/**
 *
 * @returns {URLSearchParams}
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type ItemKey = string | number;

const useItemCheckbox = <T>(itemKey: ItemKey) => {
  type ItemMap = Map<string, { item: T; checked: boolean }>;

  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [checkedMap, setCheckedMap] = useState<{ [key: string]: boolean }>({});
  const itemMap: ItemMap = useMemo(() => new Map(), []);

  const onCheckboxChange = (event: CheckboxChangeEvent, item: T) => {
    const key = item[itemKey as keyof T];
    // insert|update new item into the map and its checked status
    if (!!key) {
      itemMap.set(String(key), { item: item, checked: event.target.checked });
      setCheckedMap((prev) => {
        const newCheckMap = { ...prev };
        newCheckMap[String(key)] = event.target.checked;
        return newCheckMap;
      });
    }
  };

  useEffect(() => {
    const getSelectedItems = (itemMap: ItemMap) => {
      const itemMapKeys = Array.from(itemMap.keys());
      const checkedItemKeys = itemMapKeys.filter((key) => !!itemMap.get(key)?.checked);
      return checkedItemKeys.map((key) => itemMap.get(key)!.item);
    };
    setSelectedItems(getSelectedItems(itemMap));

    // itemMap as dependency won't work since React can't detect changes from Map object => use the checkedMap as a bridge to enable change detection
  }, [checkedMap, itemMap]);

  const clearAllCheckboxes = () => {
    itemMap.clear();
    setSelectedItems([]);
    setCheckedMap({});
  };

  return {
    onCheckboxChange,
    clearAllCheckboxes,
    selectedItems,
    checkedMap,
  };
};

export default {
  useAppMenu,
  useWindowDimensions,
  useSellerFetch,
  useIsMountedRef,
  useBranchData,
  useQuery,
  useItemCheckbox,
};
