export interface ErrorDetails {
  typeUrl: string;
  value: ErrorInfo;
}

export interface ErrorInfo {
  domain: ErrorInfoDomain;
  reason: string;
  metadata: ErrorInfoMetadata;
}

export interface ErrorInfoMetadata {
  [key: string]: string;
}

export type MessageByErrorInfo = {
  [domain in ErrorInfoDomain]: {
    [reason: string]: string;
  };
};

export enum ErrorInfoDomain {
  Inventory = "warehouse3-inventory-service@warehouse-management",
  COV = "cov-service-server@orders-management",
  StaffOMAdminBFF = "staff-om-admin-service-api@bff",
  FFR = "fulfillment-router-service-server@orders-management",
  WarehouseCentral = "warehouse3-central-service@warehouse-management",
}
