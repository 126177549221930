const parseJsonObject = <T extends Record<any, any>>(
  jsonString: string | undefined | null
): T | Record<string, never> => {
  if (!jsonString) {
    return {};
  }

  try {
    const parsed = JSON.parse(jsonString);

    // Check if the parsed value is an object and not null (typeof null is "object")
    if (parsed && typeof parsed === "object" && !Array.isArray(parsed)) {
      return parsed as T;
    } else {
      return {};
    }
  } catch (e) {
    // If JSON.parse throws an error, the input was not valid JSON
    return {};
  }
};

export { parseJsonObject };
