import { Button, Result } from "antd";
import LinkByParentOrigin from "components/LinkByParentOrigin";
import { StoreContext } from "contexts";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { sellerUtils } from "utils";
import { t } from "utils/i18n";
import WithTracking from "./withTracking";
import { TrackingContentName, TrackingRegionName } from "constants/index";

export const withSellerOrderPermission = <P extends {}>(Component: React.FC<P>): React.FC<P> => {
  return (props) => {
    const { sellerId } = useParams<{ sellerId: string }>();
    const { currentUser } = useContext(StoreContext);

    const authorizedSellerIds = sellerUtils.getAuthorizedSellerIds(currentUser);

    if (!authorizedSellerIds.includes(0) && (sellerId === "0" || !authorizedSellerIds.includes(+sellerId))) {
      return (
        <Result
          data-testid="invalid-seller-order-error"
          status="403"
          subTitle={t("InvalidSellerOrderMsg")}
          extra={
            <Button type="primary">
              <LinkByParentOrigin to={"/orders/seller"}>
                <WithTracking
                  name={TrackingContentName.BackToOrderListLink}
                  regionName={TrackingRegionName.UnauthorizedSellerScreen}
                >
                  <span>{t("BackToOrderList")}</span>
                </WithTracking>
              </LinkByParentOrigin>
            </Button>
          }
        ></Result>
      );
    }

    return <Component {...props} />;
  };
};
