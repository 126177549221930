import { SettingOutlined } from "@ant-design/icons";
import { SETTING_PATHS } from "constants/index";
import { actionConstants, appConstants, resourceConstants } from "constants/permission";
import { withPlatformOwnerPermission, withPlatformPermission } from "hocs";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { APL, STAFF_BFF } = appConstants;
const {
  CREDIT_APPROVAL_AUTHORITY,
  DISCOUNT_APPROVAL_CONFIGS,
  RETURN_REASONS,
  LOYALTY_COMPUTATION_CONFIGS,
  APPROVAL_DEBT_CONFIG,
} = resourceConstants;
const { READ, CREATE, UPDATE } = actionConstants;

// Pages
const CreditApprovalAuthorityList = lazy(() => import("pages/CreditApprovalAuthorityList"));
const DiscountApprovalConfigs = lazy(() => import("pages/DiscountApprovalConfigs"));
const ReturnReasonList = lazy(() => import("pages/ReturnReasonList"));
const LoyaltyComputationConfig = lazy(() => import("pages/LoyaltyComputationConfig"));
const DebtApprovalConfigTable = lazy(() => import("pages/DebtApprovalConfig"));

export default [
  // Parent menu
  {
    path: SETTING_PATHS.ROOT,
    name: t("Setting.Root"),
    icon: SettingOutlined,
    children: [
      SETTING_PATHS.CREDIT_APPROVAL_AUTHORITY,
      SETTING_PATHS.DISCOUNT_APPROVAL,
      SETTING_PATHS.RETURN_REASONS,
      SETTING_PATHS.LOYALTY_PROGRAM,
      SETTING_PATHS.DEBT_APPROVAL_CONFIG,
    ],
  },
  // Sub menus
  {
    exact: true,
    path: SETTING_PATHS.CREDIT_APPROVAL_AUTHORITY,
    name: t("Setting.CreditApprovalAuthority"),
    component: CreditApprovalAuthorityList,
    permissions: [permission(APL, CREDIT_APPROVAL_AUTHORITY, READ), permission(APL, CREDIT_APPROVAL_AUTHORITY, CREATE)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: SETTING_PATHS.DISCOUNT_APPROVAL,
    name: t("Setting.DiscountApproval"),
    component: DiscountApprovalConfigs,
    permissions: [permission(APL, DISCOUNT_APPROVAL_CONFIGS, READ)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: SETTING_PATHS.RETURN_REASONS,
    name: t("Setting.CancellationReasons"),
    component: withPlatformPermission(ReturnReasonList, t("Setting.CancellationReasons")),
    permissions: [permission(STAFF_BFF, RETURN_REASONS, UPDATE)],
  },
  {
    exact: true,
    path: SETTING_PATHS.LOYALTY_PROGRAM,
    name: t("Setting.LoyaltyProgram"),
    isUseSellerSwitch: true,
    component: withPlatformOwnerPermission(LoyaltyComputationConfig),
    permissions: [permission(STAFF_BFF, LOYALTY_COMPUTATION_CONFIGS, READ)],
  },
  {
    exact: true,
    path: SETTING_PATHS.DEBT_APPROVAL_CONFIG,
    name: t("Setting.DebtApprovalConfig"),
    component: DebtApprovalConfigTable,
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, APPROVAL_DEBT_CONFIG, READ)],
  },
];
