import { Dropdown, Menu, Typography } from "antd";
import { StoreContext } from "contexts";
import React, { MouseEvent, useContext } from "react";
import { LinkProps, useHistory } from "react-router-dom";
import { iframeUtils } from "utils";
import { t } from "utils/i18n";

const { getDataFromIframe } = iframeUtils;

interface Props {
  to: LinkProps["to"];
  children: React.ReactNode;
  newTab?: boolean;
  parentTo?: LinkProps["to"];
  className?: string;
  callback?: () => void;
  "data-testid"?: string;
}
/**
 *
 * @param parentTo is using when parent app path not matched with child app path (iFramed one)
 */
const LinkByParentOrigin = ({
  to,
  parentTo,
  className,
  children,
  newTab = false,
  callback,
  "data-testid": dataTestId,
}: Props) => {
  const { inIframe } = useContext(StoreContext);
  const { parentOrigin } = getDataFromIframe();
  const history = useHistory();

  //TODO: support case typeof to === 'function'
  const pathName = typeof to === "string" ? to : typeof to === "object" ? to.pathname : "";
  const alternativePathName =
    typeof parentTo === "string" ? parentTo : typeof parentTo === "object" ? parentTo.pathname : "";
  const origin = inIframe && parentOrigin ? parentOrigin : "";
  const state = typeof to === "object" ? to.state : {};
  const href = parentTo && inIframe ? `${origin}${alternativePathName}` : `${origin}${pathName}`;

  const navigate = (event: MouseEvent) => {
    event.stopPropagation();
    callback && callback();

    //If user ctrl+click or newTab is true => open in new tab
    if (event.ctrlKey || newTab) {
      const tab = window.open(href, "_blank");
      tab?.focus();
    } else {
      history.push(pathName || "", state);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a
          onClick={!!callback ? callback : undefined}
          className={className}
          href={href}
          rel="noreferrer noopener"
          target="_blank"
        >
          {t("OpenLinkInNewTab")}
        </a>
      </Menu.Item>
    </Menu>
  );

  return !!newTab ? (
    <Typography.Link onClick={navigate} className={className} data-testid={dataTestId}>
      {children}
    </Typography.Link>
  ) : (
    <Dropdown overlay={menu} trigger={["contextMenu"]}>
      <Typography.Link onClick={navigate} className={className} data-testid={dataTestId}>
        {children}
      </Typography.Link>
    </Dropdown>
  );
};

export default LinkByParentOrigin;
