import requestApis from "apis/request";
import { StaffUserDetail, UserGetListPayload, UserGetListResponseItem } from "models";

const { iamClient } = requestApis;

const getUserListOrEmpty = async (params: UserGetListPayload): Promise<UserGetListResponseItem[]> => {
  const resp = await iamClient.get("/api/v1.0/users", { params });
  return resp.data?.items || [];
};

const getUserDetail = async (userId: string): Promise<StaffUserDetail> => {
  const resp = await iamClient.get(`/api/v1.0/users/${userId}`);
  return resp.data?.item;
};

export default {
  getUserListOrEmpty,
  getUserDetail,
};
