import dayjs, { Dayjs } from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

const TIME_FORMAT = "HH:mm:ss";
const TIME_FORMAT_LITE = "HH:mm";
const DATE_SLASH_FORMAT = "DD/MM/YYYY";
const DATE_TIME_SLASH_FORMAT = `${DATE_SLASH_FORMAT} ${TIME_FORMAT}`;
const DATE_TIME_SLASH_FORMAT_LITE = `${DATE_SLASH_FORMAT} ${TIME_FORMAT_LITE}`;
const DATE_DASH_FORMAT = "DD-MM-YYYY";
const YYYY_MM_DD_DASH_FORMAT = "YYYY-MM-DD";
const START_OF_DAY = dayjs().startOf("day");
const END_OF_DAY = dayjs().endOf("day");
const LAST_THIRTY_DAYS_RANGE: RangeValue<Dayjs> = [dayjs().subtract(29, "day").startOf("day"), dayjs().endOf("day")];

export default {
  TIME_FORMAT,
  DATE_SLASH_FORMAT,
  DATE_TIME_SLASH_FORMAT,
  DATE_TIME_SLASH_FORMAT_LITE,
  DATE_DASH_FORMAT,
  YYYY_MM_DD_DASH_FORMAT,
  START_OF_DAY,
  END_OF_DAY,
  LAST_THIRTY_DAYS_RANGE,
};
