import { t } from "utils/i18n";

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: t("PleaseEnter") + " ${label}",
};

const formValidationMessages = {
  required: t("FieldIsRequired", {
    // eslint-disable-next-line no-template-curly-in-string
    field: "${label}",
  }),
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutBalance = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const FORM_NAME = "create-form";

export default {
  validateMessages,
  formValidationMessages,
  formItemLayout,
  formItemLayoutBalance,
  FORM_NAME,
};
