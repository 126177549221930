import { ShoppingCartOutlined } from "@ant-design/icons";
import { actionConstants, appConstants, resourceConstants } from "constants/index";
import { withAssignedSingleSeller } from "hocs";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_BFF } = appConstants;
const { CLEARANCE_REQUEST, INVENTORY, AUDIT_LOG } = resourceConstants;
const { READ, READ_IMBALANCED_STOCKS, READ_CLEARANCE_REQUEST } = actionConstants;
// Pages
const InventoryAlert = lazy(() => import("pages/InventoryAlert"));
const InventoryTrackingByBatch = lazy(() => import("pages/InventoryTrackingByBatch"));
const StockImbalanceAlerts = lazy(() => import("pages/StockImbalanceAlerts"));
const InventoryAlertAuditLog = lazy(() => import("pages/InventoryAlertAuditLog"));

export default [
  // Parent menu
  {
    path: "/purchasing",
    name: t("Purchasing.Root"),
    icon: ShoppingCartOutlined,
    children: ["/purchasing/inventory-alert", "/purchasing/stock-imbalance-alerts"],
  },
  // Sub menus
  {
    exact: true,
    path: "/purchasing/inventory-alert",
    name: t("Purchasing.InventoryAlert"),
    component: withAssignedSingleSeller(InventoryAlert),
    permissions: [permission(STAFF_BFF, CLEARANCE_REQUEST, READ)],
  },
  {
    exact: true,
    path: "/purchasing/inventory-alert/:bucketId/:sku",
    name: t("ManualInventory.InventoryByBatch"),
    component: withAssignedSingleSeller(InventoryTrackingByBatch),
    permissions: [permission(STAFF_BFF, CLEARANCE_REQUEST, READ)],
  },
  {
    exact: true,
    path: "/purchasing/inventory-alert/action-history",
    name: t("ManualInventory.ActionHistory"),
    component: withAssignedSingleSeller(InventoryAlertAuditLog),
    permissions: [permission(STAFF_BFF, AUDIT_LOG, READ_CLEARANCE_REQUEST)],
  },
  {
    exact: true,
    path: "/purchasing/stock-imbalance-alerts",
    name: t("Purchasing.StockImbalanceAlerts"),
    component: StockImbalanceAlerts,
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, INVENTORY, READ_IMBALANCED_STOCKS)],
  },
];
