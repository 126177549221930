import { ReturnRequestServiceStatus, ReturnRequestStatus } from "models";
import { t } from "utils/i18n";
import { GRAY, RED, YELLOW } from "./color";

const RETURN_REQUEST_STATUS_OPTIONS = [
  {
    code: ReturnRequestStatus.cancelled,
    name: t("Cancelled"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestStatus.waitImportReturnItems,
    name: t("WaitImportReturnItems"),
  },
  {
    code: ReturnRequestStatus.waitApproval,
    name: t("WaitApproval"),
    color: YELLOW["main"],
  },
  {
    code: ReturnRequestStatus.waitApprovalImported,
    name: t("WaitApprovalImported"),
    color: YELLOW["main"],
  },
  {
    code: ReturnRequestStatus.waitApprovalCancelled,
    name: t("WaitApprovalCancelled"),
    color: YELLOW["main"],
  },
  {
    code: ReturnRequestStatus.partialCancelled,
    name: t("PartialCancelled"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestStatus.returned,
    name: t("Returned"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestStatus.denied,
    name: t("Denied"),
    color: RED["light"],
  },
  {
    code: ReturnRequestStatus.approved,
    name: t("Approved"),
  },
  {
    code: ReturnRequestStatus.rejected,
    name: t("Rejected"),
    color: RED["light"],
  },
  {
    code: ReturnRequestStatus.imported,
    name: t("Imported"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestStatus.closed,
    name: t("Closed"),
    color: GRAY["main"],
  },
];

const RETURN_REQUEST_SERVICE_STATUS_OPTIONS = [
  {
    code: ReturnRequestServiceStatus.approved,
    name: t("Approved"),
  },
  {
    code: ReturnRequestServiceStatus.cancelled,
    name: t("Cancelled"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestServiceStatus.closed,
    name: t("Closed"),
    color: GRAY["main"],
  },
  {
    code: ReturnRequestServiceStatus.denied,
    name: t("Denied"),
    color: RED["light"],
  },
  {
    code: ReturnRequestServiceStatus.waitApproval,
    name: t("WaitApproval"),
    color: YELLOW["main"],
  },
];

const CANEDIT_RETURN_REQUEST_STATUSES = [ReturnRequestStatus.waitApproval];

const CANREJECT_RETURN_REQUEST_STATUSES = [ReturnRequestStatus.waitApproval];

const CANCLOSE_RETURN_REQUEST_STATUSES = [
  ReturnRequestStatus.waitImportReturnItems,
  ReturnRequestStatus.waitApproval,
  ReturnRequestStatus.imported,
  ReturnRequestStatus.partialCancelled,
];

const CANPRINT_RETURN_REQUEST_STATUSES = [ReturnRequestStatus.returned];

const PROCESSING_RETURN_REQUEST_STATUSES = [
  ReturnRequestStatus.waitApproval,
  ReturnRequestStatus.waitApprovalCancelled,
  ReturnRequestStatus.waitApprovalImported,
  ReturnRequestStatus.approved,
  ReturnRequestStatus.imported,
  ReturnRequestStatus.waitImportReturnItems,
  ReturnRequestStatus.partialCancelled,
];

// items that have finished their return process and are recorded in the system as truly 'returned' will have one of these statuses
const DONE_RETURNED_ITEM_STATUSES = [
  ReturnRequestStatus.imported,
  ReturnRequestStatus.partialCancelled,
  ReturnRequestStatus.cancelled,
  ReturnRequestStatus.returned,
];

const APPROVAL_NEXT_STATUS: {
  [currentStatus: string]: ReturnRequestStatus;
} = {
  [ReturnRequestStatus.waitApproval]: ReturnRequestStatus.approved,
  [ReturnRequestStatus.waitApprovalImported]: ReturnRequestStatus.imported,
  [ReturnRequestStatus.waitApprovalCancelled]: ReturnRequestStatus.cancelled,
};

const SERVICE_QUANTITY = 1;

export default {
  RETURN_REQUEST_STATUS_OPTIONS,
  RETURN_REQUEST_SERVICE_STATUS_OPTIONS,
  CANEDIT_RETURN_REQUEST_STATUSES,
  CANREJECT_RETURN_REQUEST_STATUSES,
  CANCLOSE_RETURN_REQUEST_STATUSES,
  CANPRINT_RETURN_REQUEST_STATUSES,
  PROCESSING_RETURN_REQUEST_STATUSES,
  DONE_RETURNED_ITEM_STATUSES,
  APPROVAL_NEXT_STATUS,
  SERVICE_QUANTITY,
};
