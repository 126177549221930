import userApis from "apis/user";
import { commonConstants } from "constants/index";
import { IPermission, IRoute } from "models";
import iframeUtils from "utils/iframe";

const { inIframe, getDataFromIframe } = iframeUtils;
const { LOGOUT_MESSAGE } = commonConstants;

/**
 * Builds a permission object from 2 or 3 arguments
 * @param app
 * @param resourceOrRole
 * @param action optional
 */
const permission = (
  app: IPermission["app"],
  resourceOrRole: IPermission["resourceOrRole"],
  action?: IPermission["action"]
): IPermission => ({ app, resourceOrRole, action });

/**
 * Converts a permission object into a string, e.g.
 * app:resource:action, app:resource, app:role
 * @param p
 */
const getPermissionCode = (p: IPermission): string => {
  const { app, resourceOrRole, action } = p;
  return [app, resourceOrRole, action].filter((x) => !!x).join(":");
};

/**
 * Checks if a permission exists in the current user's permission list
 * @param permissionToCheck
 * @param currentPermissions
 */
const checkPermissionExists = (permissionToCheck: IPermission, currentPermissions: string[]): boolean => {
  const pCode = getPermissionCode(permissionToCheck);
  return !!(currentPermissions && currentPermissions.find((p) => p.startsWith(pCode)));
};

/**
 * Given user's permission list, determines if a leaf route is accessible.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param currentPermissions the current user's permission list
 */
const isLeafRouteVisible = (route: IRoute, currentPermissions: string[]): boolean => {
  const { permissions } = route;
  if (!permissions) return true;
  const intersection = permissions.find((x) => checkPermissionExists(x, currentPermissions));
  return !!intersection;
};

/**
 * Given user's permission list, returns which routes are accessible
 * @param items the routes to check
 * @param currentPermissions the current user's permission list
 */
const filterHasPermissions = (items: IRoute[], currentPermissions: string[]): IRoute[] => {
  return items.filter((item) => {
    const { permissions, children: childPaths } = item;
    if (childPaths && (!permissions || !permissions.length)) {
      const childRoutes = childPaths
        .map((path) => items.find((item) => item.path === path))
        .filter((x) => !!x) as IRoute[];
      // return true if one of the children is visible
      const visibleChild = childRoutes.find((route) => isLeafRouteVisible(route, currentPermissions));
      return !!visibleChild;
    }
    return isLeafRouteVisible(item, currentPermissions);
  });
};

const logout = () => {
  if (!!inIframe()) {
    const parentWindow = window.parent;
    const { parentOrigin = "" } = getDataFromIframe();
    parentWindow.postMessage(LOGOUT_MESSAGE, parentOrigin);
  } else {
    userApis.logout();
  }
};

export default {
  permission,
  getPermissionCode,
  filterHasPermissions,
  logout,
};
