import i18n from "i18n";

export const t = (text: string | string[], options?: object) => i18n.t(text, options);

export const isTranslationExist = (text: string | string[], options?: object) => i18n.exists(text, options);

export const tInputPlaceholder = (text: string, originFormat: boolean = false) =>
  t("EnterPlaceholder", {
    field: originFormat ? t(text) : t(text).toLowerCase(),
  });

export const tSelectPlaceholder = (text: string, originFormat: boolean = false) =>
  t("SelectPlaceholder", {
    field: originFormat ? t(text) : t(text).toLowerCase(),
  });

export const tFieldIsRequired = (text: string) =>
  t("FieldIsRequired", {
    field: t(text),
  });

export const tFieldInvalidFormat = (text: string) =>
  t("FieldInvalidFormat", {
    field: t(text),
  });

export const toTitleCase = (str: string) =>
  str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const tSelectMustBeLessThan = (field: string, lessThan: number) =>
  t("FieldMustBeLessThanXSelected", { field, x: lessThan });
