import requestApis from "apis/request";
import { appConfig } from "app-config";
import { commonConstants } from "constants/index";
import {
  CancelOrderPayload,
  CloneOrderPayload,
  CloneOrderResponse,
  CommentsAndAssignee,
  CountNeedToActionsGetParams,
  CreateExportRequestForDiscountApprovedOrdersPayload,
  CreateExportRequestForOrdersByPlatformPayload,
  CreateExportRequestForOrdersBySellerPayload,
  CustomerCreatePayload,
  CustomerGetListItem,
  CustomerGetListParams,
  GetTotalFilteredOrderPayload,
  ICommonFileProcessHistoryResponse,
  IExportListOrderForCustomerTeamPayload,
  INumberDisplayFormat,
  IOrderItemChangeSitePayload,
  IOrderItemsConfirmPayload,
  IOrderItemsConfirmResponse,
  IOrderStatusOption,
  IPaginationQueryParamsPartial,
  ISamsungWarrantyActivationPayload,
  IShipment,
  ManualUpdateShipmentStatusPayload,
  MarketOrder,
  NeedToActionsTotalCount,
  Order,
  OrderBillingInfoUpdatePayload,
  OrderConsultantUpdatePayload,
  OrderCustomerUpdatePayload,
  OrderGetListPayload,
  OrderGetListPayload_Pre1018,
  OrderItemChangeWarehousePayload,
  OrderItemConfirmPayload,
  OrderItemConfirmWarehousePayload,
  OrderItemSwapHoldPayload,
  OrderPriorityPayload,
  OrdersExportRequestPayload,
  PostOrderCommentPayload,
  RawOrderDetails,
  RouterItemChangeWarehousePayload,
  RouterItemConfirmPayload,
  RouterItemSwapHoldPayload,
  SellerOrder,
  SellerOrderGetListPayload,
  Shipment,
  UpdateSellerOrderStatusPayload,
  UpdateShipmentStatusResponse,
} from "models";
const { importShipmentStatusFPSClientId } = appConfig;
const { ONE_MIN_IN_MS } = commonConstants;
const { staffBffClient, staffBffNoErrorNotiClient, usersClient, fpsBffClient } = requestApis;

const updateSellerOrderStatus = async (
  payload: UpdateSellerOrderStatusPayload[]
): Promise<UpdateShipmentStatusResponse> => {
  return await staffBffClient.patch(`/api/v1/seller/orders/status`, {
    data: payload,
  });
};

const getMarketOrderDetail = async (orderId: string): Promise<MarketOrder> => {
  const ret = await staffBffClient.get(`api/v1/marketplace/orders/${orderId}`);
  return ret.data.data;
};

const getOrderDetailsForPlatform = async (
  orderId: string,
  params: INumberDisplayFormat,
  options?: { skipNotification: boolean }
): Promise<{ order: RawOrderDetails }> => {
  const bffClient = options?.skipNotification ? staffBffNoErrorNotiClient : staffBffClient;
  const res = await bffClient.get(`/api/v2/staff-admin/orders/${orderId}`, { params });
  return res.data.data;
};

const getOrderDetailsForSeller = async (
  orderId: string,
  params: INumberDisplayFormat
): Promise<{ sellerOrder: RawOrderDetails }> => {
  const res = await staffBffClient.get(`api/v2/staff-admin/seller-orders/${orderId}`, { params });
  return res.data.data;
};

const getMarketOrdersListPre1018 = async (
  params: OrderGetListPayload_Pre1018
): Promise<{ orders: MarketOrder[]; pagination: { page?: number; pageSize?: number; total: number } }> => {
  const ret = await staffBffClient.get(`/api/v1/marketplace/orders`, { params });
  const { data, total } = ret.data;
  const { offset, limit } = params;
  const pagination = {
    page: (offset || 0) / (limit || 1) + 1,
    pageSize: limit,
    total: total,
  };
  return { orders: data, pagination };
};

const getMarketOrdersList = async (params: OrderGetListPayload): Promise<{ orders: Order[]; nextOffset: number }> => {
  const ret = await staffBffClient.get(`/api/v2/staff-admin/orders`, { params });
  return ret.data.data;
};

const getTotalFilteredOrders = async (params: GetTotalFilteredOrderPayload): Promise<{ orderCount: number }> => {
  const ret = await staffBffClient.get(`/api/v2/staff-admin/count-orders`, { params });
  return ret.data.data;
};

const getSellerOrdersList = async (
  params: SellerOrderGetListPayload
): Promise<{ sellerOrders: SellerOrder[]; nextOffset: number }> => {
  const ret = await staffBffClient.get(`/api/v2/staff-admin/seller-orders`, { params });
  return ret.data.data;
};

const getSellerTotalFilteredOrders = async (params: SellerOrderGetListPayload): Promise<{ shipmentCount: number }> => {
  const ret = await staffBffClient.get(`/api/v2/staff-admin/count-seller-shipments`, { params });
  return ret.data.data;
};

const getSellerOrderDetail = async (orderId: MarketOrder["id"]): Promise<MarketOrder> => {
  const ret = await staffBffClient.get(`api/v2/seller/orders/${orderId}`);
  return ret.data.data;
};

const getShipmentStatusOptionsOrEmpty = async (): Promise<IOrderStatusOption[]> => {
  const ret = await staffBffClient.get("/api/v1/shipment-status");
  return ret.data.shipmentStatus || [];
};

const getOrderStatusOptionsOrEmpty = async (): Promise<IOrderStatusOption[]> => {
  const ret = await staffBffClient.get("/api/v1/order-status");
  return ret.data.orderStatus || [];
};
// pre OMNI-1018
const createOrdersExportRequestForStaff = async (
  payload: OrdersExportRequestPayload
): Promise<{ requestId: string }> => {
  const ret = await staffBffClient.post("/api/v1/orders/export/staff", payload);
  return ret.data?.data;
};
// pre OMNI-1018
const createOrdersExportRequestForSeller = async (
  payload: OrdersExportRequestPayload
): Promise<{ requestId: string }> => {
  const ret = await staffBffClient.post("/api/v1/orders/export/seller", payload);
  return ret.data?.data;
};

const createExportRequestForOrdersByPlatform = async (
  payload: CreateExportRequestForOrdersByPlatformPayload
): Promise<any> => {
  const ret = await staffBffClient.post("/api/v2/staff-admin/export-orders", payload);
  return ret;
};

const createExportRequestForOrdersBySeller = async (
  payload: CreateExportRequestForOrdersBySellerPayload
): Promise<any> => {
  const ret = await staffBffClient.post("/api/v2/staff-admin/export-seller-orders", payload);
  return ret;
};

type FFRShipmentResponse = {
  shipment_set_id: IShipment["id"];
};
const confirmHoldItems = async (
  payload: OrderItemConfirmPayload | RouterItemConfirmPayload
): Promise<{ shipmentIds: IShipment["id"][] } | null> => {
  const { data } = await staffBffClient.post(`/api/v1/orders/${payload.orderId}/pending-holds`, payload);
  if (data && data.shipments) {
    return {
      shipmentIds: data.shipments.map((s: FFRShipmentResponse) => s.shipment_set_id),
    };
  } else return null;
};

const changeWarehouseItems = async (
  payload: OrderItemChangeWarehousePayload | RouterItemChangeWarehousePayload
): Promise<any> => {
  return await staffBffClient.post(`/api/v1/orders/${payload.orderId}/items/change-warehouse`, payload);
};

const swapHoldItem = async (
  payload: OrderItemSwapHoldPayload | RouterItemSwapHoldPayload,
  orderId: MarketOrder["id"] | Order["orderId"]
): Promise<any> => {
  return await staffBffClient.post(`/api/v1/orders/${orderId}/swap-holds`, payload);
};

const confirmWarehouse = async (
  payload: OrderItemConfirmWarehousePayload,
  orderId: MarketOrder["id"]
): Promise<any> => {
  const ret = await staffBffClient.post(`/api/v1/orders/${orderId}/reassign-store`, payload);
  return ret;
};

const updateOrderPriority = async (payload: OrderPriorityPayload): Promise<any> => {
  const ret = await staffBffClient.post(`/api/v1/orders/${payload.orderId}/mark-priority`, payload);
  return ret;
};

const cloneOrder = async (payload: CloneOrderPayload): Promise<CloneOrderResponse> => {
  try {
    const response = await staffBffClient.post(`/api/v1/orders/${payload.orderId}/clone`, {
      ...payload,
    });
    return response.data;
  } catch {
    // In FE, cart token empty is the equivalent of API error
    return {
      cartUrl: "",
      cartToken: "",
      statusCode: 0,
    };
  }
};

const postOrderComment = async (orderId: MarketOrder["id"], payload: PostOrderCommentPayload) => {
  const response = await staffBffClient.post(`/api/v1/orders/${orderId}/comments`, payload);
  return response.data;
};

const getCommentsAndAssignee = async (orderId: MarketOrder["id"]): Promise<CommentsAndAssignee> => {
  const response = await staffBffClient.get(`/api/v1/orders/${orderId}/comments`);
  return response.data?.data;
};

const updateOrderConsultant = async (orderId: MarketOrder["id"], payload: OrderConsultantUpdatePayload) => {
  const response = await staffBffClient.patch(`/api/v1/orders/${orderId}/consultant-info`, payload);
  return response.data;
};

const updateOrderCustomer = async (orderId: MarketOrder["id"], payload: OrderCustomerUpdatePayload) => {
  const response = await staffBffClient.patch(`api/v1/orders/${orderId}/customer-info`, payload);
  return response.data;
};

const getCustomerListOrEmpty = async (params: CustomerGetListParams): Promise<CustomerGetListItem[]> => {
  const response = await usersClient.get(`/profiles/search`, { params });
  return response.data?.data || [];
};

const upsertOrderCustomer = async (payload: CustomerCreatePayload): Promise<{ profile: CustomerGetListItem }> => {
  const response = await staffBffClient.post(`/api/v1/profiles/upsert`, payload);
  return response.data?.data;
};

const updateBillingInfo = async (orderId: MarketOrder["id"], payload: OrderBillingInfoUpdatePayload) => {
  const response = await staffBffClient.post(`/api/v1/orders/${orderId}/billing-info`, payload);
  return response.data;
};

const printSaleOrder = async (orderId: MarketOrder["id"], params: INumberDisplayFormat) => {
  const response = await staffBffClient.get(`/api/v2/staff-admin/print-order-details/${orderId}`, {
    params,
    responseType: "blob",
  });
  return response.data;
};

const printMultipleOrders = async (orderIds: MarketOrder["id"][], numberDisplayFormat: INumberDisplayFormat) => {
  const response = await staffBffClient.post(
    `/api/v2/staff-admin/print-multiple-orders-details`,
    { orderIds, numberDisplayFormat },
    {
      responseType: "blob",
    }
  );
  return response.data;
};

const getCountNeedToActionOrders = async (
  params: CountNeedToActionsGetParams
): Promise<{ totalCount: NeedToActionsTotalCount }> => {
  const response = await staffBffClient.get(`/api/v2/staff-admin/orders/count-need-to-action`, { params });
  return response.data.data;
};

const updateMultiSellerOrderStatus = async (
  payload: UpdateSellerOrderStatusPayload[]
): Promise<UpdateShipmentStatusResponse> => {
  return await staffBffNoErrorNotiClient.patch(`/api/v1/seller/orders/status`, {
    data: payload,
  });
};

const cancelShipmentToReprocess = async (shipmentId: Shipment["shipmentId"]): Promise<any> => {
  return await staffBffClient.post(`/api/v1/shipments/${shipmentId}/cancel-to-reprocess-items`);
};

const manualUpdateShipmentStatus = async (
  shipmentId: Shipment["shipmentId"],
  payload: ManualUpdateShipmentStatusPayload
): Promise<UpdateShipmentStatusResponse> => {
  return await staffBffClient.post(`/api/v2/staff-admin/shipments/${shipmentId}/manual-update-status`, payload);
};

const manualUpdateMultiShipmentStatus = async (
  shipmentId: Shipment["shipmentId"],
  payload: ManualUpdateShipmentStatusPayload
): Promise<UpdateShipmentStatusResponse> => {
  return await staffBffNoErrorNotiClient.post(
    `/api/v2/staff-admin/shipments/${shipmentId}/manual-update-status`,
    payload
  );
};

const refetchFullSaleOrderData = async (orderId: string) => {
  return await staffBffNoErrorNotiClient.post(
    `/api/v2/staff-admin/orders/${orderId}/refetch`,
    {},
    { timeout: ONE_MIN_IN_MS }
  );
};

const exportDiscountApprovedOrdersReport = async (payload: CreateExportRequestForDiscountApprovedOrdersPayload) => {
  return await staffBffClient.post("/api/v1/request-generate/discount-approval-report", payload);
};

const cancelOrder = async (orderId: MarketOrder["id"], payload: CancelOrderPayload): Promise<any> => {
  const response = await staffBffClient.put(`/api/v2/staff-admin/orders/${orderId}/cancel`, payload);
  return response.data;
};

const changeSiteItems = async (payload: IOrderItemChangeSitePayload) => {
  return await staffBffClient.post("/api/v2/orders/items/change-site", payload);
};

const confirmOrderItems = async (payload: IOrderItemsConfirmPayload): Promise<IOrderItemsConfirmResponse> => {
  const response = await staffBffClient.post("/api/v2/orders/items/confirm", payload);
  return response?.data?.data;
};

const getImportHistories = (params: IPaginationQueryParamsPartial): Promise<ICommonFileProcessHistoryResponse> => {
  // return Promise.resolve({} as ICommonFileProcessHistoryResponse);
  return fpsBffClient
    .get(`/api/v1/clients/${importShipmentStatusFPSClientId}/processing-files`, { params })
    .then((res) => res?.data?.data);
};

const processUpdateBatchShipmentStatus = async (fileUrl: string) => {
  return await staffBffClient.post("/api/v1/shipments/batch-update-status", { fileUrl });
};

const exportWarrantyActivation = async (payload: ISamsungWarrantyActivationPayload) => {
  return await staffBffClient.post("/api/v1/staff-admin/export-warranty-activation-result", payload);
};

const exportListOrderForCustomerTeam = async (payload: IExportListOrderForCustomerTeamPayload) => {
  return await staffBffClient.post("/api/v2/staff-admin/export-list-order-for-customer-team", payload);
};

export default {
  getMarketOrderDetail,
  getMarketOrdersListPre1018,
  getMarketOrdersList,
  getTotalFilteredOrders,
  getSellerOrdersList,
  getSellerTotalFilteredOrders,
  getSellerOrderDetail,
  getShipmentStatusOptionsOrEmpty,
  updateSellerOrderStatus,
  updateMultiSellerOrderStatus,
  getOrderStatusOptionsOrEmpty,
  confirmHoldItems,
  changeWarehouseItems,
  swapHoldItem,
  confirmWarehouse,
  updateOrderPriority,
  cloneOrder,
  createExportRequestForOrdersByPlatform,
  createExportRequestForOrdersBySeller,
  createOrdersExportRequestForStaff,
  createOrdersExportRequestForSeller,
  cancelShipmentToReprocess,
  postOrderComment,
  getCommentsAndAssignee,
  updateOrderConsultant,
  updateOrderCustomer,
  getCustomerListOrEmpty,
  upsertOrderCustomer,
  updateBillingInfo,
  printSaleOrder,
  getCountNeedToActionOrders,
  getOrderDetailsForPlatform,
  getOrderDetailsForSeller,
  printMultipleOrders,
  refetchFullSaleOrderData,
  manualUpdateShipmentStatus,
  manualUpdateMultiShipmentStatus,
  exportDiscountApprovedOrdersReport,
  cancelOrder,
  changeSiteItems,
  confirmOrderItems,
  getImportHistories,
  processUpdateBatchShipmentStatus,
  exportWarrantyActivation,
  exportListOrderForCustomerTeam,
};
