import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import { Rule } from "antd/lib/form";
import { Locale } from "antd/lib/locale-provider";

export interface IMap<T> {
  [key: string]: T;
}

export type AnyObject = IMap<any>;

export interface IPermission {
  app: string;
  resourceOrRole: string;
  action?: string;
}

export interface IRoute {
  exact?: boolean;
  path: string;
  name: string;
  component: React.ElementType;
  permissions?: IPermission[];
  icon?: React.ComponentType<{ className?: string }>;
  children?: string[];
  iFrameSrc?: string;
  redirectOutside?: boolean;
  isUseSellerSwitch?: boolean;
  flagKey?: string;
  screenName?: string;
}

export interface IRegionItem {
  key: string;
  name: string;
  flag: string;
  antdLocale: Locale;
  datePickerLocale: PickerLocale;
}

export interface IRegion {
  [key: string]: IRegionItem;
}

export interface ISeller {
  id: number;
  name: string;
  logoUrl: string;
}

export interface IBranch {
  code: string;
  name: string;
  sellerId: ISeller["id"];
}

export interface IEditableCellProps {
  id?: number | string;
  defaultValue: string | number;
  rules: Rule[];
  onSave: (newValue: string | number) => Promise<unknown>;
}

export interface IContainerDetailProps {
  match: {
    params: {
      id: string;
    };
  };
}

export interface IBffSeller {
  code: string;
  id: number;
  isActive: number;
  name: string;
  logoUrl: string;
  phoneNumber: string;
}

export interface IBffTerminal {
  code: string;
  id: number;
  isActive: number;
  name: string;
  sellerId: number;
  type: string;
}

export interface IPlatform {
  id: number;
  name: string;
  code: string;
  ownerSellerId: ISeller["id"];
}

export interface SellerOwner {
  platformId: IPlatform["id"];
  id: ISeller["id"];
}

export interface IFlagItem {
  enabled: boolean;
  expBranchId?: number;
  expId?: number;
  treatment?: string;
}

export interface IFlagsData {
  [key: string]: IFlagItem;
}

export enum SortType {
  ASC = "asc",
  DESC = "desc",
}

export interface PaginationOffset {
  offset: number;
  nextOffset: number;
}

export interface PaginationTypeTotal {
  currentPage: number;
  pageSize: number;
  totalItems?: number;
}

export interface IPaginationQueryParams {
  page: number;
  pageSize: number;
}

export interface IPaginationQueryParamsPartial extends Partial<IPaginationQueryParams> {}

export interface IBasePaginationParams {
  page?: number;
  pageSize?: number;
}

export type DateRangeValidationRule = {
  maxRange: number;
  unit: DateRangeValidationRuleUnit;
};

export enum DateRangeValidationRuleUnit {
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

export enum UploadFileStatus {
  UPLOADING = "uploading",
  ERROR = "error",
  DONE = "done",
}

export enum TIME_UNIT {
  Seconds = "seconds",
  Milliseconds = "milliseconds",
}

export interface INumberDisplayFormat {
  thousandSeparator: string;
  decimalSeparator: string;
}

export interface INumberDisplayFormat {
  thousandSeparator: string;
  decimalSeparator: string;
}
