import { userApis } from "apis";
import { appConfig } from "app-config";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { localizationUtils, requestUtils } from "utils";

const { serviceHost } = appConfig;

const accReportClient = axios.create({ baseURL: serviceHost.accReport });
const aplClient = axios.create({
  baseURL: serviceHost.apl,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
const asClient = axios.create({
  baseURL: serviceHost.as,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
const catalogClient = axios.create({ baseURL: serviceHost.catalog });
const dcaClient = axios.create({ baseURL: serviceHost.dca });
const iamClient = axios.create({ baseURL: serviceHost.iam });
const locationClient = axios.create({ baseURL: serviceHost.location });
const locationClientV2 = axios.create({ baseURL: serviceHost.locationV2 });
const orderNotificationClient = axios.create({ baseURL: serviceHost.orderNotification });
const userNotificationClient = axios.create({
  baseURL: serviceHost.userNotification,
  headers: { "Accept-Language": localizationUtils.getCurrentLanguage() },
});
const sellerClient = axios.create({ baseURL: serviceHost.sellers });
const ticketBffClient = axios.create({ baseURL: serviceHost.ticketBff });
const usersClient = axios.create({ baseURL: serviceHost.users });
const wmsClient = axios.create({ baseURL: serviceHost.wms });
const staffBffClient = axios.create({
  baseURL: serviceHost.staffBff,
  headers: { "Accept-Language": localizationUtils.getCurrentLanguage() },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
const staffBffNoErrorNotiClient = axios.create({
  baseURL: serviceHost.staffBff,
  headers: { "Accept-Language": localizationUtils.getCurrentLanguage() },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
const fileClient = axios.create({ baseURL: serviceHost.file });
const fpsBffClient = axios.create({
  baseURL: serviceHost.fpsBff,
  headers: { "Accept-Language": localizationUtils.getCurrentLanguage() },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});
const flagsupClient = axios.create({ baseURL: serviceHost.flagsup });

const getAuthorization = () => {
  return userApis.isLoggedIn() ? `Bearer ${userApis.getAccessToken()}` : "";
};

// Do something before request is sent
const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers.Authorization = getAuthorization();
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response: AxiosResponse) => {
  // Do something with response data
  return response;
};

// Any status codes that falls outside the range of 2xx cause this function to trigger
const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error
  requestUtils.handleResponseError(error);
  return Promise.reject(error);
};

const clients = [
  accReportClient,
  aplClient,
  asClient,
  catalogClient,
  dcaClient,
  iamClient,
  locationClient,
  locationClientV2,
  orderNotificationClient,
  userNotificationClient,
  sellerClient,
  staffBffClient,
  ticketBffClient,
  usersClient,
  wmsClient,
  fileClient,
  fpsBffClient,
];

clients.forEach((client) => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
});
// Ref: https://jira.teko.vn/browse/OMPRODUCT-147 - For process one by one: prevent show notification when api response error.
staffBffNoErrorNotiClient.interceptors.request.use(requestInterceptor);
staffBffNoErrorNotiClient.interceptors.response.use(responseSuccessInterceptor, (error) => Promise.reject(error));

export default {
  accReportClient,
  aplClient,
  asClient,
  catalogClient,
  dcaClient,
  iamClient,
  locationClient,
  locationClientV2,
  orderNotificationClient,
  userNotificationClient,
  sellerClient,
  staffBffClient,
  staffBffNoErrorNotiClient,
  ticketBffClient,
  usersClient,
  wmsClient,
  fileClient,
  fpsBffClient,
  flagsupClient,
};
