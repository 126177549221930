import { ActiveStatus, WarehouseTypes } from "models";
import { t } from "utils/i18n";

const VIEW_WAREHOUSE_TREE = {
  BY_SITE: "Site",
  BY_WAREHOUSE: "Warehouse",
};

const WAREHOUSE_TYPE = [
  {
    id: WarehouseTypes.sale,
    name: t("ManualInventory.WarehouseTypeSale"),
  },
  {
    id: WarehouseTypes.storeonly,
    name: t("ManualInventory.WarehouseTypeStoreOnly"),
  },
  {
    id: WarehouseTypes.demo,
    name: t("ManualInventory.WarehouseTypeDemo"),
  },
  {
    id: WarehouseTypes.refurbished,
    name: t("ManualInventory.WarehouseTypeRefurbished"),
  },
  {
    id: WarehouseTypes.backorder,
    name: t("ManualInventory.WarehouseTypeBackorder"),
  },
  {
    id: WarehouseTypes.incoming,
    name: t("ManualInventory.Incoming"),
  },
  {
    id: WarehouseTypes.notforsale,
    name: t("ManualInventory.WarehouseTypeNotForSale"),
  },
];

const SITE_TYPE = {
  DC: {
    value: 0,
    name: t("ManualInventory.SiteTypeDC"),
  },
  STORE: {
    value: 1,
    name: t("ManualInventory.SiteTypeStore"),
  },
};

const ACTIVE_STATUS_MAP: {
  [key in ActiveStatus]: boolean;
} = {
  [ActiveStatus.ACTIVE]: true,
  [ActiveStatus.INACTIVE]: false,
};

const ACTIVE_STATUS_TAG_COLOR_MAP = {
  [ActiveStatus.INACTIVE]: "default",
  [ActiveStatus.ACTIVE]: "success",
};

export default { VIEW_WAREHOUSE_TREE, WAREHOUSE_TYPE, SITE_TYPE, ACTIVE_STATUS_MAP, ACTIVE_STATUS_TAG_COLOR_MAP };
