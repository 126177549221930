import { TrackingEvent, TrackingRegionName } from "constants/index";
import { commonUtils } from "utils";

const { chainMultipleFunctions, capitalize, removeSpecialChar } = commonUtils;

export interface TrackUserClickUserEventParams {
  contentName?: string;
  regionName?: string;
  target?: string;
  payload?: string;
}

const trackUserClickEvent = (params: TrackUserClickUserEventParams) => {
  window.track(TrackingEvent.manualTrackInteractionContent, { ...params, interaction: "click" });
};

const trackReturnDrawer = (params: TrackUserClickUserEventParams) => {
  const { contentName, payload } = params;
  trackUserClickEvent({
    contentName,
    regionName: TrackingRegionName.ReturnDrawer,
    payload,
  });
};

export const computeTrackingScreenName = (path: string) => {
  const PATTERN_REGEX = /[/:]/g;
  const replacedPattern: Record<string, string> = {
    "/": ".",
    ":": "&",
  };
  return path.replace(PATTERN_REGEX, (value) => replacedPattern[value]);
};

export const nestRegionName = (...regionNames: (string | undefined)[]) => regionNames.filter(Boolean).join(".");

const convertTabToTrackingName = (str?: string) => {
  if (!str) return "";
  const tabName = chainMultipleFunctions(removeSpecialChar, capitalize, (str: string) => str + "Tab");
  return tabName(str);
};

const getTrackingProps = ({ contentName, regionName, target, payload }: TrackUserClickUserEventParams) => {
  return {
    "data-track-content": true,
    "data-content-region-name": regionName || "root",
    ...(contentName && { "data-content-name": contentName }),
    ...(target && { "data-content-target": target }),
    ...(payload && { "data-content-payload": payload }),
  } as any;
};

export default {
  trackUserClickEvent,
  trackReturnDrawer,
  computeTrackingScreenName,
  nestRegionName,
  convertTabToTrackingName,
  getTrackingProps,
};
