import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "antd";

const { Paragraph } = Typography;

const getErrorWithSugaryWrapper = (errorMessage: string) => {
  return (
    <div>
      <Trans i18nKey="SugaryWrapperErrorMessage">
        Something went wrong, please send the following message to
        <a href="mailto: support@teko.vn"> support@teko.vn </a>for assistance.
      </Trans>
      <Paragraph>
        <blockquote>{errorMessage}</blockquote>
      </Paragraph>
    </div>
  );
};

export { getErrorWithSugaryWrapper };
