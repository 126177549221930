import { IPlatform, ISeller } from "models";

export enum DeliveryStatus {
  created = "CREATED",
  sentToProvider = "SENT_TO_PROVIDER",
  cancelled = "CANCELLED",
  returned = "RETURNED",
  delivered = "DELIVERED",
  delivering = "DELIVERING",
  _3PLPicked = "3PL_PICKED",
  deliveryRequested = "DELIVERY_REQUESTED",
  cancelRejected = "CANCEL_REJECTED",
}

export interface DeliveryInfo {
  requestId: number;
  status: DeliveryStatus;
  transportProviderName: string;
  transportProviderCode: string;
  transportTrackingId: string;
  description: string;
  totalWeight: number;
  height: number;
  length: number;
  width: number;
  numberOfPackages: number;
  createdDate: string;
  createdBy: string;
  deliverTo: string;
  expectDeliveryDueTo: string;
  policyServiceName: string;
  allowTrialOnDelivery: string;
  createdByEmail?: string;
}

export interface DeliveryPayload {
  shipmentId: string;
  providerId: string;
  expectDeliveryDueTo: string;
  description?: string;
  length: number;
  height: number;
  width: number;
  totalWeight: number;
  numberOfPackages: number;
  options: {
    optionCode: string;
    optionValue: string;
  }[];
  deliveryProviderServiceCode: IDeliveryService["deliveryProviderServiceCode"];
}

export interface IProviderWithServices {
  deliveryServices: IDeliveryService[];
  options: IDeliveryOption[];
  providerId: string;
  providerName: string;
}

export interface IDeliveryService {
  deliveryProviderServiceCode: string;
  deliveryProviderServiceName: string;
  deliveryServiceId: string;
}

export interface IDeliveryOption {
  optionName: string;
  optionCode: string;
  optionValues: {
    value: string;
    name: string;
  }[];
}

export interface CreateDeliveryResponse {
  deliveryRequestId: DeliveryInfo["requestId"];
}

export interface CancelDeliveryPayload {
  deliveryRequestId: number;
}

export interface CreateBolFormValueType extends Omit<DeliveryPayload, "shipmentId" | "options"> {
  optionFormValue: string;
}

/* These interfaces will be deleted when epic OMNI_725 goes live 
------START---------
*/
export interface IProvider {
  id: string;
  name: string;
  code: string;
}

export interface TransportationProviderGetListPayload {
  platformId: IPlatform["id"];
  sellerId: ISeller["id"];
}

export interface AllowTrialOnDeliveryOption {
  name: string;
  code: string;
}

/*-----END--------*/
