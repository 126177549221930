import { ISeller } from "./common";

export enum RefundRequestStatus {
  init = "REFUND_REQUEST_STATUS_INIT",
  waitPartner = "REFUND_REQUEST_STATUS_WAITING_FOR_PARTNER",
  overdue = "REFUND_REQUEST_STATUS_OVERDUE",
  done = "REFUND_REQUEST_STATUS_DONE",
}

export enum RefundMethod {
  VNPayGateway = "VNPAY_GATEWAY",
  VNPayQrcode = "VNPAY_QRCODE",
  BankTransfer = "BANK_TRANSFER",
  Cash = "CASH",
  Pos = "POS",
  VNPaySposCard = "VNPAY_SPOS_CARD",
  MobileBanking = "MOBILE_BANKING",
}

export interface RefundRequest {
  id: string;
  orderId: string;
  orderCode: string;
  status: RefundRequestStatus;
  createdAt: string;
  confirmedAt: string;
  confirmedBy: string;
  recipient: string;
  requestAmount: number;
  refundAmount: number;
  paymentMethod: RefundMethod;
  refundPaymentReference: string;
  cancelRequestId: string;
}

export interface RefundReturnItem {
  sku: string;
  uom: string;
  name: string;
  quantity: number;
}

export interface RefundPayment {
  paymentMethod: string;
  paymentAmount: number;
  refundAt: string;
}

export interface RefundRequestDetail {
  orderId: string;
  sellerName: string;
  sellerAddress: string;
  sellerPhoneNumber: string;
  sellerWebSite: string;
  sellerLogoUrl: string;
  recipient: string;
  siteName: string;
  returnReason: string;
  totalRefundAmountInWords: string;
  returnItems: RefundReturnItem[];
  refundPayments: RefundPayment[];
}

export interface RefundRequestGetListPayLoad {
  orderCode?: string;
  status?: RefundRequestStatus;
  offset?: number;
  limit?: number;
  sellerId?: ISeller["id"];
}

export interface CancelledItem {
  lineItemId: string;
  sku: string;
  name: string;
  quantity: number;
  unitPrice: number;
  rowTotal: number;
}
export interface CancellationInfo {
  sellerName: string;
  canceledAmount: number;
  items: CancelledItem[];
  note?: string;
}

export interface RefundRequestConfirmPayload
  extends Pick<RefundRequest, "id" | "recipient" | "refundAmount" | "refundPaymentReference"> {
  refundMethod: RefundMethod;
}

export interface RefundRequestSendToVNPayPayload extends Pick<RefundRequest, "paymentMethod"> {
  refundRequestId: RefundRequest["id"];
  requestRefundAmount?: RefundRequest["refundAmount"];
  note?: string;
}
