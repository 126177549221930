import { IPlatform, ISeller } from "./common";
import { MarketOrder } from "./marketOrders";

export interface ProblematicEvent {
  id: number;
  objectType: string; //deprecated
  objectId: string; //deprecated
  message: string;
  orderCode?: MarketOrder["code"]; //deprecated
  fromService?: string;
  eventType?: number;
  eventTypeLabel?: string;
  triggerTimestamp: string;
  referenceType: ReferenceType;
  referenceId: string;
  sellerName?: ISeller["name"];
  platformName?: IPlatform["name"];
  status: ProblematicStatus;
  title?: string;
}

export enum ReferenceType {
  order = "ORDER",
  product = "PRODUCT",
  review = "REVIEW",
}

export enum ProblematicStatus {
  unspecified = "EVENT_TRACKING_STATUS_UNSPECIFIED",
  unresolved = "EVENT_TRACKING_STATUS_UNRESOLVED",
  resolved = "EVENT_TRACKING_STATUS_RESOLVED",
}

export interface OrderHealthFilter {
  page?: number;
  pageSize?: number;
}

export interface ProblematicEventGetListPayload extends Omit<OrderHealthFilter, "page" | "pageSize"> {
  limit?: number;
  offset?: number;
  objectId?: string;
  objectType?: string;
}
