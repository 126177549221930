export interface IArea {
  id: string;
  code: string;
  parentCode: string;
  province: string;
  district: string;
  name: string;
  acronym: string;
}

export interface IAreaV2 {
  id: number;
  code: string;
  name: string;
  region?: string;
}

export interface IDistrictQueryParams {
  provinceCode?: string;
  name?: string;
}

export interface IWardQueryParams {
  districtCode?: string;
  name?: string;
}

export interface IAreaQueryParams {
  name?: string;
}

export interface IDetailArea {
  provinceCode: string;
  provinceName: string;
  areaName: string;
  areaCode: string;
  data: IArea;
}

export interface IAreasGroupedByProvince {
  [provinceCode: string]: Array<IDetailArea>;
}

export interface ILocation {
  provinceCode: string;
  provinceName: string;
  fullName?: string;
  wardCode?: string;
  wardName?: string;
  districtCode?: string;
  districtName?: string;
}

export enum COUNTRY {
  VN = "VN",
  GH = "GH",
}

export interface IProvinceQueryParams {
  country?: COUNTRY;
}
