import { Result, Spin } from "antd";
import { StoreContext, useSellerState } from "contexts";
import { commonHooks } from "hooks";
import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { sellerUtils } from "utils";

const { getAuthorizedSellerIds, hasAllSellers } = sellerUtils;

export interface WithAssignedSingleSellerProps {
  currentSellerId: number;
}

export const withAssignedSingleSeller = <P extends {}>(
  Component: React.FC<P>
): React.FC<P & WithAssignedSingleSellerProps> => {
  return (props) => {
    const { currentUser } = useContext(StoreContext);
    commonHooks.useSellerFetch(currentUser);
    const { sellers, currentSeller } = useSellerState();

    const authorizedSellerIds = getAuthorizedSellerIds(currentUser, true);
    const isAssignedSingleSeller = !hasAllSellers(currentUser) && authorizedSellerIds.length === 1;
    const isValidSellerId = isAssignedSingleSeller && currentSeller;
    const isFetchingSellers = authorizedSellerIds.length && !sellers;

    if (isFetchingSellers) return <Spin spinning={isFetchingSellers} />;

    if (!isValidSellerId) {
      return (
        <Result
          status="403"
          subTitle={
            !isAssignedSingleSeller && currentSeller ? (
              <Trans i18nKey="InventoryAlertWarning">
                <div>Currently, the account is assigned multiple sellers.</div>
                <div>Please assign only one seller to access this page.</div>
              </Trans>
            ) : (
              <Trans i18nKey="InvalidSellerMsg">
                The account is assigned invalid seller. <br />
                Please contact Admin <a href="mailto:support@teko.vn">support@teko.vn</a> for assistance.
              </Trans>
            )
          }
        />
      );
    }

    return <Component {...props} currentSellerId={currentSeller?.id} />;
  };
};
