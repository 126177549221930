import { Result } from "antd";
import { BackToHomeButton, LogoutButton } from "components/Button";
import React from "react";
import { t } from "utils/i18n";

const Page500: React.FC = () => {
  return (
    <Result
      className="app-result-page"
      status="500"
      title="500"
      subTitle={t("SomethingWentWrong")}
      extra={
        <>
          <BackToHomeButton />
          <LogoutButton />
        </>
      }
    />
  );
};

export default Page500;
