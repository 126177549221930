import { RuleObject } from "antd/es/form";
import { dateTimeConstants } from "constants/index";
import dayjs, { Dayjs } from "dayjs";
import { DateRangeValidationRule, TIME_UNIT } from "models";
import { t, toTitleCase } from "utils/i18n";

const { DATE_SLASH_FORMAT, DATE_TIME_SLASH_FORMAT, DATE_TIME_SLASH_FORMAT_LITE } = dateTimeConstants;

/**
 * Example inputs:
 *
 * * 2020-10-06T04:45:15Z (no milliseconds; single letter Z means UTC+0)
 * * 2012-01-26T13:51:50.417-07:00 (with UTC-7)
 * * 2020-10-06 06:59:42+07:00 (no delimiter, with UTC+7)
 * */
const formatDate = (time: string) => {
  return dayjs(time).format(DATE_SLASH_FORMAT);
};

/**
 * Example inputs:
 *
 * * 2020-10-06T04:45:15Z (no milliseconds; single letter Z means UTC+0)
 * * 2012-01-26T13:51:50.417-07:00 (with UTC-7)
 * * 2020-10-06 06:59:42+07:00 (no delimiter, with UTC+7)
 * */
const formatDateTime = (time: string) => {
  return dayjs(time).format(DATE_TIME_SLASH_FORMAT);
};

const formatDateTimeLite = (time: string) => {
  return dayjs(time).format(DATE_TIME_SLASH_FORMAT_LITE);
};

const THIS_MONTH_START = dayjs().startOf("month");
const TODAY = dayjs();

const formatUnixDateTime = (time: number, unit?: TIME_UNIT) => {
  if (unit === TIME_UNIT.Milliseconds) {
    return dayjs(time).format(DATE_TIME_SLASH_FORMAT);
  }
  return dayjs.unix(time).format(DATE_TIME_SLASH_FORMAT);
};

const formatUnixDate = (time: number) => {
  return dayjs.unix(time).format(DATE_SLASH_FORMAT);
};

const isValidDateTime = (time: string) => {
  return dayjs(time).isValid();
};

const getLatestDate = (time1: string, time2: string) => {
  const isTime1Valid = isValidDateTime(time1);
  const isTime2Valid = isValidDateTime(time2);

  if (!isTime1Valid || !isTime2Valid) {
    return (isTime1Valid && time1) || (isTime2Valid && time2) || "";
  }

  return dayjs(time1) > dayjs(time2) ? time1 : time2;
};

const validateMaxDateRange = (dateRangeRule: DateRangeValidationRule) => async (_rule: RuleObject, value: Dayjs[]) => {
  if (!value) return;
  const { unit, maxRange } = dateRangeRule;
  const [startDate, endDate] = value;

  if (endDate.diff(startDate, unit) >= maxRange) {
    throw Error(
      t("MaxDateRange", {
        range: maxRange,
        unit: t(`${toTitleCase(unit?.toString() || "day")}s`),
      })
    );
  }
};

export default {
  formatDate,
  formatDateTime,
  formatDateTimeLite,
  THIS_MONTH_START,
  TODAY,
  formatUnixDate,
  formatUnixDateTime,
  isValidDateTime,
  getLatestDate,
  validateMaxDateRange,
};
