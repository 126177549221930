import { ClearanceRequestStatus } from "models";
import { t } from "utils/i18n";

const CLEARANCE_REQUEST_STATUS_OPTIONS = [
  {
    code: ClearanceRequestStatus.available,
    name: t("ManualInventory.Available"),
    color: "blue",
  },
  {
    code: ClearanceRequestStatus.expired,
    name: t("Expired"),
    color: "red",
  },
  {
    code: ClearanceRequestStatus.approved,
    name: t("Approved"),
    color: "green",
  },
];

export default { CLEARANCE_REQUEST_STATUS_OPTIONS };
