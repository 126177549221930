import { CalculatorOutlined } from "@ant-design/icons";
import { ACCOUNTING_PATHS } from "constants/index";
import { actionConstants, appConstants, resourceConstants, roleConstants } from "constants/permission";
import { lazy } from "react";
import { userUtils } from "utils";
import { t } from "utils/i18n";

const { permission } = userUtils;
const { STAFF_BFF } = appConstants;
const { CREDIT_APPROVAL_CONFIGS, PURCHASE_INVOICES, ACCOUNTING } = resourceConstants;
const { CREATE, READ_POR_INVOICE, UPDATE } = actionConstants;
const { ACCOUNTANT, ACCOUNTANT_READONLY } = roleConstants;

// Pages
const RefundRequestList = lazy(() => import("pages/RefundRequestList"));
const PurchaseInvoiceCreate = lazy(() => import("pages/PurchaseInvoiceCreate"));
const PurchaseReturnInvoiceCreate = lazy(() => import("pages/PurchaseReturnInvoiceCreate"));
const CustomerCreditLimit = lazy(() => import("pages/CustomerCreditLimit"));

export default [
  // Parent menu
  {
    path: "/accounting",
    name: t("Accounting.Root"),
    icon: CalculatorOutlined,
    children: ["/accounting/credit-limits", "/accounting/refund-requests", "/accounting/customer-credit-limits"],
  },
  // Sub menus
  {
    exact: true,
    path: "/accounting/customer-credit-limits",
    name: t("Accounting.CustomerCreditLimits"),
    component: CustomerCreditLimit,
    permissions: [permission(STAFF_BFF, CREDIT_APPROVAL_CONFIGS, CREATE)],
    isUseSellerSwitch: true,
  },
  {
    exact: true,
    path: "/accounting/refund-requests",
    name: t("Accounting.RefundRequests"),
    component: RefundRequestList,
    permissions: [permission(STAFF_BFF, ACCOUNTANT), permission(STAFF_BFF, ACCOUNTANT_READONLY)],
  },
  {
    exact: true,
    path: "/accounting/purchase-invoices/create",
    name: t("Accounting.PurchaseInvoiceCreate"),
    component: PurchaseInvoiceCreate,
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, PURCHASE_INVOICES, CREATE)],
  },
  {
    exact: true,
    path: ACCOUNTING_PATHS.CONFIRM_PURCHASE_INVOICE,
    name: t("Accounting.PurchaseInvoiceConfirm"),
    component: PurchaseInvoiceCreate,
    isUseSellerSwitch: true,
    permissions: [permission(STAFF_BFF, PURCHASE_INVOICES, UPDATE)],
  },
  {
    exact: true,
    path: "/accounting/purchase-return-invoices/create",
    name: t("Accounting.PurchaseReturnInvoiceCreate"),
    component: PurchaseReturnInvoiceCreate,
    permissions: [permission(STAFF_BFF, ACCOUNTING, READ_POR_INVOICE)],
  },
];
